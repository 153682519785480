.st0 {
  fill: none;
}
.metismenu-icon {
  background-size: 28px;
  width: 28px;
  height: 28px;
  line-height: 28px;
  display: inline-block;
  margin-right: 16px;
  filter: grayscale(150%);
  position: absolute;
  left: 1rem;
  &:before {
    background-size: 22px;
    content: '';
    background-repeat: no-repeat;
    background-position: left center;
    width: 28px;
    height: 28px;
    display: inline-block;
  }
}

$icons: dashboard users jobs truck settings disputes testimonials blog envelope userCheck job files list-ul;
@each $icon in $icons {
  .icon-#{$icon} {
    &:before {
      background-image: url('/images/svg/#{$icon}.svg');
    }
  }
}

.card-icon-60 {
  width: 60px;
  height: 60px;
  @extend .flexbox-align-c;
  @extend .shadow-sm;
}
