// Componant: Dropdown

.dropdown-menu {
  font-size: rem(12);
  line-height: 18px;
  @extend .shadow;
  border: 0;
  .d-menu-icon {
    min-width: 18px;
    display: inline-block;
  }
  .dropdown-item {
    &:hover,
    &:focus {
      outline: 0 !important;
    }
  }
}

.dropdown-no-icon {
  &::after {
    content: none;
  }
}
