.user-verity {
  margin-top: 34px !important;
  color: #36b7b7 !important;
  background-color: #d7c7c7 !important;
  height: 48px !important;
  text-align: center !important;
  border-radius: 12px !important;
}
.chat-bar-collapsible {
  position: fixed;
  bottom: 0;
  right: 50px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.chat-bar-collapsible-client {
  position: fixed;
  bottom: 0;
  left: 26%;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.collapsible {
  background-color: rgb(82, 151, 255);
  color: white;
  cursor: pointer;
  padding: 18px;
  width: 350px;
  text-align: left;
  outline: none;
  font-size: 18px;
  border-radius: 10px 10px 0px 0px;
  border: 3px solid white;
  border-bottom: none;
}

.chat-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  background-color: #f1f1f1;
}

.full-chat-block {
  width: 350px;
  background: white;
  text-align: center;
  overflow: auto;
  scrollbar-width: none;
  height: max-content;
  transition: max-height 0.2s ease-out;
}

.outer-container {
  min-height: 500px;
  bottom: 0%;
  position: relative;
}

.chat-container {
  max-height: 500px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  scroll-behavior: smooth;
  hyphens: auto;
}

.chat-container::-webkit-scrollbar {
  display: none;
}

.chat-bar-input-block {
  display: flex;
  float: left;
  box-sizing: border-box;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  background-color: rgb(235, 235, 235);
  border-radius: 10px 10px 0px 0px;
  padding: 10px 0px 10px 10px;
}

.chat-bar-icons {
  display: flex;
  justify-content: space-evenly;
  box-sizing: border-box;
  width: 25%;
  float: right;
  font-size: 20px;
}

#chat-icon:hover {
  opacity: 0.7;
}

/* Chat bubbles */

#userInput {
  width: 75%;
}

.input-box {
  float: left;
  border: none;
  box-sizing: border-box;
  width: 100%;
  border-radius: 10px;
  padding: 10px;
  font-size: 16px;
  color: #000;
  background-color: white;
  outline: none;
}

.userText {
  color: white;
  font-family: Helvetica;
  font-size: 16px;
  font-weight: normal;
  text-align: right;
  clear: both;
}

.userText span {
  line-height: 1.5em;
  display: inline-block;
  background: #5ca6fa;
  padding: 10px;
  border-radius: 8px;
  border-bottom-right-radius: 2px;
  max-width: 80%;
  margin-right: 10px;
  animation: floatup 0.5s forwards;
}

.botText {
  color: #000;
  font-family: Helvetica;
  font-weight: normal;
  font-size: 16px;
  text-align: left;
}

.botText span {
  line-height: 1.5em;
  display: inline-block;
  background: #e0e0e0;
  padding: 10px;
  border-radius: 8px;
  border-bottom-left-radius: 2px;
  max-width: 80%;
  margin-left: 10px;
  animation: floatup 0.5s forwards;
}

@keyframes floatup {
  from {
    transform: translateY(14px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

@media screen and (max-width: 600px) {
  .full-chat-block {
    width: 100%;
    border-radius: 0px;
  }
  .chat-bar-collapsible {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
  }
  .collapsible {
    width: 100%;
    border: 0px;
    border-top: 3px solid white;
    border-radius: 0px;
  }
}
.mt-admin-5 {
  margin-top: 2rem !important;
}

/* Custom Styles */
.tbody > tr > td.actionColumn {
  width: 15%;
}

/* Testimonial */
.tbody > tr > td.testimonialCommentColumn {
  width: 20%;
}

.tbody > tr > td.testimonialColumn {
  width: 15%;
}

.tbody > tr > td.testimonialImgColumn > img {
  width: 60px;
  height: auto;
  object-fit: cover;
  border-radius: 10px;
  min-height: 60px;
  max-height: 60px;
}

/* Blog */
.tbody > tr > td.blogDescriptionColumn {
  width: 25%;
}
.tbody > tr > td.blogDescriptionColumn > div {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tbody > tr > td.blogColumn {
  width: 20%;
}

.tbody > tr > td.blogImgColumn {
  width: 15%;
}

.tbody > tr > td.blogImgColumn > img {
  width: 60px;
  height: auto;
  object-fit: cover;
  border-radius: 10px;
  min-height: 60px;
  max-height: 60px;
}

/* Dashboard Statistics */

.dashboard-stats-heading {
  font-size: 20px;
}

/* React Date Range picker */
.page-title-date-range {
  display: flex;
  flex-wrap: wrap;
}

.page-title-dr-select {
  margin: 0 10px 10px 0;
  width: 200px;
}

.rdr-picker {
  background-color: white;
  margin-bottom: 10px;
}

.rdr-picker > div:nth-child(1) {
  border-radius: 5px;
  padding: 0px 10px;
}

/* Accordian style */
.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 0;
  font-size: 1rem;
  color: #212529;
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button.collapsed {
  border-bottom-width: 0;
}
.accordion-button:not(.collapsed) {
  color: #0c63e4;
  background-color: #e7f1ff;
}
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(180deg);
}
.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: '';
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: none;
  outline: 0;
  box-shadow: none;
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.accordion-item:last-of-type .accordion-button.collapsed {
  /* border-bottom-width: 1px;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; */
}
.accordion-item:last-of-type .accordion-collapse {
  /* border-bottom-width: 1px;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; */
}

.accordion-collapse {
  border: solid rgba(0, 0, 0, 0.125);
  border-width: 0 1px;
  margin-bottom: 20px;
}

.accordion-body {
  padding: 1rem 1.25rem;
}

.accordion-flush .accordion-button {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item:first-of-type .accordion-button {
  border-top-width: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion-flush .accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-width: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

/* Career Jobs */

/* Job List */
.tbody > tr > td.cjdetailsColumn > div {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tbody > tr > td.cjListColumn {
  width: 30%;
}

/* AddEdit Carrer Job */

.formArrayRepearer {
  display: flex;
  align-items: flex-start;
}

.formArrayRepearer img {
  width: 30px;
  height: auto;
  margin-top: 5px;
  margin-left: 10px;
  cursor: pointer;
  filter: grayscale(150%);
}

.formArrayRepearer .form-group {
  flex-grow: 1;
}

/* Career Application View */
.applicationDetails h2 {
  font-size: 1.65rem;
}

.applicantDetails {
  font-size: 22px;
}

.applicantDetails p {
  margin-bottom: 20px;
}

/* Dashboard */
.page-title-dv {
  margin-right: 10px;
}
.page-title-dv .btn-group button {
  color: #000;
  background: white;
  border: 1px solid #ccc;
  padding: 6px 12px;
}

.page-title-dv .btn-group button:not(.disabled).active {
  color: white;
  background: #36b7b7;
  border: 1px solid #36b7b7;
}

.date-range-btn {
  padding: 8px 15px;
  border-radius: 5px;
  background: #007679;
  color: #fff;
  font-size: 16px;
  transition: all 0.2s ease-in-out 0s;
  cursor: pointer;
  float: right;
}

@media only screen and (max-width: 1369px) {
  .date-range-btn {
    font-size: 12px;
  }
}

@media only screen and (max-width: 540px) {
  .date-range-btn {
    float: left;
  }

  .date-range-picker {
    flex-direction: column;
  }
}

.modal-md.modal-dialog.date-range-modal {
  max-width: max-content !important;
}

.color-light-green {
  color: #36b7b7;
}

/* Documents All */
.document {
  text-align: left;
  margin-bottom: 15px;
  font-size: 16px;
  min-height: 40px;
  flex-basis: 100%;
}

.document span {
  color: #919191;
}

.document p {
  margin: 0 0 5px;
}

.document-row {
  font-size: 16px;
  background: #eee;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 15px;
}
.document-row .col-6 {
  padding: 0 10px;
}

.document-row .document {
  margin: 0;
}

.verifyme-card {
  text-align: left;
  padding: 10px 20px;
  border: 1px solid #cfcfcf;
  border-radius: 8px;
}

.collapse-header {
  background-color: #9191912b;
  border-radius: 5px;
  padding: 0 30px;
}

.collapse-header + div {
  padding: 10px;
}

.ports-list-container {
  max-height: 450px;
  overflow-y: scroll;
}

.ports-list-container::-webkit-scrollbar {
  width: 4px;
  background-color: #c2c2c2;
}

.ports-list-container::-webkit-scrollbar-thumb {
  background-color: #95aac9;
}

.tags-input-container {
  border: 1px solid #ced4da;
  border-radius: 5px;
  padding: 5px;
}

.w-25 {
  width: 25%;
}

.w-30 {
  width: 30%;
}

.w-40 {
  width: 40%;
}

/* Pagination component related - start */
.pagination-row {
  display: flex;
  margin: 0 40px;
  justify-content: end;
}

.pagination-row .page-count-select {
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  margin: 0 10px;
}

/* Pagination component related - end */

a.detailsdiv {
  display: inline-block;
  text-align: center;
  padding: 5px 10px;
  border-radius: 10px;
  color: white;
  cursor: pointer;
}

.color-secondary {
  background-color: #29bec1;
}

.link {
  color: #36b7b7 !important;
  cursor: pointer;
}

.reviews-list {
  padding: 0;
  list-style: none;
  text-align: left;
  border: 1px solid #eee;
}
.reviews-list li {
  padding: 10px;
}
.reviews-list li:not(:last-child) {
  border-bottom: 1px solid #eee;
}
.reviews-list li p {
  margin: 10px 0;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.bidlist-td {
  background-color: #9191912b;
}

.bidlist-open-tr1 {
  border: 2px solid #29bec1;
  border-bottom: none;
}

.bidlist-open-tr2 {
  border: 2px solid #29bec1;
  border-top: none;
}

.modal-header button {
  position: absolute;
  right: 15px;
  top: 10px;
}
.modal-body {
  padding-top: 0;
}

.page-title.page-title-flex.approved-users-heading {
  justify-content: flex-start !important;
}

.approved-users-heading span .user-type-select .user-type-select__value-container {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 30px;
}

.height-38px {
  height: 38px;
}

/* Custom Radio styles -- START */
/* The container */
.radio-container {
  display: inline-block;
  position: relative;
  padding-left: 35px;
  margin: 0 10px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.radio-container:hover .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.radio-container .checkmark.checked {
  background-color: #2196f3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-container .checkmark.checked:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio-container .checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

/* Custom Checkbox styles -- END */

.select-bid-container table td {
  padding: 0.5rem 0.5rem;
  font-weight: initial;
}

.select-bid-container table thead th {
  background-color: #f9fbfd;
  padding: 1rem 1rem;
  font-family: 'Poppins', sans-serif;
  text-transform: uppercase;
  font-weight: 500 !important;
  border: 0 !important;
}
.select-bid-container a.detailsdiv {
  min-width: 100px;
}

.bid-list-table tbody tr {
  border-bottom: 1px solid #ccc;
}

.bid-list-table tbody tr:last-of-type {
  border-bottom: 0px;
}

/* MUI Tabs styles -- start*/
button.Mui-selected:focus {
  outline: none;
}

.MuiTabs-root button {
  font-size: 20px;
}

.tabs-content-container {
  padding: 30px 20px;
}
button.MuiTab-textColorInherit {
  color: #007679 !important;
}

.MuiTabs-root .MuiTabs-scroller span.MuiTabs-indicator {
  background-color: #007679 !important;
}
/* MUI Tabs styles -- end*/

/* Added this to fix bug: modal content looking blurry */
.swal-modal {
  will-change: unset !important;
}

.settings-action-btn {
  margin-top: 2em;
}

@media only screen and (max-width: 540px) {
  .settings-action-btn {
    margin-top: 0;
    margin-bottom: 1em;
  }
}
