// Customize Bootstrap
@import 'basics/customize-bootstrap';

// Bootstrap
@import 'bootstrap/bootstrap';

// Partial --  Custom mixins and function
@import 'partial/mixins';
@import 'partial/function';

// Basics Files
@import 'basics/fonts';
@import 'basics/reset';
@import 'basics/icon';
@import 'basics/flexbox';
@import 'basics/layout';
@import 'basics/app';
@import 'basics/utility';
@import 'basics/ovarlay';

// Component
@import 'component/loader';
@import 'component/header';
@import 'component/sidebar';
@import 'component/accordion';
@import 'component/alert';
@import 'component/badge';
@import 'component/dropdown';
@import 'component/footer';
@import 'component/forms';
@import 'component/tables';
@import 'component/modal';
@import 'component/card';
@import 'component/progress';
@import 'component/tabs';
@import 'component/list';
@import 'component/button';
@import 'component/bootstrap-datepicker';
@import 'component/select2';
@import 'component/metismenu';
@import 'component/auto-complete';
@import 'component/datepicker';
@import 'component/pagination';

// Pages
@import 'pages/authentication-page';
