// Application Layour
%header-fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

body {
  &.sidebar-mini {
    .page-wrapper {
      // padding-top: 2rem;
      margin-left: $sidebar-mini-width;
      @include media-breakpoint-down(lg) {
        margin-left: 0 !important;
      }
    }
  }
  &.layout-fixed {
    .page-wrapper {
      padding-top: 2rem;
      @include media-breakpoint-down(lg) {
        margin-left: 0 !important;
      }
    }
    &.sidebar-mini {
      .left-sidebar {
        margin-left: -$sidebar-width;
        visibility: hidden;
      }
      .page-wrapper {
        margin-left: 0;
      }
    }
  }
}
