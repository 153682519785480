// Componant: Datepicker
.react-datepicker-popper {
  font-size: rem(14);
  z-index: $zindex-fixed;

  .react-datepicker {
    font-family: $font-Poppins;
    border: 1px solid rgba($black, 0.4);
    .react-datepicker__header {
      background-color: $primary;
      color: $white;
      padding-top: 7px;
      .react-datepicker__day-names {
        .react-datepicker__day-name {
          margin: 0.5rem 0.166rem;
          font-weight: 500;
        }
      }
    }
    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker__day-name {
      color: $white;
    }

    .react-datepicker__navigation--previous {
      border-right-color: rgba($white, 0.3);
    }
    .react-datepicker__navigation--next {
      border-left-color: rgba($white, 0.3);
    }
    .react-datepicker__triangle {
      border-bottom-color: $primary;
    }
    .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle,
    .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-year-read-view--down-arrow,
    .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before,
    .react-datepicker__year-read-view--down-arrow::before,
    .react-datepicker__month-read-view--down-arrow::before,
    .react-datepicker__month-year-read-view--down-arrow::before {
      border-bottom-color: $primary;
    }
  }
}
.react-datepicker-popper[data-placement^="bottom"] {
  margin-top: 9px;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  background-color: $primary;
  font-weight: inherit;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 2rem;
  line-height: 2rem;
}
.react-datepicker__month {
  margin: 0.75rem 0.4rem;
}
.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: #0078a7;
}
.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
  background-color: #fff;
  color: #0078a7;
}

.react-datepicker-popper .react-datepicker .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle,
.react-datepicker-popper .react-datepicker .react-datepicker__year-read-view--down-arrow,
.react-datepicker-popper .react-datepicker .react-datepicker__month-read-view--down-arrow,
.react-datepicker-popper .react-datepicker .react-datepicker__month-year-read-view--down-arrow,
.react-datepicker-popper
  .react-datepicker
  .react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::before,
.react-datepicker-popper .react-datepicker .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker-popper .react-datepicker .react-datepicker__month-read-view--down-arrow::before,
.react-datepicker-popper .react-datepicker .react-datepicker__month-year-read-view--down-arrow::before {
  border-bottom-color: rgba(255, 255, 255, 0.3);
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  border-top-color: rgba(255, 255, 255, 0.3);
}
