// Modal
.modal-full {
  max-width: 98%;
  width: 100%;
}
.modal-content {
  @include border-radius(20px);
}
.modal-header {
  border: 0;
  .modal-title,
  .card-titles {
    padding-top: 20px;
    padding-bottom: 10px;
    color: $black;
    font-size: rem(20);
    font-weight: 600;
    line-height: 27px;
  }
}

.product-list-scroll {
  overflow-y: auto;
  height: 350px;
}
.modal-rp {
  &.modal-dialog {
    max-width: 720px !important;
  }
}

.modal-md {
  &.modal-dialog {
    max-width: 560px !important;
  }
}

.pac-container {
  z-index: 1050;
}
