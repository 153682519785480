// Datepicker
.datepicker {
    &.dropdown-menu {
        z-index: 1030 !important;
        min-width: 13rem;
        padding: 0.75rem;
    }
    th,
    td {
        width: 35px;
        height: 30px;
    }
}