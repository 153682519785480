// Page: Authentication Pages
.auth-wrapper {
  $input-focus-border-color: rgba($white, 0.5);
  min-height: 100vh;
  position: relative;
  background-position: right top;
  background-color: $white;

  .card {
    padding: 20px;
  }
  .auth-content {
    width: 100%;
    height: 100vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    flex-wrap: wrap;
  }
  .auth-heading {
    margin-bottom: 28px;
    h2 {
      font-size: rem(30);
      line-height: 46px;
      font-weight: 500;
      span {
        font-size: rem(15);
      }
    }
  }
  .content-logo {
    padding: 2rem;
    border-radius: 2px;
    position: relative;
    @extend .flexbox-align-c;
  }
  .content-form {
    width: 360px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    @extend .flexbox-align-c;
  }
  .card-form {
    width: 100%;
  }
}
