// Componant: Pagination

.pagination {
  .page-item:not(.disabled) {
    &:hover {
      svg {
        g {
          path {
            fill: $primary;
          }
        }
      }
    }
  }
  .page-link {
    border-color: $gray-200 !important;
    font-weight: $font-weight-normal;
  }
  .break-me {
    a {
      @extend .page-link;
    }
  }
  @include media-breakpoint-down(sm) {
    .page-link {
      padding: 0.75rem 1rem;
    }
  }
}
