// Componant: Button

button {
  cursor: pointer;
}
.btn {
  text-transform: inherit;
  overflow: hidden;
  font-weight: 400;
  font-size: rem(14);
  // @extend .shadow-sm;
  background-position: center;
}
.btn-fix {
  max-width: 100%;
  min-width: 223px;
  @include media-breakpoint-down(md) {
    min-width: 143px;
  }
}
.btn-modal {
  max-width: 100%;
  width: 142px;
}
.sidebar-toggler {
  color: $white;
  @extend .flexbox-align-c;
}
.btn-clear {
  @include button($secondary, transparent, transparent);
  @extend .shadow-none;
}

.btn-rounded {
  @include border-radius(100px);
}

@each $color, $value in $theme-colors {
  .btn-#{$color}-light {
    background-color: rgba($value, 0.15);
    color: $value;
    &:hover,
    &:active,
    &:focus,
    &:active:hover {
      background-color: rgba($value, 0.25);
      color: $value;
    }
  }
}

.btn-m-w {
  min-width: 107px;
}
.btn-m-w-lg {
  min-width: 118px;
}
