.metismenu {
  overflow: hidden;
  position: relative;
  > .metismenu-container > .metismenu-item > .metismenu-link {
    @extend .d-flex;
    @extend .align-items-center;
    padding-left: 54px;
    height: 53px;
    // .metismenu-state-icon {
    //   height: 53px;
    // }
  }
}

.metismenu-container,
.metismenu-item {
  margin: 0;
  padding: 0;
}

.metismenu-container {
  list-style: none;
  .metismenu-container {
    transition: padding 0.3s;
    -webkit-transition: padding 0.3s;
    background: rgba(255, 255, 255, 0.05);
    .metismenu-item > .metismenu-link {
      height: 0;
      overflow: hidden;
      font-weight: 300 !important;
    }
    .metismenu-link {
      padding-left: 54px;
      position: relative;
      &:before {
        // content: "";
        // width: 6px;
        // height: 6px;
        // background-color: $black;
        // display: block;
        // position: absolute;
        // left: 25px;
        // top: 23.5px;
        // @include border-radius(50%);
      }
    }
    .metismenu-container .metismenu-link {
      padding-left: 4.0625rem;
      position: relative;
      &:before {
        content: "";
        width: 9px;
        height: 2px;
        background-color: $black;
        display: block;
        position: absolute;
        left: 40.5px;
        top: 23.5px;
        @include border-radius(0);
        opacity: 0.5;
      }
    }
    .metismenu-container {
      .metismenu-item > .metismenu-link {
        font-weight: 500 !important;
        opacity: 0.5;
        &:before {
          opacity: 1;
        }
        &.active,
        &:hover {
          color: $dark;
          opacity: 1;
          background-color: transparent;
        }
      }
    }
  }
  &.visible {
    // padding: 0.5em 0;
    > .metismenu-item > .metismenu-link {
      height: 53px;
      @extend .d-flex;
      @extend .align-items-center;
    }
  }
}

.metismenu-link {
  transition: height 0.3s, color 0.3s, background-color 0.3s;
  -webkit-transition: height 0.3s, color 0.3s, background-color 0.3s;
  display: block;
  height: 53px;
  position: relative;
}

// i {
//   &.metismenu-icon {
//     text-align: center;
//   }
//   &.metismenu-state-icon {
//     display: inline-block;
//     margin-left: 0.255em;
//     vertical-align: 0.255em;
//     background-image: url("/images/svg/arrow-submenu.svg");
//     background-size: contain;
//     background-repeat: no-repeat;
//     position: absolute;
//     right: 1rem;
//     top: 50%;
//     @include translate(1rem, -50%);
//     width: 10.05px;
//     height: 5.64px;
//     &.rotate-minus-90 {
//       transform: rotate(90deg);
//       -webkit-transform: rotate(90deg);
//     }
//   }
// }
