::selection {
  color: $white;
  background-color: $primary;
}

::-webkit-scrollbar {
  height: 2px;
  width: 3px;
}

::-webkit-scrollbar-thumb {
  background-color: $gray-300;
  @include border-radius(20px);
  height: 100px;
}

::-webkit-scrollbar-corner {
  background: #000;
}

body {
  font-size: rem(14);
  line-height: 21px;
  letter-spacing: 0.2px;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-Poppins;
  font-weight: $headings-font-weight;
  line-height: 30px;
}
h2 {
  font-size: rem(30);
  line-height: 37px;
}
h3 {
  font-size: rem(20);
}

h5 {
  font-size: rem(26);
  line-height: 39px;
}

a {
  // @include link($primary, #551a8b, $primary, $secondary);
}

a,
button {
  @include default_transition(0.3s, ease-in-out);
}
.font-weight-semibold {
  font-weight: 600 !important;
  &.custom-control-label {
    font-weight: 600 !important;
  }
}

.w-60 {
  width: 60px;
}

.mw-auto {
  max-width: unset !important;
}

.custom-control-label.text-dark {
  color: $dark !important;
}

.useravtar {
  width: 40px;
  height: 40px;
  @extend .mr-3;
  @include border-radius(50%);
  svg path {
    fill: #8c9196;
  }
}
.pro-loader {
  padding: 30px;
  text-align: center;
}

.image-banner {
  border: 1px solid rgba($black, 0.05);
  @extend .shadow-sm;
  @extend .rounded;
  @extend .overflow-hidden;
  img {
    height: 300px;
    width: 100%;
    object-fit: contain;
  }
}

.truncate-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  text-overflow: -o-ellipsis-lastline;
}

.l-h-21 {
  line-height: 21px;
}
.sm-hide {
  @include media-breakpoint-down(md) {
    display: none;
  }
}
