// Componant: Form
.form-group {
  margin-bottom: 27px;
  label {
    font-size: rem(15);
    font-weight: $font-weight-medium;
    display: block;
    color: $gray-900;
    margin-bottom: 0.75rem;
    text-transform: capitalize;
  }
}
.fg-mb {
  margin-bottom: 27px;
}

.form-control {
  font-weight: $font-weight-normal;
  color: $gray-900;
  font-size: rem(14);
  background-color: transparent;
  @include input-placeholder {
    color: $gray-600;
    // @include opacity(0.7);
  }
  &:focus {
    background-color: transparent;
    color: $gray-900;
  }
}

.f-c-sm {
  .form-control {
    @extend .form-control-sm;
  }
}
.p-show {
  @include input-placeholder {
    font-size: 15px;
  }
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
}

select::-ms-expand {
  display: none;
}
select.nav-select {
  padding-right: 55px !important;
  // background-image: url(../../icon/nav-filter.svg);
  background-size: 35px 35.93px;
  background-position: right center;
  background-repeat: no-repeat;
  option {
    color: $dark;
  }
}

select.select-icon {
  background-image: url("/images/arrow-down.svg");
  background-position: right 11px center;
  background-repeat: no-repeat;
  background-size: 11px;
  padding-right: 1.75rem;
}
.f-c-w {
  @include media-breakpoint-down(md) {
    width: 225px;
  }
}

.custom-control-mt {
  margin-top: 20px;
}
.custom-control-label {
  padding-top: 2.5px;
  font-weight: 300 !important;
  color: $gray-600 !important;
  font-size: rem(14) !important;
}

.invalid-feedback {
  font-size: rem(12);
  margin-top: 12px;
}

.cocument-upload {
  text-align: center;
  height: 275px;
  padding: 10px 20px;
  @include border-radius(10px);
  border: 3px dashed $primary;
  @extend .flexbox-align-c;
  flex-direction: column;
}

.input-search {
  background-image: url(/images/svg/search.svg);
  background-position: left 0.75rem center;
  background-repeat: no-repeat;
  padding-left: 2.5rem;
}

.css-1pahdxg-control,
.css-yk16xz-control {
  border-radius: 5px !important;
  &:hover,
  &:focus {
    box-shadow: none !important;
    outline: none !important;
    border-color: $primary !important;
    svg {
      path {
        fill: $primary;
      }
    }
  }
  svg {
    path {
      fill: $black;
    }
  }
}

.custom-control-inline {
  @include media-breakpoint-down(sm) {
    &.ml-3 {
      margin-left: 0 !important;
    }
  }
}

.switch {
  .custom-switch {
    .custom-control-input {
      &:checked {
        ~ {
          .custom-control-label {
            z-index: 3;
            &::after {
              transform: translateX(2.15rem);
              background-color: $success;
            }
            &:before {
              border-color: $success;
              background-color: transparent;
            }
          }
          .switch-label {
            &:before {
              content: attr(data-text-on);
              color: $success;
              left: 1.25rem;
            }
            &:after {
              left: 2.72rem;
            }
          }
        }
      }
    }
  }
  .switch-label {
    cursor: pointer;
    display: block;
    &:before {
      font-family: $font-Poppins;
      content: attr(data-text-off);
      color: $primary;
      position: absolute;
      top: 50%;
      left: 50%;
      @include translate(-50%, -50%);
      top: 1rem;
      left: 2.4rem;
      font-size: rem(10);
      line-height: 13px;
      font-weight: $font-weight-medium;
      text-transform: uppercase;
    }
    &:after {
      content: "";
      width: 6px;
      height: 6px;
      background-color: $white;
      display: block;
      @include border-radius(50%);
      position: absolute;
      left: 9px;
      top: 13px;
      z-index: 3;
    }
  }
  .custom-control-label {
    z-index: 3;
    background-color: transparent;
    &:before {
      cursor: pointer;
      background-color: transparent;
      width: 3.625rem;
      height: 1.5rem;
      @include border-radius(25px);
      border-color: $primary;
    }
    &:after {
      width: 1.25rem;
      height: 1.25rem;
      @include border-radius(50%);
      background-color: $primary;
      cursor: pointer;
    }
  }

  .custom-control-label {
    &:before {
      // width: 4.625rem;
    }
  }
}

.md-input {
  position: relative;
  margin-bottom: 30px;
  .md-form-control {
    font-size: rem(14);
    padding: 10px 10px 10px 0;
    display: block;
    border: none;
    border-bottom: 2px solid #cacaca;
    box-shadow: none;
    width: 100%;
    &:focus {
      ~ {
        label {
          top: -15px;
          font-size: rem(14);
          color: $light-color;
          font-weight: $font-weight-normal;
        }
        .bar {
          &:before {
            width: 50%;
          }
          &:after {
            width: 50%;
          }
        }
      }
    }
    &:valid {
      ~ {
        label {
          top: -15px;
          font-size: 14px;
          color: $light-color;
        }
      }
    }
  }
  label {
    line-height: 21px;
    color: $light-color;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 0;
    top: 20px;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }
  .bar {
    &:before {
      left: 50%;
      content: "";
      height: 2px;
      width: 0;
      bottom: 0px;
      position: absolute;
      background: $primary;
      transition: 0.2s ease all;
      -moz-transition: 0.2s ease all;
      -webkit-transition: 0.2s ease all;
    }
    &:after {
      right: 50%;
      content: "";
      height: 2px;
      width: 0;
      bottom: 0px;
      position: absolute;
      background: $primary;
      transition: 0.2s ease all;
      -moz-transition: 0.2s ease all;
      -webkit-transition: 0.2s ease all;
    }
  }
  .highlight {
    position: absolute;
    height: 60%;
    width: 100px;
    top: 25%;
    left: 0;
    pointer-events: none;
    opacity: 0.5;
  }
}

.form-column {
  @extend .form-control;
  border: 0;
  & > label {
    padding-top: 7px;
  }
}

.document-upload {
  text-align: center;
  height: 200px;
  width: 100%;
  padding: 1rem;
  @include border-radius(10px);
  border: 2px dashed $border-color;
  @extend .flexbox-align-c;
  flex-direction: column;
  & > label {
    font-weight: 400;
    font-size: rem(12);
    // line-height: 16px;
    @extend .text-muted;
    u {
      @extend .text-primary;
    }
  }
  &-sm {
    height: 100px;
  }
}

.input-icon {
  max-width: 200px;
  @extend .position-relative;
  &.icon-left {
    span {
      left: 0;
      right: auto;
    }
    .form-control {
      padding-left: 2rem;
      padding-right: 0.75rem;
    }
  }
  &.icon-left-lg {
    span {
      left: 0.85rem;
      right: auto;
      z-index: 0;
    }
    .form-control {
      z-index: 3;
      position: relative;
      padding-right: 0.75rem;
    }
  }
  span {
    @extend .position-absolute;
    right: 0;
    top: 0;
    padding: 0.5rem 1rem;
    border: 1px solid transparent;
  }
  .form-control {
    padding-right: 2rem;
  }
  &.input-label {
    .form-control {
      padding-right: 0.75rem;
    }
    span {
      padding: 0.5rem 0;
      right: auto;
      left: calc(100% - 50px);
    }
  }
}

.css-xb97g8 {
  &:hover {
    background-color: rgba($primary, 0.25) !important;
    // color: $white !important;
  }
}
.invalid-show {
  display: block !important;
}

.select2 {
  .css-2b097c-container {
    flex: auto;
  }
}
