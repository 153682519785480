// Componant: Sidebar
.left-sidebar {
  width: $sidebar-width;
  background-color: $sidebar-color;
  // min-height: 100%;
  @extend .fixed-top;
  @extend .border-right;
  @extend .d-flex;
  @extend .flex-column;
  @include default_transition(0.3s, ease-in-out);
  z-index: 1001;
  height: 92vh;
  top: $header-height;
  overflow-y: auto;
  padding-bottom: 15px;
  .nav-header {
    padding: 1.5rem 1rem;
    @extend .d-flex;
    @extend .justify-content-between;
    @extend .align-items-center;
    .btn {
      @extend .p-0;
    }
    .navbar-brand {
      @extend .p-0;
      @extend .mr-0;
    }
  }
  .metismenu {
    background-color: transparent;
    height: auto;
    overflow-y: auto;
    .sidebar-menu {
      min-width: $sidebar-width;
      font-size: rem(15);
      .metismenu-item {
        .metismenu-link {
          font-weight: $font-weight-medium;
          color: $gray-900;
          font-size: rem(14);
          position: relative;
          &:after {
            content: '';
            height: 31px;
            width: 4px;
            @include border-radius(25px);
            position: absolute;
            left: 0;
            top: 0;
            top: 50%;
            @include translate(-50%, -50%);
            display: block;
            background-color: transparent;
            @include default_transition(0.3s, ease-in-out);
          }
          &.active,
          &:hover {
            &:after {
              background-color: $primary;
            }
            color: $primary;
            .metismenu-icon {
              filter: unset !important;
            }
          }
        }
      }
      .metismenu-link {
        color: $black;
        text-transform: capitalize;
        &:hover {
          text-decoration: none;
        }
        .nav-icon {
          @include default_transition(0.2s, linear);
        }
      }
    }
  }
  .sidebar-logout {
    padding-top: 20px;
    padding-bottom: 20px;
    .btn-logout {
      &:hover {
        color: $danger;
        svg {
          fill: $danger;
        }
      }
    }
  }
}

body:not(.layout-fixed).sidebar-mini {
  .left-sidebar {
    width: $sidebar-mini-width;
    .sidebar-menu {
      & > li {
        & > .metismenu-link {
          & > .nav-label {
            display: none;
          }
          & > .nav-arrow {
            display: none;
          }
        }
      }
      li {
        .nav-level-2 {
          display: none;
          position: absolute;
          top: 0;
          left: 100%;
          min-width: 250px;
          padding: 10px 0;
          border-left: 1px solid rgba($body-color, 0.1);
          .metismenu-link {
            padding-left: 15px;
          }
        }
        .nav-level-3 {
          .metismenu-link {
            padding-left: 25px;
          }
        }
        &:hover {
          .nav-level-2 {
            display: block;
            height: auto !important;
            opacity: 1;
            visibility: visible;
          }
          .metismenu-link {
            .nav-icon {
              color: $gray-900;
            }
          }
        }
      }
      *[class^='nav-level'] {
        background-color: $white;
      }
    }
  }
  .left-sidebar {
    // &:hover {
    //   width: $sidebar-width;
    //   .metismenu-link {
    //     & > span {
    //       @include media-breakpoint-up(md) {
    //         display: block;
    //       }
    //     }
    //   }
    // }
  }
}

.sidebar-mini {
  .tooltip-sidebar-toggle {
    .fa-bars {
      &:before {
        content: '\f061';
      }
    }
  }
  .metismenu-link {
    & > span {
      // display: block;
      @include media-breakpoint-up(lg) {
        opacity: 0;
      }
    }
  }
}

@media (max-width: 768px) {
  .left-sidebar {
    margin-left: -$sidebar-width;
    box-shadow: none;
    width: $sidebar-width !important;
  }
  .page-wrapper {
    margin-left: unset;
  }
  .sidebar-mini {
    .left-sidebar {
      margin-left: 0;
      box-shadow: none;
    }
  }
}
