// App
.layout {
  width: 100%;
  min-height: 100%;
  position: relative;
  /*overflow: hidden;*/
}

.page-wrapper {
  position: relative;
  margin-left: $sidebar-width;
  margin-top: $header-height;
  min-height: calc(100vh - #{$header-height});
  padding: 30px 30px 0;
  @include transition(margin 0.3s ease-in-out);
  @include media-breakpoint-down(md) {
    padding: 30px 1rem 0;
    margin-top: 56px;
  }
}

.page-content {
  padding-bottom: 40px;
  .page-title {
    margin-bottom: 28px;
    .page-heading {
      font-size: rem(26);
      line-height: 30px;
      font-weight: $font-weight-medium;
      & > span {
        cursor: pointer;
      }
    }
    &.page-title-flex {
      @extend .d-md-flex;
      @extend .justify-content-between;
      @extend .align-items-center;
    }
    .small {
      font-size: rem(14);
    }
  }
  &.no-page-title {
    margin-top: 23px;
  }
}

.product-list {
  &-item {
    padding-bottom: 0.5rem;
    .product-list-item {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      border-bottom: 1px solid rgba($border-color, 0.5);
    }
  }
  .product-list {
    margin-top: 0.5rem;
    padding-top: 0.5rem;
    padding-left: 1.5rem;
    padding-bottom: 0.5rem;
    @extend .border-top;
    @include media-breakpoint-down(md) {
      padding-left: 0.5rem;
    }
  }
}

.flex-1 {
  flex: 1;
}

.video-wrapper {
  & > div {
    max-width: 100%;
  }
  iframe {
    @extend .embed-responsive;
    max-height: 350px;
    // @extend .embed-responsive-21by9;
  }
}
