// Componant: List

.media-list {
    .media {
        padding: 0.55rem 0;
        &:last-child {
            padding-bottom: 0;
        }
    }
}
.media-list-divider {
    .media:not(:first-child) {
        padding: 1rem 0;
        border-top: 1px solid $list-group-border-color;
    }
}
.list-mb-40 {
    li:not(:last-child) {
        margin-bottom: 40px;
    }
}
.list-report {
    li {
        @extend .d-flex;
        @extend .justify-content-between;
        @extend .border-0;
        @extend .px-0;
        @extend .py-2;
    }
}

.uploaded-media {
    margin-top: 1.5rem;
}

.um-item {
    height: 100px;
    width: 100px;
    @extend .border;
    @extend .rounded;
    @extend .position-relative;
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    .um-hover {
        padding: 0.4rem 0.5rem;
        @include default_transition(0.3s, ease-in-out);
        background-color: rgba($gray-600, 0.1);
        @extend .position-absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
    }
    &:hover {
        .um-hover {
            opacity: 1;
        }
    }
    @include media-breakpoint-down(lg) {
        height: 110px;
    }
    @include media-breakpoint-down(sm) {
        height: 75px;
    }
}
