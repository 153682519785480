// Componant: badge
.badge {
  @extend .font-weight-normal;
  @extend .text-uppercase;
  font-size: 80%;
}
.badge-point {
  display: inline-block;
  height: 8px;
  width: 8px;
  @include border-radius(50%);
  padding: 0;
}
.badge-purple {
  color: #fff;
  background-color: #8e24aa;
}
.badge-orange {
  color: #fff;
  background-color: #fb8c00;
}
.badge-warning {
  color: #fff;
}
