//
// Customize bootstrap variables to match
// look and feel of the theme
//-----------------------------------------------------
// stylelint-disable
$white: #fff !default;
$gray-100: #f9fbfd !default;
$gray-200: #ececec !default;
$gray-300: #e3ebf6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #95aac9 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #12263f !default;
$black: #000 !default;

/*Theme color*/

$primary: #36b7b7;
$secondary: #878787;
$success: #00d97e;
$info: #39afd1;
$warning: #f6c343;
$danger: #e63757;
$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
);
$body-bg: $gray-100;
$body-color: $gray-900;
$base-shadow-color: #005595;
$nav-base-color: #1e2224;
$light-color: #b4b4b4;

// Customize typographic styles
$font-size-base: 1rem;
$font-family-sans-serif: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
$link-color: $primary;
$headings-line-height: 21px !default;
$small-font-size: 90%;

// Customize button styles
$btn-border-radius: 7px;
$font-weight-medium: 500 !default;
$btn-font-weight: 400 !default;
$btn-transition: box-shadow 0.3s cubic-bezier(0.35, 0, 0.25, 1), transform 0.2s cubic-bezier(0.35, 0, 0.25, 1), background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
$line-height-lg: 1.75 !default;
$input-btn-padding-y: 0.65rem !default;
$input-btn-padding-x: 0.75rem !default;
$input-btn-padding-y-lg: 0.4rem !default;
$input-btn-padding-x-lg: 1.6rem !default;
$input-btn-padding-y-sm: 0.5rem !default;
$input-btn-padding-x-sm: 0.75rem !default;
// Custmize form component styles
$input-border-radius: 0;
$input-border-radius-lg: 5px;
$input-border-radius-sm: 5px;
$input-focus-border-color: $primary;
$input-btn-border-width: 1px;
$input-btn-focus-box-shadow: none;
$input-btn-focus-width: 0;
$input-disabled-bg: rgba(#dfe6ec, 0.27) !default;
// Dropdowns
// Dropdown menu container and contents.
$dropdown-padding-y: 0.75rem;
$dropdown-item-padding-y: 0.75rem;
$dropdown-item-padding-x: 1.5rem;
$dropdown-header-color: $black;
$dropdown-min-width: 10rem !default;
$dropdown-padding-y: 0.5rem !default;
$dropdown-spacer: 0.125rem !default;
$dropdown-bg: $white !default;
$dropdown-border-color: rgba($black, 0.15) !default;

$dropdown-divider-bg: $gray-300 !default;
$dropdown-box-shadow: 0 0.5rem 1rem rgba($black, 0.175) !default;
$dropdown-link-color: $body-color !default;
$dropdown-link-hover-color: darken($body-color, 5%) !default;
$dropdown-link-hover-bg: $gray-100 !default;
$dropdown-link-active-color: darken($body-color, 5%) !default;
$dropdown-link-active-bg: darken($gray-200, 5%) !default;
// Forms
$input-color: $primary;
// Links
//
// Style anchor elements.
$link-hover-decoration: underline !default;
// List
$list-group-border-color: rgba($primary, 0.4);

// card
$card-border-radius: 1rem;
$card-color: $body-color;
$card-spacer-y: 1.5rem !default;
$card-spacer-x: 1.5rem !default;

// progress bar
$progress-height: 0.4rem !default;
$progress-border-radius: 5px;
$progress-height-lg: 0.8rem !default;
$progress-height-xl: 1.2rem !default;

// badge
$badge-padding-y: 0.5em !default;
$badge-padding-x: 0.8em !default;
// Tables
$table-head-color: $black !default;
$table-accent-bg: rgba($primary, 0.05);
$table-border-color: $gray-200;
$table-border-width: 2px;
// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.
$table-cell-padding: 0.75rem !default;
$table-cell-padding-sm: 0.45rem !default;

//shadow
$box-shadow-sm: 0 0.125rem 0.25rem rgba($gray-900, 0.02) !default;
$box-shadow: 0 0.5rem 1rem rgba($gray-900, 0.03) !default;
$box-shadow-lg: 0 1rem 3rem rgba($gray-900, 0.06) !default;

// Pagination

$pagination-padding-y: 0.875rem !default;
$pagination-padding-x: 1.25rem !default;
$pagination-padding-y-sm: 0.25rem !default;
$pagination-padding-x-sm: 0.5rem !default;
$pagination-padding-y-lg: 0.75rem !default;
$pagination-padding-x-lg: 1.5rem !default;
$pagination-line-height: 1.25 !default;

$pagination-color: $secondary !important;
$pagination-bg: $white !default;
$pagination-border-color: $gray-200 !default;
$pagination-hover-bg: $white !important;
$pagination-hover-color: $primary !important;
$pagination-active-color: $primary !important;
$pagination-active-bg: $white !default;

$modal-xl: 1140px !default;
$modal-lg: 800px !default;
$modal-md: 795px !default;
$modal-sm: 300px !default;

//
// Application Variables
//-----------------------------------------------------

// ------- Header --------
$header-height: 70px;
$footer-height: 70px;

// ------- Sidebar --------
$sidebar-width: 250px;
$sidebar-mini-width: 60px;
$sidebar-color: #ffffff; //
// Customize bootstrap variables to match
// look and feel of the theme
//-----------------------------------------------------
// stylelint-disable
$white: #fff !default;
$gray-100: #f9fbfd !default;
$gray-200: #ececec !default;
$gray-300: #e3ebf6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #95aac9 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #12263f !default;
$black: #000 !default;

/*Theme color*/

$primary: #36b7b7;
$secondary: #878787;
$success: #00d97e;
$info: #39afd1;
$warning: #f6c343;
$danger: #e63757;
$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
);
$body-bg: $gray-100;
$body-color: $gray-900;
$base-shadow-color: #005595;
$nav-base-color: #1e2224;
$light-color: #b4b4b4;

// Customize typographic styles
$font-size-base: 1rem;
$font-family-sans-serif: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
$link-color: $primary;
$headings-line-height: 21px !default;
$small-font-size: 90%;

// Customize button styles
$btn-border-radius: 7px;
$font-weight-medium: 500 !default;
$btn-font-weight: 400 !default;
$btn-transition: box-shadow 0.3s cubic-bezier(0.35, 0, 0.25, 1), transform 0.2s cubic-bezier(0.35, 0, 0.25, 1), background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
$line-height-lg: 1.75 !default;
$input-btn-padding-y: 0.65rem !default;
$input-btn-padding-x: 0.75rem !default;
$input-btn-padding-y-lg: 0.4rem !default;
$input-btn-padding-x-lg: 1.6rem !default;
$input-btn-padding-y-sm: 0.5rem !default;
$input-btn-padding-x-sm: 0.75rem !default;
// Custmize form component styles
$input-border-radius: 0;
$input-border-radius-lg: 5px;
$input-border-radius-sm: 5px;
$input-focus-border-color: $primary;
$input-btn-border-width: 1px;
$input-btn-focus-box-shadow: none;
$input-btn-focus-width: 0;
$input-disabled-bg: rgba(#dfe6ec, 0.27) !default;
// Dropdowns
// Dropdown menu container and contents.
$dropdown-padding-y: 0.75rem;
$dropdown-item-padding-y: 0.75rem;
$dropdown-item-padding-x: 1.5rem;
$dropdown-header-color: $black;
$dropdown-min-width: 10rem !default;
$dropdown-padding-y: 0.5rem !default;
$dropdown-spacer: 0.125rem !default;
$dropdown-bg: $white !default;
$dropdown-border-color: rgba($black, 0.15) !default;

$dropdown-divider-bg: $gray-300 !default;
$dropdown-box-shadow: 0 0.5rem 1rem rgba($black, 0.175) !default;
$dropdown-link-color: $body-color !default;
$dropdown-link-hover-color: darken($body-color, 5%) !default;
$dropdown-link-hover-bg: $gray-100 !default;
$dropdown-link-active-color: darken($body-color, 5%) !default;
$dropdown-link-active-bg: darken($gray-200, 5%) !default;
// Forms
$input-color: $primary;
// Links
//
// Style anchor elements.
$link-hover-decoration: underline !default;
// List
$list-group-border-color: rgba($primary, 0.4);

// card
$card-border-radius: 1rem;
$card-color: $body-color;
$card-spacer-y: 1.5rem !default;
$card-spacer-x: 1.5rem !default;

// progress bar
$progress-height: 0.4rem !default;
$progress-border-radius: 5px;
$progress-height-lg: 0.8rem !default;
$progress-height-xl: 1.2rem !default;

// badge
$badge-padding-y: 0.5em !default;
$badge-padding-x: 0.8em !default;
// Tables
$table-head-color: $black !default;
$table-accent-bg: rgba($primary, 0.05);
$table-border-color: $gray-200;
$table-border-width: 2px;
// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.
$table-cell-padding: 0.75rem !default;
$table-cell-padding-sm: 0.45rem !default;

//shadow
$box-shadow-sm: 0 0.125rem 0.25rem rgba($gray-900, 0.02) !default;
$box-shadow: 0 0.5rem 1rem rgba($gray-900, 0.03) !default;
$box-shadow-lg: 0 1rem 3rem rgba($gray-900, 0.06) !default;

// Pagination

$pagination-padding-y: 0.875rem !default;
$pagination-padding-x: 1.25rem !default;
$pagination-padding-y-sm: 0.25rem !default;
$pagination-padding-x-sm: 0.5rem !default;
$pagination-padding-y-lg: 0.75rem !default;
$pagination-padding-x-lg: 1.5rem !default;
$pagination-line-height: 1.25 !default;

$pagination-color: $secondary !important;
$pagination-bg: $white !default;
$pagination-border-color: $gray-200 !default;
$pagination-hover-bg: $white !important;
$pagination-hover-color: $primary !important;
$pagination-active-color: $primary !important;
$pagination-active-bg: $white !default;

$modal-xl: 1140px !default;
$modal-lg: 800px !default;
$modal-md: 795px !default;
$modal-sm: 300px !default;

//
// Application Variables
//-----------------------------------------------------

// ------- Header --------
$header-height: 70px;
$footer-height: 70px;

// ------- Sidebar --------
$sidebar-width: 250px;
$sidebar-mini-width: 60px;
$sidebar-color: #ffffff; //
// Customize bootstrap variables to match
// look and feel of the theme
//-----------------------------------------------------
// stylelint-disable
$white: #fff !default;
$gray-100: #f9fbfd !default;
$gray-200: #ececec !default;
$gray-300: #e3ebf6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #95aac9 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #12263f !default;
$black: #000 !default;

/*Theme color*/

$primary: #36b7b7;
$secondary: #878787;
$success: #00d97e;
$info: #39afd1;
$warning: #f6c343;
$danger: #e63757;
$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
);
$body-bg: $gray-100;
$body-color: $gray-900;
$base-shadow-color: #005595;
$nav-base-color: #1e2224;
$light-color: #b4b4b4;

// Customize typographic styles
$font-size-base: 1rem;
$font-family-sans-serif: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
$link-color: $primary;
$headings-line-height: 21px !default;
$small-font-size: 90%;

// Customize button styles
$btn-border-radius: 7px;
$font-weight-medium: 500 !default;
$btn-font-weight: 400 !default;
$btn-transition: box-shadow 0.3s cubic-bezier(0.35, 0, 0.25, 1), transform 0.2s cubic-bezier(0.35, 0, 0.25, 1), background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
$line-height-lg: 1.75 !default;
$input-btn-padding-y: 0.65rem !default;
$input-btn-padding-x: 0.75rem !default;
$input-btn-padding-y-lg: 0.4rem !default;
$input-btn-padding-x-lg: 1.6rem !default;
$input-btn-padding-y-sm: 0.5rem !default;
$input-btn-padding-x-sm: 0.75rem !default;
// Custmize form component styles
$input-border-radius: 0;
$input-border-radius-lg: 5px;
$input-border-radius-sm: 5px;
$input-focus-border-color: $primary;
$input-btn-border-width: 1px;
$input-btn-focus-box-shadow: none;
$input-btn-focus-width: 0;
$input-disabled-bg: rgba(#dfe6ec, 0.27) !default;
// Dropdowns
// Dropdown menu container and contents.
$dropdown-padding-y: 0.75rem;
$dropdown-item-padding-y: 0.75rem;
$dropdown-item-padding-x: 1.5rem;
$dropdown-header-color: $black;
$dropdown-min-width: 10rem !default;
$dropdown-padding-y: 0.5rem !default;
$dropdown-spacer: 0.125rem !default;
$dropdown-bg: $white !default;
$dropdown-border-color: rgba($black, 0.15) !default;

$dropdown-divider-bg: $gray-300 !default;
$dropdown-box-shadow: 0 0.5rem 1rem rgba($black, 0.175) !default;
$dropdown-link-color: $body-color !default;
$dropdown-link-hover-color: darken($body-color, 5%) !default;
$dropdown-link-hover-bg: $gray-100 !default;
$dropdown-link-active-color: darken($body-color, 5%) !default;
$dropdown-link-active-bg: darken($gray-200, 5%) !default;
// Forms
$input-color: $primary;
// Links
//
// Style anchor elements.
$link-hover-decoration: underline !default;
// List
$list-group-border-color: rgba($primary, 0.4);

// card
$card-border-radius: 1rem;
$card-color: $body-color;
$card-spacer-y: 1.5rem !default;
$card-spacer-x: 1.5rem !default;

// progress bar
$progress-height: 0.4rem !default;
$progress-border-radius: 5px;
$progress-height-lg: 0.8rem !default;
$progress-height-xl: 1.2rem !default;

// badge
$badge-padding-y: 0.5em !default;
$badge-padding-x: 0.8em !default;
// Tables
$table-head-color: $black !default;
$table-accent-bg: rgba($primary, 0.05);
$table-border-color: $gray-200;
$table-border-width: 2px;
// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.
$table-cell-padding: 0.75rem !default;
$table-cell-padding-sm: 0.45rem !default;

//shadow
$box-shadow-sm: 0 0.125rem 0.25rem rgba($gray-900, 0.02) !default;
$box-shadow: 0 0.5rem 1rem rgba($gray-900, 0.03) !default;
$box-shadow-lg: 0 1rem 3rem rgba($gray-900, 0.06) !default;

// Pagination

$pagination-padding-y: 0.875rem !default;
$pagination-padding-x: 1.25rem !default;
$pagination-padding-y-sm: 0.25rem !default;
$pagination-padding-x-sm: 0.5rem !default;
$pagination-padding-y-lg: 0.75rem !default;
$pagination-padding-x-lg: 1.5rem !default;
$pagination-line-height: 1.25 !default;

$pagination-color: $secondary !important;
$pagination-bg: $white !default;
$pagination-border-color: $gray-200 !default;
$pagination-hover-bg: $white !important;
$pagination-hover-color: $primary !important;
$pagination-active-color: $primary !important;
$pagination-active-bg: $white !default;

$modal-xl: 1140px !default;
$modal-lg: 800px !default;
$modal-md: 795px !default;
$modal-sm: 300px !default;

//
// Application Variables
//-----------------------------------------------------

// ------- Header --------
$header-height: 70px;
$footer-height: 70px;

// ------- Sidebar --------
$sidebar-width: 250px;
$sidebar-mini-width: 60px;
$sidebar-color: #ffffff; //
// Customize bootstrap variables to match
// look and feel of the theme
//-----------------------------------------------------
// stylelint-disable
$white: #fff !default;
$gray-100: #f9fbfd !default;
$gray-200: #ececec !default;
$gray-300: #e3ebf6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #95aac9 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #12263f !default;
$black: #000 !default;

/*Theme color*/

$primary: #36b7b7;
$secondary: #878787;
$success: #00d97e;
$info: #39afd1;
$warning: #f6c343;
$danger: #e63757;
$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
);
$body-bg: $gray-100;
$body-color: $gray-900;
$base-shadow-color: #005595;
$nav-base-color: #1e2224;
$light-color: #b4b4b4;

// Customize typographic styles
$font-size-base: 1rem;
$font-family-sans-serif: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
$link-color: $primary;
$headings-line-height: 21px !default;
$small-font-size: 90%;

// Customize button styles
$btn-border-radius: 7px;
$font-weight-medium: 500 !default;
$btn-font-weight: 400 !default;
$btn-transition: box-shadow 0.3s cubic-bezier(0.35, 0, 0.25, 1), transform 0.2s cubic-bezier(0.35, 0, 0.25, 1), background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
$line-height-lg: 1.75 !default;
$input-btn-padding-y: 0.65rem !default;
$input-btn-padding-x: 0.75rem !default;
$input-btn-padding-y-lg: 0.4rem !default;
$input-btn-padding-x-lg: 1.6rem !default;
$input-btn-padding-y-sm: 0.5rem !default;
$input-btn-padding-x-sm: 0.75rem !default;
// Custmize form component styles
$input-border-radius: 0;
$input-border-radius-lg: 5px;
$input-border-radius-sm: 5px;
$input-focus-border-color: $primary;
$input-btn-border-width: 1px;
$input-btn-focus-box-shadow: none;
$input-btn-focus-width: 0;
$input-disabled-bg: rgba(#dfe6ec, 0.27) !default;
// Dropdowns
// Dropdown menu container and contents.
$dropdown-padding-y: 0.75rem;
$dropdown-item-padding-y: 0.75rem;
$dropdown-item-padding-x: 1.5rem;
$dropdown-header-color: $black;
$dropdown-min-width: 10rem !default;
$dropdown-padding-y: 0.5rem !default;
$dropdown-spacer: 0.125rem !default;
$dropdown-bg: $white !default;
$dropdown-border-color: rgba($black, 0.15) !default;

$dropdown-divider-bg: $gray-300 !default;
$dropdown-box-shadow: 0 0.5rem 1rem rgba($black, 0.175) !default;
$dropdown-link-color: $body-color !default;
$dropdown-link-hover-color: darken($body-color, 5%) !default;
$dropdown-link-hover-bg: $gray-100 !default;
$dropdown-link-active-color: darken($body-color, 5%) !default;
$dropdown-link-active-bg: darken($gray-200, 5%) !default;
// Forms
$input-color: $primary;
// Links
//
// Style anchor elements.
$link-hover-decoration: underline !default;
// List
$list-group-border-color: rgba($primary, 0.4);

// card
$card-border-radius: 1rem;
$card-color: $body-color;
$card-spacer-y: 1.5rem !default;
$card-spacer-x: 1.5rem !default;

// progress bar
$progress-height: 0.4rem !default;
$progress-border-radius: 5px;
$progress-height-lg: 0.8rem !default;
$progress-height-xl: 1.2rem !default;

// badge
$badge-padding-y: 0.5em !default;
$badge-padding-x: 0.8em !default;
// Tables
$table-head-color: $black !default;
$table-accent-bg: rgba($primary, 0.05);
$table-border-color: $gray-200;
$table-border-width: 2px;
// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.
$table-cell-padding: 0.75rem !default;
$table-cell-padding-sm: 0.45rem !default;

//shadow
$box-shadow-sm: 0 0.125rem 0.25rem rgba($gray-900, 0.02) !default;
$box-shadow: 0 0.5rem 1rem rgba($gray-900, 0.03) !default;
$box-shadow-lg: 0 1rem 3rem rgba($gray-900, 0.06) !default;

// Pagination

$pagination-padding-y: 0.875rem !default;
$pagination-padding-x: 1.25rem !default;
$pagination-padding-y-sm: 0.25rem !default;
$pagination-padding-x-sm: 0.5rem !default;
$pagination-padding-y-lg: 0.75rem !default;
$pagination-padding-x-lg: 1.5rem !default;
$pagination-line-height: 1.25 !default;

$pagination-color: $secondary !important;
$pagination-bg: $white !default;
$pagination-border-color: $gray-200 !default;
$pagination-hover-bg: $white !important;
$pagination-hover-color: $primary !important;
$pagination-active-color: $primary !important;
$pagination-active-bg: $white !default;

$modal-xl: 1140px !default;
$modal-lg: 800px !default;
$modal-md: 795px !default;
$modal-sm: 300px !default;

//
// Application Variables
//-----------------------------------------------------

// ------- Header --------
$header-height: 70px;
$footer-height: 70px;

// ------- Sidebar --------
$sidebar-width: 250px;
$sidebar-mini-width: 60px;
$sidebar-color: #ffffff; //
// Customize bootstrap variables to match
// look and feel of the theme
//-----------------------------------------------------
// stylelint-disable
$white: #fff !default;
$gray-100: #f9fbfd !default;
$gray-200: #ececec !default;
$gray-300: #e3ebf6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #95aac9 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #12263f !default;
$black: #000 !default;

/*Theme color*/

$primary: #36b7b7;
$secondary: #878787;
$success: #00d97e;
$info: #39afd1;
$warning: #f6c343;
$danger: #e63757;
$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
);
$body-bg: $gray-100;
$body-color: $gray-900;
$base-shadow-color: #005595;
$nav-base-color: #1e2224;
$light-color: #b4b4b4;

// Customize typographic styles
$font-size-base: 1rem;
$font-family-sans-serif: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
$link-color: $primary;
$headings-line-height: 21px !default;
$small-font-size: 90%;

// Customize button styles
$btn-border-radius: 7px;
$font-weight-medium: 500 !default;
$btn-font-weight: 400 !default;
$btn-transition: box-shadow 0.3s cubic-bezier(0.35, 0, 0.25, 1), transform 0.2s cubic-bezier(0.35, 0, 0.25, 1), background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
$line-height-lg: 1.75 !default;
$input-btn-padding-y: 0.65rem !default;
$input-btn-padding-x: 0.75rem !default;
$input-btn-padding-y-lg: 0.4rem !default;
$input-btn-padding-x-lg: 1.6rem !default;
$input-btn-padding-y-sm: 0.5rem !default;
$input-btn-padding-x-sm: 0.75rem !default;
// Custmize form component styles
$input-border-radius: 0;
$input-border-radius-lg: 5px;
$input-border-radius-sm: 5px;
$input-focus-border-color: $primary;
$input-btn-border-width: 1px;
$input-btn-focus-box-shadow: none;
$input-btn-focus-width: 0;
$input-disabled-bg: rgba(#dfe6ec, 0.27) !default;
// Dropdowns
// Dropdown menu container and contents.
$dropdown-padding-y: 0.75rem;
$dropdown-item-padding-y: 0.75rem;
$dropdown-item-padding-x: 1.5rem;
$dropdown-header-color: $black;
$dropdown-min-width: 10rem !default;
$dropdown-padding-y: 0.5rem !default;
$dropdown-spacer: 0.125rem !default;
$dropdown-bg: $white !default;
$dropdown-border-color: rgba($black, 0.15) !default;

$dropdown-divider-bg: $gray-300 !default;
$dropdown-box-shadow: 0 0.5rem 1rem rgba($black, 0.175) !default;
$dropdown-link-color: $body-color !default;
$dropdown-link-hover-color: darken($body-color, 5%) !default;
$dropdown-link-hover-bg: $gray-100 !default;
$dropdown-link-active-color: darken($body-color, 5%) !default;
$dropdown-link-active-bg: darken($gray-200, 5%) !default;
// Forms
$input-color: $primary;
// Links
//
// Style anchor elements.
$link-hover-decoration: underline !default;
// List
$list-group-border-color: rgba($primary, 0.4);

// card
$card-border-radius: 1rem;
$card-color: $body-color;
$card-spacer-y: 1.5rem !default;
$card-spacer-x: 1.5rem !default;

// progress bar
$progress-height: 0.4rem !default;
$progress-border-radius: 5px;
$progress-height-lg: 0.8rem !default;
$progress-height-xl: 1.2rem !default;

// badge
$badge-padding-y: 0.5em !default;
$badge-padding-x: 0.8em !default;
// Tables
$table-head-color: $black !default;
$table-accent-bg: rgba($primary, 0.05);
$table-border-color: $gray-200;
$table-border-width: 2px;
// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.
$table-cell-padding: 0.75rem !default;
$table-cell-padding-sm: 0.45rem !default;

//shadow
$box-shadow-sm: 0 0.125rem 0.25rem rgba($gray-900, 0.02) !default;
$box-shadow: 0 0.5rem 1rem rgba($gray-900, 0.03) !default;
$box-shadow-lg: 0 1rem 3rem rgba($gray-900, 0.06) !default;

// Pagination

$pagination-padding-y: 0.875rem !default;
$pagination-padding-x: 1.25rem !default;
$pagination-padding-y-sm: 0.25rem !default;
$pagination-padding-x-sm: 0.5rem !default;
$pagination-padding-y-lg: 0.75rem !default;
$pagination-padding-x-lg: 1.5rem !default;
$pagination-line-height: 1.25 !default;

$pagination-color: $secondary !important;
$pagination-bg: $white !default;
$pagination-border-color: $gray-200 !default;
$pagination-hover-bg: $white !important;
$pagination-hover-color: $primary !important;
$pagination-active-color: $primary !important;
$pagination-active-bg: $white !default;

$modal-xl: 1140px !default;
$modal-lg: 800px !default;
$modal-md: 795px !default;
$modal-sm: 300px !default;

//
// Application Variables
//-----------------------------------------------------

// ------- Header --------
$header-height: 70px;
$footer-height: 70px;

// ------- Sidebar --------
$sidebar-width: 250px;
$sidebar-mini-width: 60px;
$sidebar-color: #ffffff;
$white: #fff !default;
$gray-100: #f9fbfd !default;
$gray-200: #ececec !default;
$gray-300: #e3ebf6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #95aac9 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #12263f !default;
$black: #000 !default; //
// Customize bootstrap variables to match
// look and feel of the theme
//-----------------------------------------------------
// stylelint-disable
$white: #fff !default;
$gray-100: #f9fbfd !default;
$gray-200: #ececec !default;
$gray-300: #e3ebf6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #95aac9 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #12263f !default;
$black: #000 !default;

/*Theme color*/

$primary: #36b7b7;
$secondary: #878787;
$success: #00d97e;
$info: #39afd1;
$warning: #f6c343;
$danger: #e63757;
$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
);
$body-bg: $gray-100;
$body-color: $gray-900;
$base-shadow-color: #005595;
$nav-base-color: #1e2224;
$light-color: #b4b4b4;

// Customize typographic styles
$font-size-base: 1rem;
$font-family-sans-serif: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
$link-color: $primary;
$headings-line-height: 21px !default;
$small-font-size: 90%;

// Customize button styles
$btn-border-radius: 7px;
$font-weight-medium: 500 !default;
$btn-font-weight: 400 !default;
$btn-transition: box-shadow 0.3s cubic-bezier(0.35, 0, 0.25, 1), transform 0.2s cubic-bezier(0.35, 0, 0.25, 1), background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
$line-height-lg: 1.75 !default;
$input-btn-padding-y: 0.65rem !default;
$input-btn-padding-x: 0.75rem !default;
$input-btn-padding-y-lg: 0.4rem !default;
$input-btn-padding-x-lg: 1.6rem !default;
$input-btn-padding-y-sm: 0.5rem !default;
$input-btn-padding-x-sm: 0.75rem !default;
// Custmize form component styles
$input-border-radius: 0;
$input-border-radius-lg: 5px;
$input-border-radius-sm: 5px;
$input-focus-border-color: $primary;
$input-btn-border-width: 1px;
$input-btn-focus-box-shadow: none;
$input-btn-focus-width: 0;
$input-disabled-bg: rgba(#dfe6ec, 0.27) !default;
// Dropdowns
// Dropdown menu container and contents.
$dropdown-padding-y: 0.75rem;
$dropdown-item-padding-y: 0.75rem;
$dropdown-item-padding-x: 1.5rem;
$dropdown-header-color: $black;
$dropdown-min-width: 10rem !default;
$dropdown-padding-y: 0.5rem !default;
$dropdown-spacer: 0.125rem !default;
$dropdown-bg: $white !default;
$dropdown-border-color: rgba($black, 0.15) !default;

$dropdown-divider-bg: $gray-300 !default;
$dropdown-box-shadow: 0 0.5rem 1rem rgba($black, 0.175) !default;
$dropdown-link-color: $body-color !default;
$dropdown-link-hover-color: darken($body-color, 5%) !default;
$dropdown-link-hover-bg: $gray-100 !default;
$dropdown-link-active-color: darken($body-color, 5%) !default;
$dropdown-link-active-bg: darken($gray-200, 5%) !default;
// Forms
$input-color: $primary;
// Links
//
// Style anchor elements.
$link-hover-decoration: underline !default;
// List
$list-group-border-color: rgba($primary, 0.4);

// card
$card-border-radius: 1rem;
$card-color: $body-color;
$card-spacer-y: 1.5rem !default;
$card-spacer-x: 1.5rem !default;

// progress bar
$progress-height: 0.4rem !default;
$progress-border-radius: 5px;
$progress-height-lg: 0.8rem !default;
$progress-height-xl: 1.2rem !default;

// badge
$badge-padding-y: 0.5em !default;
$badge-padding-x: 0.8em !default;
// Tables
$table-head-color: $black !default;
$table-accent-bg: rgba($primary, 0.05);
$table-border-color: $gray-200;
$table-border-width: 2px;
// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.
$table-cell-padding: 0.75rem !default;
$table-cell-padding-sm: 0.45rem !default;

//shadow
$box-shadow-sm: 0 0.125rem 0.25rem rgba($gray-900, 0.02) !default;
$box-shadow: 0 0.5rem 1rem rgba($gray-900, 0.03) !default;
$box-shadow-lg: 0 1rem 3rem rgba($gray-900, 0.06) !default;

// Pagination

$pagination-padding-y: 0.875rem !default;
$pagination-padding-x: 1.25rem !default;
$pagination-padding-y-sm: 0.25rem !default;
$pagination-padding-x-sm: 0.5rem !default;
$pagination-padding-y-lg: 0.75rem !default;
$pagination-padding-x-lg: 1.5rem !default;
$pagination-line-height: 1.25 !default;

$pagination-color: $secondary !important;
$pagination-bg: $white !default;
$pagination-border-color: $gray-200 !default;
$pagination-hover-bg: $white !important;
$pagination-hover-color: $primary !important;
$pagination-active-color: $primary !important;
$pagination-active-bg: $white !default;

$modal-xl: 1140px !default;
$modal-lg: 800px !default;
$modal-md: 795px !default;
$modal-sm: 300px !default;

//
// Application Variables
//-----------------------------------------------------

// ------- Header --------
$header-height: 70px;
$footer-height: 70px;

// ------- Sidebar --------
$sidebar-width: 250px;
$sidebar-mini-width: 60px;
$sidebar-color: #ffffff;

$primary: #36b7b7;
$secondary: #878787;
$success: #00d97e;
$info: #39afd1;
$warning: #f6c343;
$danger: #e63757;
$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
);
$body-bg: $gray-100;
$body-color: $gray-900;
$base-shadow-color: #005595;
$nav-base-color: #1e2224;
$light-color: #b4b4b4; //
// Customize bootstrap variables to match
// look and feel of the theme
//-----------------------------------------------------
// stylelint-disable
$white: #fff !default;
$gray-100: #f9fbfd !default;
$gray-200: #ececec !default;
$gray-300: #e3ebf6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #95aac9 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #12263f !default;
$black: #000 !default;

/*Theme color*/

$primary: #36b7b7;
$secondary: #878787;
$success: #00d97e;
$info: #39afd1;
$warning: #f6c343;
$danger: #e63757;
$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
);
$body-bg: $gray-100;
$body-color: $gray-900;
$base-shadow-color: #005595;
$nav-base-color: #1e2224;
$light-color: #b4b4b4;

// Customize typographic styles
$font-size-base: 1rem;
$font-family-sans-serif: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
$link-color: $primary;
$headings-line-height: 21px !default;
$small-font-size: 90%;

// Customize button styles
$btn-border-radius: 7px;
$font-weight-medium: 500 !default;
$btn-font-weight: 400 !default;
$btn-transition: box-shadow 0.3s cubic-bezier(0.35, 0, 0.25, 1), transform 0.2s cubic-bezier(0.35, 0, 0.25, 1), background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
$line-height-lg: 1.75 !default;
$input-btn-padding-y: 0.65rem !default;
$input-btn-padding-x: 0.75rem !default;
$input-btn-padding-y-lg: 0.4rem !default;
$input-btn-padding-x-lg: 1.6rem !default;
$input-btn-padding-y-sm: 0.5rem !default;
$input-btn-padding-x-sm: 0.75rem !default;
// Custmize form component styles
$input-border-radius: 0;
$input-border-radius-lg: 5px;
$input-border-radius-sm: 5px;
$input-focus-border-color: $primary;
$input-btn-border-width: 1px;
$input-btn-focus-box-shadow: none;
$input-btn-focus-width: 0;
$input-disabled-bg: rgba(#dfe6ec, 0.27) !default;
// Dropdowns
// Dropdown menu container and contents.
$dropdown-padding-y: 0.75rem;
$dropdown-item-padding-y: 0.75rem;
$dropdown-item-padding-x: 1.5rem;
$dropdown-header-color: $black;
$dropdown-min-width: 10rem !default;
$dropdown-padding-y: 0.5rem !default;
$dropdown-spacer: 0.125rem !default;
$dropdown-bg: $white !default;
$dropdown-border-color: rgba($black, 0.15) !default;

$dropdown-divider-bg: $gray-300 !default;
$dropdown-box-shadow: 0 0.5rem 1rem rgba($black, 0.175) !default;
$dropdown-link-color: $body-color !default;
$dropdown-link-hover-color: darken($body-color, 5%) !default;
$dropdown-link-hover-bg: $gray-100 !default;
$dropdown-link-active-color: darken($body-color, 5%) !default;
$dropdown-link-active-bg: darken($gray-200, 5%) !default;
// Forms
$input-color: $primary;
// Links
//
// Style anchor elements.
$link-hover-decoration: underline !default;
// List
$list-group-border-color: rgba($primary, 0.4);

// card
$card-border-radius: 1rem;
$card-color: $body-color;
$card-spacer-y: 1.5rem !default;
$card-spacer-x: 1.5rem !default;

// progress bar
$progress-height: 0.4rem !default;
$progress-border-radius: 5px;
$progress-height-lg: 0.8rem !default;
$progress-height-xl: 1.2rem !default;

// badge
$badge-padding-y: 0.5em !default;
$badge-padding-x: 0.8em !default;
// Tables
$table-head-color: $black !default;
$table-accent-bg: rgba($primary, 0.05);
$table-border-color: $gray-200;
$table-border-width: 2px;
// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.
$table-cell-padding: 0.75rem !default;
$table-cell-padding-sm: 0.45rem !default;

//shadow
$box-shadow-sm: 0 0.125rem 0.25rem rgba($gray-900, 0.02) !default;
$box-shadow: 0 0.5rem 1rem rgba($gray-900, 0.03) !default;
$box-shadow-lg: 0 1rem 3rem rgba($gray-900, 0.06) !default;

// Pagination

$pagination-padding-y: 0.875rem !default;
$pagination-padding-x: 1.25rem !default;
$pagination-padding-y-sm: 0.25rem !default;
$pagination-padding-x-sm: 0.5rem !default;
$pagination-padding-y-lg: 0.75rem !default;
$pagination-padding-x-lg: 1.5rem !default;
$pagination-line-height: 1.25 !default;

$pagination-color: $secondary !important;
$pagination-bg: $white !default;
$pagination-border-color: $gray-200 !default;
$pagination-hover-bg: $white !important;
$pagination-hover-color: $primary !important;
$pagination-active-color: $primary !important;
$pagination-active-bg: $white !default;

$modal-xl: 1140px !default;
$modal-lg: 800px !default;
$modal-md: 795px !default;
$modal-sm: 300px !default;

//
// Application Variables
//-----------------------------------------------------

// ------- Header --------
$header-height: 70px;
$footer-height: 70px;

// ------- Sidebar --------
$sidebar-width: 250px;
$sidebar-mini-width: 60px;
$sidebar-color: #ffffff;
$font-size-base: 1rem;
$font-family-sans-serif: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
$link-color: $primary;
$headings-line-height: 21px !default;
$small-font-size: 90%; //
// Customize bootstrap variables to match
// look and feel of the theme
//-----------------------------------------------------
// stylelint-disable
$white: #fff !default;
$gray-100: #f9fbfd !default;
$gray-200: #ececec !default;
$gray-300: #e3ebf6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #95aac9 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #12263f !default;
$black: #000 !default;

/*Theme color*/

$primary: #36b7b7;
$secondary: #878787;
$success: #00d97e;
$info: #39afd1;
$warning: #f6c343;
$danger: #e63757;
$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
);
$body-bg: $gray-100;
$body-color: $gray-900;
$base-shadow-color: #005595;
$nav-base-color: #1e2224;
$light-color: #b4b4b4;

// Customize typographic styles
$font-size-base: 1rem;
$font-family-sans-serif: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
$link-color: $primary;
$headings-line-height: 21px !default;
$small-font-size: 90%;

// Customize button styles
$btn-border-radius: 7px;
$font-weight-medium: 500 !default;
$btn-font-weight: 400 !default;
$btn-transition: box-shadow 0.3s cubic-bezier(0.35, 0, 0.25, 1), transform 0.2s cubic-bezier(0.35, 0, 0.25, 1), background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
$line-height-lg: 1.75 !default;
$input-btn-padding-y: 0.65rem !default;
$input-btn-padding-x: 0.75rem !default;
$input-btn-padding-y-lg: 0.4rem !default;
$input-btn-padding-x-lg: 1.6rem !default;
$input-btn-padding-y-sm: 0.5rem !default;
$input-btn-padding-x-sm: 0.75rem !default;
// Custmize form component styles
$input-border-radius: 0;
$input-border-radius-lg: 5px;
$input-border-radius-sm: 5px;
$input-focus-border-color: $primary;
$input-btn-border-width: 1px;
$input-btn-focus-box-shadow: none;
$input-btn-focus-width: 0;
$input-disabled-bg: rgba(#dfe6ec, 0.27) !default;
// Dropdowns
// Dropdown menu container and contents.
$dropdown-padding-y: 0.75rem;
$dropdown-item-padding-y: 0.75rem;
$dropdown-item-padding-x: 1.5rem;
$dropdown-header-color: $black;
$dropdown-min-width: 10rem !default;
$dropdown-padding-y: 0.5rem !default;
$dropdown-spacer: 0.125rem !default;
$dropdown-bg: $white !default;
$dropdown-border-color: rgba($black, 0.15) !default;

$dropdown-divider-bg: $gray-300 !default;
$dropdown-box-shadow: 0 0.5rem 1rem rgba($black, 0.175) !default;
$dropdown-link-color: $body-color !default;
$dropdown-link-hover-color: darken($body-color, 5%) !default;
$dropdown-link-hover-bg: $gray-100 !default;
$dropdown-link-active-color: darken($body-color, 5%) !default;
$dropdown-link-active-bg: darken($gray-200, 5%) !default;
// Forms
$input-color: $primary;
// Links
//
// Style anchor elements.
$link-hover-decoration: underline !default;
// List
$list-group-border-color: rgba($primary, 0.4);

// card
$card-border-radius: 1rem;
$card-color: $body-color;
$card-spacer-y: 1.5rem !default;
$card-spacer-x: 1.5rem !default;

// progress bar
$progress-height: 0.4rem !default;
$progress-border-radius: 5px;
$progress-height-lg: 0.8rem !default;
$progress-height-xl: 1.2rem !default;

// badge
$badge-padding-y: 0.5em !default;
$badge-padding-x: 0.8em !default;
// Tables
$table-head-color: $black !default;
$table-accent-bg: rgba($primary, 0.05);
$table-border-color: $gray-200;
$table-border-width: 2px;
// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.
$table-cell-padding: 0.75rem !default;
$table-cell-padding-sm: 0.45rem !default;

//shadow
$box-shadow-sm: 0 0.125rem 0.25rem rgba($gray-900, 0.02) !default;
$box-shadow: 0 0.5rem 1rem rgba($gray-900, 0.03) !default;
$box-shadow-lg: 0 1rem 3rem rgba($gray-900, 0.06) !default;

// Pagination

$pagination-padding-y: 0.875rem !default;
$pagination-padding-x: 1.25rem !default;
$pagination-padding-y-sm: 0.25rem !default;
$pagination-padding-x-sm: 0.5rem !default;
$pagination-padding-y-lg: 0.75rem !default;
$pagination-padding-x-lg: 1.5rem !default;
$pagination-line-height: 1.25 !default;

$pagination-color: $secondary !important;
$pagination-bg: $white !default;
$pagination-border-color: $gray-200 !default;
$pagination-hover-bg: $white !important;
$pagination-hover-color: $primary !important;
$pagination-active-color: $primary !important;
$pagination-active-bg: $white !default;

$modal-xl: 1140px !default;
$modal-lg: 800px !default;
$modal-md: 795px !default;
$modal-sm: 300px !default;

//
// Application Variables
//-----------------------------------------------------

// ------- Header --------
$header-height: 70px;
$footer-height: 70px;

// ------- Sidebar --------
$sidebar-width: 250px;
$sidebar-mini-width: 60px;
$sidebar-color: #ffffff;
$btn-border-radius: 7px;
$font-weight-medium: 500 !default;
$btn-font-weight: 400 !default;
$btn-transition: box-shadow 0.3s cubic-bezier(0.35, 0, 0.25, 1), transform 0.2s cubic-bezier(0.35, 0, 0.25, 1), background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
$line-height-lg: 1.75 !default;
$input-btn-padding-y: 0.65rem !default;
$input-btn-padding-x: 0.75rem !default;
$input-btn-padding-y-lg: 0.4rem !default;
$input-btn-padding-x-lg: 1.6rem !default;
$input-btn-padding-y-sm: 0.5rem !default;
$input-btn-padding-x-sm: 0.75rem !default; //
// Customize bootstrap variables to match
// look and feel of the theme
//-----------------------------------------------------
// stylelint-disable
$white: #fff !default;
$gray-100: #f9fbfd !default;
$gray-200: #ececec !default;
$gray-300: #e3ebf6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #95aac9 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #12263f !default;
$black: #000 !default;

/*Theme color*/

$primary: #36b7b7;
$secondary: #878787;
$success: #00d97e;
$info: #39afd1;
$warning: #f6c343;
$danger: #e63757;
$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
);
$body-bg: $gray-100;
$body-color: $gray-900;
$base-shadow-color: #005595;
$nav-base-color: #1e2224;
$light-color: #b4b4b4;

// Customize typographic styles
$font-size-base: 1rem;
$font-family-sans-serif: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
$link-color: $primary;
$headings-line-height: 21px !default;
$small-font-size: 90%;

// Customize button styles
$btn-border-radius: 7px;
$font-weight-medium: 500 !default;
$btn-font-weight: 400 !default;
$btn-transition: box-shadow 0.3s cubic-bezier(0.35, 0, 0.25, 1), transform 0.2s cubic-bezier(0.35, 0, 0.25, 1), background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
$line-height-lg: 1.75 !default;
$input-btn-padding-y: 0.65rem !default;
$input-btn-padding-x: 0.75rem !default;
$input-btn-padding-y-lg: 0.4rem !default;
$input-btn-padding-x-lg: 1.6rem !default;
$input-btn-padding-y-sm: 0.5rem !default;
$input-btn-padding-x-sm: 0.75rem !default;
// Custmize form component styles
$input-border-radius: 0;
$input-border-radius-lg: 5px;
$input-border-radius-sm: 5px;
$input-focus-border-color: $primary;
$input-btn-border-width: 1px;
$input-btn-focus-box-shadow: none;
$input-btn-focus-width: 0;
$input-disabled-bg: rgba(#dfe6ec, 0.27) !default;
// Dropdowns
// Dropdown menu container and contents.
$dropdown-padding-y: 0.75rem;
$dropdown-item-padding-y: 0.75rem;
$dropdown-item-padding-x: 1.5rem;
$dropdown-header-color: $black;
$dropdown-min-width: 10rem !default;
$dropdown-padding-y: 0.5rem !default;
$dropdown-spacer: 0.125rem !default;
$dropdown-bg: $white !default;
$dropdown-border-color: rgba($black, 0.15) !default;

$dropdown-divider-bg: $gray-300 !default;
$dropdown-box-shadow: 0 0.5rem 1rem rgba($black, 0.175) !default;
$dropdown-link-color: $body-color !default;
$dropdown-link-hover-color: darken($body-color, 5%) !default;
$dropdown-link-hover-bg: $gray-100 !default;
$dropdown-link-active-color: darken($body-color, 5%) !default;
$dropdown-link-active-bg: darken($gray-200, 5%) !default;
// Forms
$input-color: $primary;
// Links
//
// Style anchor elements.
$link-hover-decoration: underline !default;
// List
$list-group-border-color: rgba($primary, 0.4);

// card
$card-border-radius: 1rem;
$card-color: $body-color;
$card-spacer-y: 1.5rem !default;
$card-spacer-x: 1.5rem !default;

// progress bar
$progress-height: 0.4rem !default;
$progress-border-radius: 5px;
$progress-height-lg: 0.8rem !default;
$progress-height-xl: 1.2rem !default;

// badge
$badge-padding-y: 0.5em !default;
$badge-padding-x: 0.8em !default;
// Tables
$table-head-color: $black !default;
$table-accent-bg: rgba($primary, 0.05);
$table-border-color: $gray-200;
$table-border-width: 2px;
// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.
$table-cell-padding: 0.75rem !default;
$table-cell-padding-sm: 0.45rem !default;

//shadow
$box-shadow-sm: 0 0.125rem 0.25rem rgba($gray-900, 0.02) !default;
$box-shadow: 0 0.5rem 1rem rgba($gray-900, 0.03) !default;
$box-shadow-lg: 0 1rem 3rem rgba($gray-900, 0.06) !default;

// Pagination

$pagination-padding-y: 0.875rem !default;
$pagination-padding-x: 1.25rem !default;
$pagination-padding-y-sm: 0.25rem !default;
$pagination-padding-x-sm: 0.5rem !default;
$pagination-padding-y-lg: 0.75rem !default;
$pagination-padding-x-lg: 1.5rem !default;
$pagination-line-height: 1.25 !default;

$pagination-color: $secondary !important;
$pagination-bg: $white !default;
$pagination-border-color: $gray-200 !default;
$pagination-hover-bg: $white !important;
$pagination-hover-color: $primary !important;
$pagination-active-color: $primary !important;
$pagination-active-bg: $white !default;

$modal-xl: 1140px !default;
$modal-lg: 800px !default;
$modal-md: 795px !default;
$modal-sm: 300px !default;

//
// Application Variables
//-----------------------------------------------------

// ------- Header --------
$header-height: 70px;
$footer-height: 70px;

// ------- Sidebar --------
$sidebar-width: 250px;
$sidebar-mini-width: 60px;
$sidebar-color: #ffffff;
$input-border-radius: 0;
$input-border-radius-lg: 5px;
$input-border-radius-sm: 5px;
$input-focus-border-color: $primary;
$input-btn-border-width: 1px;
$input-btn-focus-box-shadow: none;
$input-btn-focus-width: 0;
$input-disabled-bg: rgba(#dfe6ec, 0.27) !default; //
// Customize bootstrap variables to match
// look and feel of the theme
//-----------------------------------------------------
// stylelint-disable
$white: #fff !default;
$gray-100: #f9fbfd !default;
$gray-200: #ececec !default;
$gray-300: #e3ebf6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #95aac9 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #12263f !default;
$black: #000 !default;

/*Theme color*/

$primary: #36b7b7;
$secondary: #878787;
$success: #00d97e;
$info: #39afd1;
$warning: #f6c343;
$danger: #e63757;
$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
);
$body-bg: $gray-100;
$body-color: $gray-900;
$base-shadow-color: #005595;
$nav-base-color: #1e2224;
$light-color: #b4b4b4;

// Customize typographic styles
$font-size-base: 1rem;
$font-family-sans-serif: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
$link-color: $primary;
$headings-line-height: 21px !default;
$small-font-size: 90%;

// Customize button styles
$btn-border-radius: 7px;
$font-weight-medium: 500 !default;
$btn-font-weight: 400 !default;
$btn-transition: box-shadow 0.3s cubic-bezier(0.35, 0, 0.25, 1), transform 0.2s cubic-bezier(0.35, 0, 0.25, 1), background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
$line-height-lg: 1.75 !default;
$input-btn-padding-y: 0.65rem !default;
$input-btn-padding-x: 0.75rem !default;
$input-btn-padding-y-lg: 0.4rem !default;
$input-btn-padding-x-lg: 1.6rem !default;
$input-btn-padding-y-sm: 0.5rem !default;
$input-btn-padding-x-sm: 0.75rem !default;
// Custmize form component styles
$input-border-radius: 0;
$input-border-radius-lg: 5px;
$input-border-radius-sm: 5px;
$input-focus-border-color: $primary;
$input-btn-border-width: 1px;
$input-btn-focus-box-shadow: none;
$input-btn-focus-width: 0;
$input-disabled-bg: rgba(#dfe6ec, 0.27) !default;
// Dropdowns
// Dropdown menu container and contents.
$dropdown-padding-y: 0.75rem;
$dropdown-item-padding-y: 0.75rem;
$dropdown-item-padding-x: 1.5rem;
$dropdown-header-color: $black;
$dropdown-min-width: 10rem !default;
$dropdown-padding-y: 0.5rem !default;
$dropdown-spacer: 0.125rem !default;
$dropdown-bg: $white !default;
$dropdown-border-color: rgba($black, 0.15) !default;

$dropdown-divider-bg: $gray-300 !default;
$dropdown-box-shadow: 0 0.5rem 1rem rgba($black, 0.175) !default;
$dropdown-link-color: $body-color !default;
$dropdown-link-hover-color: darken($body-color, 5%) !default;
$dropdown-link-hover-bg: $gray-100 !default;
$dropdown-link-active-color: darken($body-color, 5%) !default;
$dropdown-link-active-bg: darken($gray-200, 5%) !default;
// Forms
$input-color: $primary;
// Links
//
// Style anchor elements.
$link-hover-decoration: underline !default;
// List
$list-group-border-color: rgba($primary, 0.4);

// card
$card-border-radius: 1rem;
$card-color: $body-color;
$card-spacer-y: 1.5rem !default;
$card-spacer-x: 1.5rem !default;

// progress bar
$progress-height: 0.4rem !default;
$progress-border-radius: 5px;
$progress-height-lg: 0.8rem !default;
$progress-height-xl: 1.2rem !default;

// badge
$badge-padding-y: 0.5em !default;
$badge-padding-x: 0.8em !default;
// Tables
$table-head-color: $black !default;
$table-accent-bg: rgba($primary, 0.05);
$table-border-color: $gray-200;
$table-border-width: 2px;
// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.
$table-cell-padding: 0.75rem !default;
$table-cell-padding-sm: 0.45rem !default;

//shadow
$box-shadow-sm: 0 0.125rem 0.25rem rgba($gray-900, 0.02) !default;
$box-shadow: 0 0.5rem 1rem rgba($gray-900, 0.03) !default;
$box-shadow-lg: 0 1rem 3rem rgba($gray-900, 0.06) !default;

// Pagination

$pagination-padding-y: 0.875rem !default;
$pagination-padding-x: 1.25rem !default;
$pagination-padding-y-sm: 0.25rem !default;
$pagination-padding-x-sm: 0.5rem !default;
$pagination-padding-y-lg: 0.75rem !default;
$pagination-padding-x-lg: 1.5rem !default;
$pagination-line-height: 1.25 !default;

$pagination-color: $secondary !important;
$pagination-bg: $white !default;
$pagination-border-color: $gray-200 !default;
$pagination-hover-bg: $white !important;
$pagination-hover-color: $primary !important;
$pagination-active-color: $primary !important;
$pagination-active-bg: $white !default;

$modal-xl: 1140px !default;
$modal-lg: 800px !default;
$modal-md: 795px !default;
$modal-sm: 300px !default;

//
// Application Variables
//-----------------------------------------------------

// ------- Header --------
$header-height: 70px;
$footer-height: 70px;

// ------- Sidebar --------
$sidebar-width: 250px;
$sidebar-mini-width: 60px;
$sidebar-color: #ffffff; //
// Customize bootstrap variables to match
// look and feel of the theme
//-----------------------------------------------------
// stylelint-disable
$white: #fff !default;
$gray-100: #f9fbfd !default;
$gray-200: #ececec !default;
$gray-300: #e3ebf6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #95aac9 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #12263f !default;
$black: #000 !default;

/*Theme color*/

$primary: #36b7b7;
$secondary: #878787;
$success: #00d97e;
$info: #39afd1;
$warning: #f6c343;
$danger: #e63757;
$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
);
$body-bg: $gray-100;
$body-color: $gray-900;
$base-shadow-color: #005595;
$nav-base-color: #1e2224;
$light-color: #b4b4b4;

// Customize typographic styles
$font-size-base: 1rem;
$font-family-sans-serif: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
$link-color: $primary;
$headings-line-height: 21px !default;
$small-font-size: 90%;

// Customize button styles
$btn-border-radius: 7px;
$font-weight-medium: 500 !default;
$btn-font-weight: 400 !default;
$btn-transition: box-shadow 0.3s cubic-bezier(0.35, 0, 0.25, 1), transform 0.2s cubic-bezier(0.35, 0, 0.25, 1), background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
$line-height-lg: 1.75 !default;
$input-btn-padding-y: 0.65rem !default;
$input-btn-padding-x: 0.75rem !default;
$input-btn-padding-y-lg: 0.4rem !default;
$input-btn-padding-x-lg: 1.6rem !default;
$input-btn-padding-y-sm: 0.5rem !default;
$input-btn-padding-x-sm: 0.75rem !default;
// Custmize form component styles
$input-border-radius: 0;
$input-border-radius-lg: 5px;
$input-border-radius-sm: 5px;
$input-focus-border-color: $primary;
$input-btn-border-width: 1px;
$input-btn-focus-box-shadow: none;
$input-btn-focus-width: 0;
$input-disabled-bg: rgba(#dfe6ec, 0.27) !default;
// Dropdowns
// Dropdown menu container and contents.
$dropdown-padding-y: 0.75rem;
$dropdown-item-padding-y: 0.75rem;
$dropdown-item-padding-x: 1.5rem;
$dropdown-header-color: $black;
$dropdown-min-width: 10rem !default;
$dropdown-padding-y: 0.5rem !default;
$dropdown-spacer: 0.125rem !default;
$dropdown-bg: $white !default;
$dropdown-border-color: rgba($black, 0.15) !default;

$dropdown-divider-bg: $gray-300 !default;
$dropdown-box-shadow: 0 0.5rem 1rem rgba($black, 0.175) !default;
$dropdown-link-color: $body-color !default;
$dropdown-link-hover-color: darken($body-color, 5%) !default;
$dropdown-link-hover-bg: $gray-100 !default;
$dropdown-link-active-color: darken($body-color, 5%) !default;
$dropdown-link-active-bg: darken($gray-200, 5%) !default;
// Forms
$input-color: $primary;
// Links
//
// Style anchor elements.
$link-hover-decoration: underline !default;
// List
$list-group-border-color: rgba($primary, 0.4);

// card
$card-border-radius: 1rem;
$card-color: $body-color;
$card-spacer-y: 1.5rem !default;
$card-spacer-x: 1.5rem !default;

// progress bar
$progress-height: 0.4rem !default;
$progress-border-radius: 5px;
$progress-height-lg: 0.8rem !default;
$progress-height-xl: 1.2rem !default;

// badge
$badge-padding-y: 0.5em !default;
$badge-padding-x: 0.8em !default;
// Tables
$table-head-color: $black !default;
$table-accent-bg: rgba($primary, 0.05);
$table-border-color: $gray-200;
$table-border-width: 2px;
// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.
$table-cell-padding: 0.75rem !default;
$table-cell-padding-sm: 0.45rem !default;

//shadow
$box-shadow-sm: 0 0.125rem 0.25rem rgba($gray-900, 0.02) !default;
$box-shadow: 0 0.5rem 1rem rgba($gray-900, 0.03) !default;
$box-shadow-lg: 0 1rem 3rem rgba($gray-900, 0.06) !default;

// Pagination

$pagination-padding-y: 0.875rem !default;
$pagination-padding-x: 1.25rem !default;
$pagination-padding-y-sm: 0.25rem !default;
$pagination-padding-x-sm: 0.5rem !default;
$pagination-padding-y-lg: 0.75rem !default;
$pagination-padding-x-lg: 1.5rem !default;
$pagination-line-height: 1.25 !default;

$pagination-color: $secondary !important;
$pagination-bg: $white !default;
$pagination-border-color: $gray-200 !default;
$pagination-hover-bg: $white !important;
$pagination-hover-color: $primary !important;
$pagination-active-color: $primary !important;
$pagination-active-bg: $white !default;

$modal-xl: 1140px !default;
$modal-lg: 800px !default;
$modal-md: 795px !default;
$modal-sm: 300px !default;

//
// Application Variables
//-----------------------------------------------------

// ------- Header --------
$header-height: 70px;
$footer-height: 70px;

// ------- Sidebar --------
$sidebar-width: 250px;
$sidebar-mini-width: 60px;
$sidebar-color: #ffffff;
$dropdown-padding-y: 0.75rem;
$dropdown-item-padding-y: 0.75rem;
$dropdown-item-padding-x: 1.5rem;
$dropdown-header-color: $black;
$dropdown-min-width: 10rem !default;
$dropdown-padding-y: 0.5rem !default;
$dropdown-spacer: 0.125rem !default;
$dropdown-bg: $white !default;
$dropdown-border-color: rgba($black, 0.15) !default;
$dropdown-divider-bg: $gray-300 !default;
$dropdown-box-shadow: 0 0.5rem 1rem rgba($black, 0.175) !default;
$dropdown-link-color: $body-color !default;
$dropdown-link-hover-color: darken($body-color, 5%) !default;
$dropdown-link-hover-bg: $gray-100 !default;
$dropdown-link-active-color: darken($body-color, 5%) !default;
$dropdown-link-active-bg: darken($gray-200, 5%) !default; //
// Customize bootstrap variables to match
// look and feel of the theme
//-----------------------------------------------------
// stylelint-disable
$white: #fff !default;
$gray-100: #f9fbfd !default;
$gray-200: #ececec !default;
$gray-300: #e3ebf6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #95aac9 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #12263f !default;
$black: #000 !default;

/*Theme color*/

$primary: #36b7b7;
$secondary: #878787;
$success: #00d97e;
$info: #39afd1;
$warning: #f6c343;
$danger: #e63757;
$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
);
$body-bg: $gray-100;
$body-color: $gray-900;
$base-shadow-color: #005595;
$nav-base-color: #1e2224;
$light-color: #b4b4b4;

// Customize typographic styles
$font-size-base: 1rem;
$font-family-sans-serif: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
$link-color: $primary;
$headings-line-height: 21px !default;
$small-font-size: 90%;

// Customize button styles
$btn-border-radius: 7px;
$font-weight-medium: 500 !default;
$btn-font-weight: 400 !default;
$btn-transition: box-shadow 0.3s cubic-bezier(0.35, 0, 0.25, 1), transform 0.2s cubic-bezier(0.35, 0, 0.25, 1), background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
$line-height-lg: 1.75 !default;
$input-btn-padding-y: 0.65rem !default;
$input-btn-padding-x: 0.75rem !default;
$input-btn-padding-y-lg: 0.4rem !default;
$input-btn-padding-x-lg: 1.6rem !default;
$input-btn-padding-y-sm: 0.5rem !default;
$input-btn-padding-x-sm: 0.75rem !default;
// Custmize form component styles
$input-border-radius: 0;
$input-border-radius-lg: 5px;
$input-border-radius-sm: 5px;
$input-focus-border-color: $primary;
$input-btn-border-width: 1px;
$input-btn-focus-box-shadow: none;
$input-btn-focus-width: 0;
$input-disabled-bg: rgba(#dfe6ec, 0.27) !default;
// Dropdowns
// Dropdown menu container and contents.
$dropdown-padding-y: 0.75rem;
$dropdown-item-padding-y: 0.75rem;
$dropdown-item-padding-x: 1.5rem;
$dropdown-header-color: $black;
$dropdown-min-width: 10rem !default;
$dropdown-padding-y: 0.5rem !default;
$dropdown-spacer: 0.125rem !default;
$dropdown-bg: $white !default;
$dropdown-border-color: rgba($black, 0.15) !default;

$dropdown-divider-bg: $gray-300 !default;
$dropdown-box-shadow: 0 0.5rem 1rem rgba($black, 0.175) !default;
$dropdown-link-color: $body-color !default;
$dropdown-link-hover-color: darken($body-color, 5%) !default;
$dropdown-link-hover-bg: $gray-100 !default;
$dropdown-link-active-color: darken($body-color, 5%) !default;
$dropdown-link-active-bg: darken($gray-200, 5%) !default;
// Forms
$input-color: $primary;
// Links
//
// Style anchor elements.
$link-hover-decoration: underline !default;
// List
$list-group-border-color: rgba($primary, 0.4);

// card
$card-border-radius: 1rem;
$card-color: $body-color;
$card-spacer-y: 1.5rem !default;
$card-spacer-x: 1.5rem !default;

// progress bar
$progress-height: 0.4rem !default;
$progress-border-radius: 5px;
$progress-height-lg: 0.8rem !default;
$progress-height-xl: 1.2rem !default;

// badge
$badge-padding-y: 0.5em !default;
$badge-padding-x: 0.8em !default;
// Tables
$table-head-color: $black !default;
$table-accent-bg: rgba($primary, 0.05);
$table-border-color: $gray-200;
$table-border-width: 2px;
// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.
$table-cell-padding: 0.75rem !default;
$table-cell-padding-sm: 0.45rem !default;

//shadow
$box-shadow-sm: 0 0.125rem 0.25rem rgba($gray-900, 0.02) !default;
$box-shadow: 0 0.5rem 1rem rgba($gray-900, 0.03) !default;
$box-shadow-lg: 0 1rem 3rem rgba($gray-900, 0.06) !default;

// Pagination

$pagination-padding-y: 0.875rem !default;
$pagination-padding-x: 1.25rem !default;
$pagination-padding-y-sm: 0.25rem !default;
$pagination-padding-x-sm: 0.5rem !default;
$pagination-padding-y-lg: 0.75rem !default;
$pagination-padding-x-lg: 1.5rem !default;
$pagination-line-height: 1.25 !default;

$pagination-color: $secondary !important;
$pagination-bg: $white !default;
$pagination-border-color: $gray-200 !default;
$pagination-hover-bg: $white !important;
$pagination-hover-color: $primary !important;
$pagination-active-color: $primary !important;
$pagination-active-bg: $white !default;

$modal-xl: 1140px !default;
$modal-lg: 800px !default;
$modal-md: 795px !default;
$modal-sm: 300px !default;

//
// Application Variables
//-----------------------------------------------------

// ------- Header --------
$header-height: 70px;
$footer-height: 70px;

// ------- Sidebar --------
$sidebar-width: 250px;
$sidebar-mini-width: 60px;
$sidebar-color: #ffffff;
$input-color: $primary; //
// Customize bootstrap variables to match
// look and feel of the theme
//-----------------------------------------------------
// stylelint-disable
$white: #fff !default;
$gray-100: #f9fbfd !default;
$gray-200: #ececec !default;
$gray-300: #e3ebf6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #95aac9 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #12263f !default;
$black: #000 !default;

/*Theme color*/

$primary: #36b7b7;
$secondary: #878787;
$success: #00d97e;
$info: #39afd1;
$warning: #f6c343;
$danger: #e63757;
$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
);
$body-bg: $gray-100;
$body-color: $gray-900;
$base-shadow-color: #005595;
$nav-base-color: #1e2224;
$light-color: #b4b4b4;

// Customize typographic styles
$font-size-base: 1rem;
$font-family-sans-serif: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
$link-color: $primary;
$headings-line-height: 21px !default;
$small-font-size: 90%;

// Customize button styles
$btn-border-radius: 7px;
$font-weight-medium: 500 !default;
$btn-font-weight: 400 !default;
$btn-transition: box-shadow 0.3s cubic-bezier(0.35, 0, 0.25, 1), transform 0.2s cubic-bezier(0.35, 0, 0.25, 1), background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
$line-height-lg: 1.75 !default;
$input-btn-padding-y: 0.65rem !default;
$input-btn-padding-x: 0.75rem !default;
$input-btn-padding-y-lg: 0.4rem !default;
$input-btn-padding-x-lg: 1.6rem !default;
$input-btn-padding-y-sm: 0.5rem !default;
$input-btn-padding-x-sm: 0.75rem !default;
// Custmize form component styles
$input-border-radius: 0;
$input-border-radius-lg: 5px;
$input-border-radius-sm: 5px;
$input-focus-border-color: $primary;
$input-btn-border-width: 1px;
$input-btn-focus-box-shadow: none;
$input-btn-focus-width: 0;
$input-disabled-bg: rgba(#dfe6ec, 0.27) !default;
// Dropdowns
// Dropdown menu container and contents.
$dropdown-padding-y: 0.75rem;
$dropdown-item-padding-y: 0.75rem;
$dropdown-item-padding-x: 1.5rem;
$dropdown-header-color: $black;
$dropdown-min-width: 10rem !default;
$dropdown-padding-y: 0.5rem !default;
$dropdown-spacer: 0.125rem !default;
$dropdown-bg: $white !default;
$dropdown-border-color: rgba($black, 0.15) !default;

$dropdown-divider-bg: $gray-300 !default;
$dropdown-box-shadow: 0 0.5rem 1rem rgba($black, 0.175) !default;
$dropdown-link-color: $body-color !default;
$dropdown-link-hover-color: darken($body-color, 5%) !default;
$dropdown-link-hover-bg: $gray-100 !default;
$dropdown-link-active-color: darken($body-color, 5%) !default;
$dropdown-link-active-bg: darken($gray-200, 5%) !default;
// Forms
$input-color: $primary;
// Links
//
// Style anchor elements.
$link-hover-decoration: underline !default;
// List
$list-group-border-color: rgba($primary, 0.4);

// card
$card-border-radius: 1rem;
$card-color: $body-color;
$card-spacer-y: 1.5rem !default;
$card-spacer-x: 1.5rem !default;

// progress bar
$progress-height: 0.4rem !default;
$progress-border-radius: 5px;
$progress-height-lg: 0.8rem !default;
$progress-height-xl: 1.2rem !default;

// badge
$badge-padding-y: 0.5em !default;
$badge-padding-x: 0.8em !default;
// Tables
$table-head-color: $black !default;
$table-accent-bg: rgba($primary, 0.05);
$table-border-color: $gray-200;
$table-border-width: 2px;
// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.
$table-cell-padding: 0.75rem !default;
$table-cell-padding-sm: 0.45rem !default;

//shadow
$box-shadow-sm: 0 0.125rem 0.25rem rgba($gray-900, 0.02) !default;
$box-shadow: 0 0.5rem 1rem rgba($gray-900, 0.03) !default;
$box-shadow-lg: 0 1rem 3rem rgba($gray-900, 0.06) !default;

// Pagination

$pagination-padding-y: 0.875rem !default;
$pagination-padding-x: 1.25rem !default;
$pagination-padding-y-sm: 0.25rem !default;
$pagination-padding-x-sm: 0.5rem !default;
$pagination-padding-y-lg: 0.75rem !default;
$pagination-padding-x-lg: 1.5rem !default;
$pagination-line-height: 1.25 !default;

$pagination-color: $secondary !important;
$pagination-bg: $white !default;
$pagination-border-color: $gray-200 !default;
$pagination-hover-bg: $white !important;
$pagination-hover-color: $primary !important;
$pagination-active-color: $primary !important;
$pagination-active-bg: $white !default;

$modal-xl: 1140px !default;
$modal-lg: 800px !default;
$modal-md: 795px !default;
$modal-sm: 300px !default;

//
// Application Variables
//-----------------------------------------------------

// ------- Header --------
$header-height: 70px;
$footer-height: 70px;

// ------- Sidebar --------
$sidebar-width: 250px;
$sidebar-mini-width: 60px;
$sidebar-color: #ffffff; //
// Customize bootstrap variables to match
// look and feel of the theme
//-----------------------------------------------------
// stylelint-disable
$white: #fff !default;
$gray-100: #f9fbfd !default;
$gray-200: #ececec !default;
$gray-300: #e3ebf6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #95aac9 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #12263f !default;
$black: #000 !default;

/*Theme color*/

$primary: #36b7b7;
$secondary: #878787;
$success: #00d97e;
$info: #39afd1;
$warning: #f6c343;
$danger: #e63757;
$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
);
$body-bg: $gray-100;
$body-color: $gray-900;
$base-shadow-color: #005595;
$nav-base-color: #1e2224;
$light-color: #b4b4b4;

// Customize typographic styles
$font-size-base: 1rem;
$font-family-sans-serif: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
$link-color: $primary;
$headings-line-height: 21px !default;
$small-font-size: 90%;

// Customize button styles
$btn-border-radius: 7px;
$font-weight-medium: 500 !default;
$btn-font-weight: 400 !default;
$btn-transition: box-shadow 0.3s cubic-bezier(0.35, 0, 0.25, 1), transform 0.2s cubic-bezier(0.35, 0, 0.25, 1), background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
$line-height-lg: 1.75 !default;
$input-btn-padding-y: 0.65rem !default;
$input-btn-padding-x: 0.75rem !default;
$input-btn-padding-y-lg: 0.4rem !default;
$input-btn-padding-x-lg: 1.6rem !default;
$input-btn-padding-y-sm: 0.5rem !default;
$input-btn-padding-x-sm: 0.75rem !default;
// Custmize form component styles
$input-border-radius: 0;
$input-border-radius-lg: 5px;
$input-border-radius-sm: 5px;
$input-focus-border-color: $primary;
$input-btn-border-width: 1px;
$input-btn-focus-box-shadow: none;
$input-btn-focus-width: 0;
$input-disabled-bg: rgba(#dfe6ec, 0.27) !default;
// Dropdowns
// Dropdown menu container and contents.
$dropdown-padding-y: 0.75rem;
$dropdown-item-padding-y: 0.75rem;
$dropdown-item-padding-x: 1.5rem;
$dropdown-header-color: $black;
$dropdown-min-width: 10rem !default;
$dropdown-padding-y: 0.5rem !default;
$dropdown-spacer: 0.125rem !default;
$dropdown-bg: $white !default;
$dropdown-border-color: rgba($black, 0.15) !default;

$dropdown-divider-bg: $gray-300 !default;
$dropdown-box-shadow: 0 0.5rem 1rem rgba($black, 0.175) !default;
$dropdown-link-color: $body-color !default;
$dropdown-link-hover-color: darken($body-color, 5%) !default;
$dropdown-link-hover-bg: $gray-100 !default;
$dropdown-link-active-color: darken($body-color, 5%) !default;
$dropdown-link-active-bg: darken($gray-200, 5%) !default;
// Forms
$input-color: $primary;
// Links
//
// Style anchor elements.
$link-hover-decoration: underline !default;
// List
$list-group-border-color: rgba($primary, 0.4);

// card
$card-border-radius: 1rem;
$card-color: $body-color;
$card-spacer-y: 1.5rem !default;
$card-spacer-x: 1.5rem !default;

// progress bar
$progress-height: 0.4rem !default;
$progress-border-radius: 5px;
$progress-height-lg: 0.8rem !default;
$progress-height-xl: 1.2rem !default;

// badge
$badge-padding-y: 0.5em !default;
$badge-padding-x: 0.8em !default;
// Tables
$table-head-color: $black !default;
$table-accent-bg: rgba($primary, 0.05);
$table-border-color: $gray-200;
$table-border-width: 2px;
// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.
$table-cell-padding: 0.75rem !default;
$table-cell-padding-sm: 0.45rem !default;

//shadow
$box-shadow-sm: 0 0.125rem 0.25rem rgba($gray-900, 0.02) !default;
$box-shadow: 0 0.5rem 1rem rgba($gray-900, 0.03) !default;
$box-shadow-lg: 0 1rem 3rem rgba($gray-900, 0.06) !default;

// Pagination

$pagination-padding-y: 0.875rem !default;
$pagination-padding-x: 1.25rem !default;
$pagination-padding-y-sm: 0.25rem !default;
$pagination-padding-x-sm: 0.5rem !default;
$pagination-padding-y-lg: 0.75rem !default;
$pagination-padding-x-lg: 1.5rem !default;
$pagination-line-height: 1.25 !default;

$pagination-color: $secondary !important;
$pagination-bg: $white !default;
$pagination-border-color: $gray-200 !default;
$pagination-hover-bg: $white !important;
$pagination-hover-color: $primary !important;
$pagination-active-color: $primary !important;
$pagination-active-bg: $white !default;

$modal-xl: 1140px !default;
$modal-lg: 800px !default;
$modal-md: 795px !default;
$modal-sm: 300px !default;

//
// Application Variables
//-----------------------------------------------------

// ------- Header --------
$header-height: 70px;
$footer-height: 70px;

// ------- Sidebar --------
$sidebar-width: 250px;
$sidebar-mini-width: 60px;
$sidebar-color: #ffffff; //
// Customize bootstrap variables to match
// look and feel of the theme
//-----------------------------------------------------
// stylelint-disable
$white: #fff !default;
$gray-100: #f9fbfd !default;
$gray-200: #ececec !default;
$gray-300: #e3ebf6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #95aac9 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #12263f !default;
$black: #000 !default;

/*Theme color*/

$primary: #36b7b7;
$secondary: #878787;
$success: #00d97e;
$info: #39afd1;
$warning: #f6c343;
$danger: #e63757;
$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
);
$body-bg: $gray-100;
$body-color: $gray-900;
$base-shadow-color: #005595;
$nav-base-color: #1e2224;
$light-color: #b4b4b4;

// Customize typographic styles
$font-size-base: 1rem;
$font-family-sans-serif: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
$link-color: $primary;
$headings-line-height: 21px !default;
$small-font-size: 90%;

// Customize button styles
$btn-border-radius: 7px;
$font-weight-medium: 500 !default;
$btn-font-weight: 400 !default;
$btn-transition: box-shadow 0.3s cubic-bezier(0.35, 0, 0.25, 1), transform 0.2s cubic-bezier(0.35, 0, 0.25, 1), background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
$line-height-lg: 1.75 !default;
$input-btn-padding-y: 0.65rem !default;
$input-btn-padding-x: 0.75rem !default;
$input-btn-padding-y-lg: 0.4rem !default;
$input-btn-padding-x-lg: 1.6rem !default;
$input-btn-padding-y-sm: 0.5rem !default;
$input-btn-padding-x-sm: 0.75rem !default;
// Custmize form component styles
$input-border-radius: 0;
$input-border-radius-lg: 5px;
$input-border-radius-sm: 5px;
$input-focus-border-color: $primary;
$input-btn-border-width: 1px;
$input-btn-focus-box-shadow: none;
$input-btn-focus-width: 0;
$input-disabled-bg: rgba(#dfe6ec, 0.27) !default;
// Dropdowns
// Dropdown menu container and contents.
$dropdown-padding-y: 0.75rem;
$dropdown-item-padding-y: 0.75rem;
$dropdown-item-padding-x: 1.5rem;
$dropdown-header-color: $black;
$dropdown-min-width: 10rem !default;
$dropdown-padding-y: 0.5rem !default;
$dropdown-spacer: 0.125rem !default;
$dropdown-bg: $white !default;
$dropdown-border-color: rgba($black, 0.15) !default;

$dropdown-divider-bg: $gray-300 !default;
$dropdown-box-shadow: 0 0.5rem 1rem rgba($black, 0.175) !default;
$dropdown-link-color: $body-color !default;
$dropdown-link-hover-color: darken($body-color, 5%) !default;
$dropdown-link-hover-bg: $gray-100 !default;
$dropdown-link-active-color: darken($body-color, 5%) !default;
$dropdown-link-active-bg: darken($gray-200, 5%) !default;
// Forms
$input-color: $primary;
// Links
//
// Style anchor elements.
$link-hover-decoration: underline !default;
// List
$list-group-border-color: rgba($primary, 0.4);

// card
$card-border-radius: 1rem;
$card-color: $body-color;
$card-spacer-y: 1.5rem !default;
$card-spacer-x: 1.5rem !default;

// progress bar
$progress-height: 0.4rem !default;
$progress-border-radius: 5px;
$progress-height-lg: 0.8rem !default;
$progress-height-xl: 1.2rem !default;

// badge
$badge-padding-y: 0.5em !default;
$badge-padding-x: 0.8em !default;
// Tables
$table-head-color: $black !default;
$table-accent-bg: rgba($primary, 0.05);
$table-border-color: $gray-200;
$table-border-width: 2px;
// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.
$table-cell-padding: 0.75rem !default;
$table-cell-padding-sm: 0.45rem !default;

//shadow
$box-shadow-sm: 0 0.125rem 0.25rem rgba($gray-900, 0.02) !default;
$box-shadow: 0 0.5rem 1rem rgba($gray-900, 0.03) !default;
$box-shadow-lg: 0 1rem 3rem rgba($gray-900, 0.06) !default;

// Pagination

$pagination-padding-y: 0.875rem !default;
$pagination-padding-x: 1.25rem !default;
$pagination-padding-y-sm: 0.25rem !default;
$pagination-padding-x-sm: 0.5rem !default;
$pagination-padding-y-lg: 0.75rem !default;
$pagination-padding-x-lg: 1.5rem !default;
$pagination-line-height: 1.25 !default;

$pagination-color: $secondary !important;
$pagination-bg: $white !default;
$pagination-border-color: $gray-200 !default;
$pagination-hover-bg: $white !important;
$pagination-hover-color: $primary !important;
$pagination-active-color: $primary !important;
$pagination-active-bg: $white !default;

$modal-xl: 1140px !default;
$modal-lg: 800px !default;
$modal-md: 795px !default;
$modal-sm: 300px !default;

//
// Application Variables
//-----------------------------------------------------

// ------- Header --------
$header-height: 70px;
$footer-height: 70px;

// ------- Sidebar --------
$sidebar-width: 250px;
$sidebar-mini-width: 60px;
$sidebar-color: #ffffff;
$link-hover-decoration: underline !default; //
// Customize bootstrap variables to match
// look and feel of the theme
//-----------------------------------------------------
// stylelint-disable
$white: #fff !default;
$gray-100: #f9fbfd !default;
$gray-200: #ececec !default;
$gray-300: #e3ebf6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #95aac9 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #12263f !default;
$black: #000 !default;

/*Theme color*/

$primary: #36b7b7;
$secondary: #878787;
$success: #00d97e;
$info: #39afd1;
$warning: #f6c343;
$danger: #e63757;
$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
);
$body-bg: $gray-100;
$body-color: $gray-900;
$base-shadow-color: #005595;
$nav-base-color: #1e2224;
$light-color: #b4b4b4;

// Customize typographic styles
$font-size-base: 1rem;
$font-family-sans-serif: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
$link-color: $primary;
$headings-line-height: 21px !default;
$small-font-size: 90%;

// Customize button styles
$btn-border-radius: 7px;
$font-weight-medium: 500 !default;
$btn-font-weight: 400 !default;
$btn-transition: box-shadow 0.3s cubic-bezier(0.35, 0, 0.25, 1), transform 0.2s cubic-bezier(0.35, 0, 0.25, 1), background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
$line-height-lg: 1.75 !default;
$input-btn-padding-y: 0.65rem !default;
$input-btn-padding-x: 0.75rem !default;
$input-btn-padding-y-lg: 0.4rem !default;
$input-btn-padding-x-lg: 1.6rem !default;
$input-btn-padding-y-sm: 0.5rem !default;
$input-btn-padding-x-sm: 0.75rem !default;
// Custmize form component styles
$input-border-radius: 0;
$input-border-radius-lg: 5px;
$input-border-radius-sm: 5px;
$input-focus-border-color: $primary;
$input-btn-border-width: 1px;
$input-btn-focus-box-shadow: none;
$input-btn-focus-width: 0;
$input-disabled-bg: rgba(#dfe6ec, 0.27) !default;
// Dropdowns
// Dropdown menu container and contents.
$dropdown-padding-y: 0.75rem;
$dropdown-item-padding-y: 0.75rem;
$dropdown-item-padding-x: 1.5rem;
$dropdown-header-color: $black;
$dropdown-min-width: 10rem !default;
$dropdown-padding-y: 0.5rem !default;
$dropdown-spacer: 0.125rem !default;
$dropdown-bg: $white !default;
$dropdown-border-color: rgba($black, 0.15) !default;

$dropdown-divider-bg: $gray-300 !default;
$dropdown-box-shadow: 0 0.5rem 1rem rgba($black, 0.175) !default;
$dropdown-link-color: $body-color !default;
$dropdown-link-hover-color: darken($body-color, 5%) !default;
$dropdown-link-hover-bg: $gray-100 !default;
$dropdown-link-active-color: darken($body-color, 5%) !default;
$dropdown-link-active-bg: darken($gray-200, 5%) !default;
// Forms
$input-color: $primary;
// Links
//
// Style anchor elements.
$link-hover-decoration: underline !default;
// List
$list-group-border-color: rgba($primary, 0.4);

// card
$card-border-radius: 1rem;
$card-color: $body-color;
$card-spacer-y: 1.5rem !default;
$card-spacer-x: 1.5rem !default;

// progress bar
$progress-height: 0.4rem !default;
$progress-border-radius: 5px;
$progress-height-lg: 0.8rem !default;
$progress-height-xl: 1.2rem !default;

// badge
$badge-padding-y: 0.5em !default;
$badge-padding-x: 0.8em !default;
// Tables
$table-head-color: $black !default;
$table-accent-bg: rgba($primary, 0.05);
$table-border-color: $gray-200;
$table-border-width: 2px;
// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.
$table-cell-padding: 0.75rem !default;
$table-cell-padding-sm: 0.45rem !default;

//shadow
$box-shadow-sm: 0 0.125rem 0.25rem rgba($gray-900, 0.02) !default;
$box-shadow: 0 0.5rem 1rem rgba($gray-900, 0.03) !default;
$box-shadow-lg: 0 1rem 3rem rgba($gray-900, 0.06) !default;

// Pagination

$pagination-padding-y: 0.875rem !default;
$pagination-padding-x: 1.25rem !default;
$pagination-padding-y-sm: 0.25rem !default;
$pagination-padding-x-sm: 0.5rem !default;
$pagination-padding-y-lg: 0.75rem !default;
$pagination-padding-x-lg: 1.5rem !default;
$pagination-line-height: 1.25 !default;

$pagination-color: $secondary !important;
$pagination-bg: $white !default;
$pagination-border-color: $gray-200 !default;
$pagination-hover-bg: $white !important;
$pagination-hover-color: $primary !important;
$pagination-active-color: $primary !important;
$pagination-active-bg: $white !default;

$modal-xl: 1140px !default;
$modal-lg: 800px !default;
$modal-md: 795px !default;
$modal-sm: 300px !default;

//
// Application Variables
//-----------------------------------------------------

// ------- Header --------
$header-height: 70px;
$footer-height: 70px;

// ------- Sidebar --------
$sidebar-width: 250px;
$sidebar-mini-width: 60px;
$sidebar-color: #ffffff;
$list-group-border-color: rgba($primary, 0.4); //
// Customize bootstrap variables to match
// look and feel of the theme
//-----------------------------------------------------
// stylelint-disable
$white: #fff !default;
$gray-100: #f9fbfd !default;
$gray-200: #ececec !default;
$gray-300: #e3ebf6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #95aac9 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #12263f !default;
$black: #000 !default;

/*Theme color*/

$primary: #36b7b7;
$secondary: #878787;
$success: #00d97e;
$info: #39afd1;
$warning: #f6c343;
$danger: #e63757;
$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
);
$body-bg: $gray-100;
$body-color: $gray-900;
$base-shadow-color: #005595;
$nav-base-color: #1e2224;
$light-color: #b4b4b4;

// Customize typographic styles
$font-size-base: 1rem;
$font-family-sans-serif: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
$link-color: $primary;
$headings-line-height: 21px !default;
$small-font-size: 90%;

// Customize button styles
$btn-border-radius: 7px;
$font-weight-medium: 500 !default;
$btn-font-weight: 400 !default;
$btn-transition: box-shadow 0.3s cubic-bezier(0.35, 0, 0.25, 1), transform 0.2s cubic-bezier(0.35, 0, 0.25, 1), background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
$line-height-lg: 1.75 !default;
$input-btn-padding-y: 0.65rem !default;
$input-btn-padding-x: 0.75rem !default;
$input-btn-padding-y-lg: 0.4rem !default;
$input-btn-padding-x-lg: 1.6rem !default;
$input-btn-padding-y-sm: 0.5rem !default;
$input-btn-padding-x-sm: 0.75rem !default;
// Custmize form component styles
$input-border-radius: 0;
$input-border-radius-lg: 5px;
$input-border-radius-sm: 5px;
$input-focus-border-color: $primary;
$input-btn-border-width: 1px;
$input-btn-focus-box-shadow: none;
$input-btn-focus-width: 0;
$input-disabled-bg: rgba(#dfe6ec, 0.27) !default;
// Dropdowns
// Dropdown menu container and contents.
$dropdown-padding-y: 0.75rem;
$dropdown-item-padding-y: 0.75rem;
$dropdown-item-padding-x: 1.5rem;
$dropdown-header-color: $black;
$dropdown-min-width: 10rem !default;
$dropdown-padding-y: 0.5rem !default;
$dropdown-spacer: 0.125rem !default;
$dropdown-bg: $white !default;
$dropdown-border-color: rgba($black, 0.15) !default;

$dropdown-divider-bg: $gray-300 !default;
$dropdown-box-shadow: 0 0.5rem 1rem rgba($black, 0.175) !default;
$dropdown-link-color: $body-color !default;
$dropdown-link-hover-color: darken($body-color, 5%) !default;
$dropdown-link-hover-bg: $gray-100 !default;
$dropdown-link-active-color: darken($body-color, 5%) !default;
$dropdown-link-active-bg: darken($gray-200, 5%) !default;
// Forms
$input-color: $primary;
// Links
//
// Style anchor elements.
$link-hover-decoration: underline !default;
// List
$list-group-border-color: rgba($primary, 0.4);

// card
$card-border-radius: 1rem;
$card-color: $body-color;
$card-spacer-y: 1.5rem !default;
$card-spacer-x: 1.5rem !default;

// progress bar
$progress-height: 0.4rem !default;
$progress-border-radius: 5px;
$progress-height-lg: 0.8rem !default;
$progress-height-xl: 1.2rem !default;

// badge
$badge-padding-y: 0.5em !default;
$badge-padding-x: 0.8em !default;
// Tables
$table-head-color: $black !default;
$table-accent-bg: rgba($primary, 0.05);
$table-border-color: $gray-200;
$table-border-width: 2px;
// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.
$table-cell-padding: 0.75rem !default;
$table-cell-padding-sm: 0.45rem !default;

//shadow
$box-shadow-sm: 0 0.125rem 0.25rem rgba($gray-900, 0.02) !default;
$box-shadow: 0 0.5rem 1rem rgba($gray-900, 0.03) !default;
$box-shadow-lg: 0 1rem 3rem rgba($gray-900, 0.06) !default;

// Pagination

$pagination-padding-y: 0.875rem !default;
$pagination-padding-x: 1.25rem !default;
$pagination-padding-y-sm: 0.25rem !default;
$pagination-padding-x-sm: 0.5rem !default;
$pagination-padding-y-lg: 0.75rem !default;
$pagination-padding-x-lg: 1.5rem !default;
$pagination-line-height: 1.25 !default;

$pagination-color: $secondary !important;
$pagination-bg: $white !default;
$pagination-border-color: $gray-200 !default;
$pagination-hover-bg: $white !important;
$pagination-hover-color: $primary !important;
$pagination-active-color: $primary !important;
$pagination-active-bg: $white !default;

$modal-xl: 1140px !default;
$modal-lg: 800px !default;
$modal-md: 795px !default;
$modal-sm: 300px !default;

//
// Application Variables
//-----------------------------------------------------

// ------- Header --------
$header-height: 70px;
$footer-height: 70px;

// ------- Sidebar --------
$sidebar-width: 250px;
$sidebar-mini-width: 60px;
$sidebar-color: #ffffff;
$card-border-radius: 1rem;
$card-color: $body-color;
$card-spacer-y: 1.5rem !default;
$card-spacer-x: 1.5rem !default; //
// Customize bootstrap variables to match
// look and feel of the theme
//-----------------------------------------------------
// stylelint-disable
$white: #fff !default;
$gray-100: #f9fbfd !default;
$gray-200: #ececec !default;
$gray-300: #e3ebf6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #95aac9 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #12263f !default;
$black: #000 !default;

/*Theme color*/

$primary: #36b7b7;
$secondary: #878787;
$success: #00d97e;
$info: #39afd1;
$warning: #f6c343;
$danger: #e63757;
$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
);
$body-bg: $gray-100;
$body-color: $gray-900;
$base-shadow-color: #005595;
$nav-base-color: #1e2224;
$light-color: #b4b4b4;

// Customize typographic styles
$font-size-base: 1rem;
$font-family-sans-serif: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
$link-color: $primary;
$headings-line-height: 21px !default;
$small-font-size: 90%;

// Customize button styles
$btn-border-radius: 7px;
$font-weight-medium: 500 !default;
$btn-font-weight: 400 !default;
$btn-transition: box-shadow 0.3s cubic-bezier(0.35, 0, 0.25, 1), transform 0.2s cubic-bezier(0.35, 0, 0.25, 1), background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
$line-height-lg: 1.75 !default;
$input-btn-padding-y: 0.65rem !default;
$input-btn-padding-x: 0.75rem !default;
$input-btn-padding-y-lg: 0.4rem !default;
$input-btn-padding-x-lg: 1.6rem !default;
$input-btn-padding-y-sm: 0.5rem !default;
$input-btn-padding-x-sm: 0.75rem !default;
// Custmize form component styles
$input-border-radius: 0;
$input-border-radius-lg: 5px;
$input-border-radius-sm: 5px;
$input-focus-border-color: $primary;
$input-btn-border-width: 1px;
$input-btn-focus-box-shadow: none;
$input-btn-focus-width: 0;
$input-disabled-bg: rgba(#dfe6ec, 0.27) !default;
// Dropdowns
// Dropdown menu container and contents.
$dropdown-padding-y: 0.75rem;
$dropdown-item-padding-y: 0.75rem;
$dropdown-item-padding-x: 1.5rem;
$dropdown-header-color: $black;
$dropdown-min-width: 10rem !default;
$dropdown-padding-y: 0.5rem !default;
$dropdown-spacer: 0.125rem !default;
$dropdown-bg: $white !default;
$dropdown-border-color: rgba($black, 0.15) !default;

$dropdown-divider-bg: $gray-300 !default;
$dropdown-box-shadow: 0 0.5rem 1rem rgba($black, 0.175) !default;
$dropdown-link-color: $body-color !default;
$dropdown-link-hover-color: darken($body-color, 5%) !default;
$dropdown-link-hover-bg: $gray-100 !default;
$dropdown-link-active-color: darken($body-color, 5%) !default;
$dropdown-link-active-bg: darken($gray-200, 5%) !default;
// Forms
$input-color: $primary;
// Links
//
// Style anchor elements.
$link-hover-decoration: underline !default;
// List
$list-group-border-color: rgba($primary, 0.4);

// card
$card-border-radius: 1rem;
$card-color: $body-color;
$card-spacer-y: 1.5rem !default;
$card-spacer-x: 1.5rem !default;

// progress bar
$progress-height: 0.4rem !default;
$progress-border-radius: 5px;
$progress-height-lg: 0.8rem !default;
$progress-height-xl: 1.2rem !default;

// badge
$badge-padding-y: 0.5em !default;
$badge-padding-x: 0.8em !default;
// Tables
$table-head-color: $black !default;
$table-accent-bg: rgba($primary, 0.05);
$table-border-color: $gray-200;
$table-border-width: 2px;
// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.
$table-cell-padding: 0.75rem !default;
$table-cell-padding-sm: 0.45rem !default;

//shadow
$box-shadow-sm: 0 0.125rem 0.25rem rgba($gray-900, 0.02) !default;
$box-shadow: 0 0.5rem 1rem rgba($gray-900, 0.03) !default;
$box-shadow-lg: 0 1rem 3rem rgba($gray-900, 0.06) !default;

// Pagination

$pagination-padding-y: 0.875rem !default;
$pagination-padding-x: 1.25rem !default;
$pagination-padding-y-sm: 0.25rem !default;
$pagination-padding-x-sm: 0.5rem !default;
$pagination-padding-y-lg: 0.75rem !default;
$pagination-padding-x-lg: 1.5rem !default;
$pagination-line-height: 1.25 !default;

$pagination-color: $secondary !important;
$pagination-bg: $white !default;
$pagination-border-color: $gray-200 !default;
$pagination-hover-bg: $white !important;
$pagination-hover-color: $primary !important;
$pagination-active-color: $primary !important;
$pagination-active-bg: $white !default;

$modal-xl: 1140px !default;
$modal-lg: 800px !default;
$modal-md: 795px !default;
$modal-sm: 300px !default;

//
// Application Variables
//-----------------------------------------------------

// ------- Header --------
$header-height: 70px;
$footer-height: 70px;

// ------- Sidebar --------
$sidebar-width: 250px;
$sidebar-mini-width: 60px;
$sidebar-color: #ffffff;
$progress-height: 0.4rem !default;
$progress-border-radius: 5px;
$progress-height-lg: 0.8rem !default;
$progress-height-xl: 1.2rem !default; //
// Customize bootstrap variables to match
// look and feel of the theme
//-----------------------------------------------------
// stylelint-disable
$white: #fff !default;
$gray-100: #f9fbfd !default;
$gray-200: #ececec !default;
$gray-300: #e3ebf6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #95aac9 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #12263f !default;
$black: #000 !default;

/*Theme color*/

$primary: #36b7b7;
$secondary: #878787;
$success: #00d97e;
$info: #39afd1;
$warning: #f6c343;
$danger: #e63757;
$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
);
$body-bg: $gray-100;
$body-color: $gray-900;
$base-shadow-color: #005595;
$nav-base-color: #1e2224;
$light-color: #b4b4b4;

// Customize typographic styles
$font-size-base: 1rem;
$font-family-sans-serif: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
$link-color: $primary;
$headings-line-height: 21px !default;
$small-font-size: 90%;

// Customize button styles
$btn-border-radius: 7px;
$font-weight-medium: 500 !default;
$btn-font-weight: 400 !default;
$btn-transition: box-shadow 0.3s cubic-bezier(0.35, 0, 0.25, 1), transform 0.2s cubic-bezier(0.35, 0, 0.25, 1), background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
$line-height-lg: 1.75 !default;
$input-btn-padding-y: 0.65rem !default;
$input-btn-padding-x: 0.75rem !default;
$input-btn-padding-y-lg: 0.4rem !default;
$input-btn-padding-x-lg: 1.6rem !default;
$input-btn-padding-y-sm: 0.5rem !default;
$input-btn-padding-x-sm: 0.75rem !default;
// Custmize form component styles
$input-border-radius: 0;
$input-border-radius-lg: 5px;
$input-border-radius-sm: 5px;
$input-focus-border-color: $primary;
$input-btn-border-width: 1px;
$input-btn-focus-box-shadow: none;
$input-btn-focus-width: 0;
$input-disabled-bg: rgba(#dfe6ec, 0.27) !default;
// Dropdowns
// Dropdown menu container and contents.
$dropdown-padding-y: 0.75rem;
$dropdown-item-padding-y: 0.75rem;
$dropdown-item-padding-x: 1.5rem;
$dropdown-header-color: $black;
$dropdown-min-width: 10rem !default;
$dropdown-padding-y: 0.5rem !default;
$dropdown-spacer: 0.125rem !default;
$dropdown-bg: $white !default;
$dropdown-border-color: rgba($black, 0.15) !default;

$dropdown-divider-bg: $gray-300 !default;
$dropdown-box-shadow: 0 0.5rem 1rem rgba($black, 0.175) !default;
$dropdown-link-color: $body-color !default;
$dropdown-link-hover-color: darken($body-color, 5%) !default;
$dropdown-link-hover-bg: $gray-100 !default;
$dropdown-link-active-color: darken($body-color, 5%) !default;
$dropdown-link-active-bg: darken($gray-200, 5%) !default;
// Forms
$input-color: $primary;
// Links
//
// Style anchor elements.
$link-hover-decoration: underline !default;
// List
$list-group-border-color: rgba($primary, 0.4);

// card
$card-border-radius: 1rem;
$card-color: $body-color;
$card-spacer-y: 1.5rem !default;
$card-spacer-x: 1.5rem !default;

// progress bar
$progress-height: 0.4rem !default;
$progress-border-radius: 5px;
$progress-height-lg: 0.8rem !default;
$progress-height-xl: 1.2rem !default;

// badge
$badge-padding-y: 0.5em !default;
$badge-padding-x: 0.8em !default;
// Tables
$table-head-color: $black !default;
$table-accent-bg: rgba($primary, 0.05);
$table-border-color: $gray-200;
$table-border-width: 2px;
// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.
$table-cell-padding: 0.75rem !default;
$table-cell-padding-sm: 0.45rem !default;

//shadow
$box-shadow-sm: 0 0.125rem 0.25rem rgba($gray-900, 0.02) !default;
$box-shadow: 0 0.5rem 1rem rgba($gray-900, 0.03) !default;
$box-shadow-lg: 0 1rem 3rem rgba($gray-900, 0.06) !default;

// Pagination

$pagination-padding-y: 0.875rem !default;
$pagination-padding-x: 1.25rem !default;
$pagination-padding-y-sm: 0.25rem !default;
$pagination-padding-x-sm: 0.5rem !default;
$pagination-padding-y-lg: 0.75rem !default;
$pagination-padding-x-lg: 1.5rem !default;
$pagination-line-height: 1.25 !default;

$pagination-color: $secondary !important;
$pagination-bg: $white !default;
$pagination-border-color: $gray-200 !default;
$pagination-hover-bg: $white !important;
$pagination-hover-color: $primary !important;
$pagination-active-color: $primary !important;
$pagination-active-bg: $white !default;

$modal-xl: 1140px !default;
$modal-lg: 800px !default;
$modal-md: 795px !default;
$modal-sm: 300px !default;

//
// Application Variables
//-----------------------------------------------------

// ------- Header --------
$header-height: 70px;
$footer-height: 70px;

// ------- Sidebar --------
$sidebar-width: 250px;
$sidebar-mini-width: 60px;
$sidebar-color: #ffffff;
$badge-padding-y: 0.5em !default;
$badge-padding-x: 0.8em !default; //
// Customize bootstrap variables to match
// look and feel of the theme
//-----------------------------------------------------
// stylelint-disable
$white: #fff !default;
$gray-100: #f9fbfd !default;
$gray-200: #ececec !default;
$gray-300: #e3ebf6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #95aac9 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #12263f !default;
$black: #000 !default;

/*Theme color*/

$primary: #36b7b7;
$secondary: #878787;
$success: #00d97e;
$info: #39afd1;
$warning: #f6c343;
$danger: #e63757;
$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
);
$body-bg: $gray-100;
$body-color: $gray-900;
$base-shadow-color: #005595;
$nav-base-color: #1e2224;
$light-color: #b4b4b4;

// Customize typographic styles
$font-size-base: 1rem;
$font-family-sans-serif: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
$link-color: $primary;
$headings-line-height: 21px !default;
$small-font-size: 90%;

// Customize button styles
$btn-border-radius: 7px;
$font-weight-medium: 500 !default;
$btn-font-weight: 400 !default;
$btn-transition: box-shadow 0.3s cubic-bezier(0.35, 0, 0.25, 1), transform 0.2s cubic-bezier(0.35, 0, 0.25, 1), background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
$line-height-lg: 1.75 !default;
$input-btn-padding-y: 0.65rem !default;
$input-btn-padding-x: 0.75rem !default;
$input-btn-padding-y-lg: 0.4rem !default;
$input-btn-padding-x-lg: 1.6rem !default;
$input-btn-padding-y-sm: 0.5rem !default;
$input-btn-padding-x-sm: 0.75rem !default;
// Custmize form component styles
$input-border-radius: 0;
$input-border-radius-lg: 5px;
$input-border-radius-sm: 5px;
$input-focus-border-color: $primary;
$input-btn-border-width: 1px;
$input-btn-focus-box-shadow: none;
$input-btn-focus-width: 0;
$input-disabled-bg: rgba(#dfe6ec, 0.27) !default;
// Dropdowns
// Dropdown menu container and contents.
$dropdown-padding-y: 0.75rem;
$dropdown-item-padding-y: 0.75rem;
$dropdown-item-padding-x: 1.5rem;
$dropdown-header-color: $black;
$dropdown-min-width: 10rem !default;
$dropdown-padding-y: 0.5rem !default;
$dropdown-spacer: 0.125rem !default;
$dropdown-bg: $white !default;
$dropdown-border-color: rgba($black, 0.15) !default;

$dropdown-divider-bg: $gray-300 !default;
$dropdown-box-shadow: 0 0.5rem 1rem rgba($black, 0.175) !default;
$dropdown-link-color: $body-color !default;
$dropdown-link-hover-color: darken($body-color, 5%) !default;
$dropdown-link-hover-bg: $gray-100 !default;
$dropdown-link-active-color: darken($body-color, 5%) !default;
$dropdown-link-active-bg: darken($gray-200, 5%) !default;
// Forms
$input-color: $primary;
// Links
//
// Style anchor elements.
$link-hover-decoration: underline !default;
// List
$list-group-border-color: rgba($primary, 0.4);

// card
$card-border-radius: 1rem;
$card-color: $body-color;
$card-spacer-y: 1.5rem !default;
$card-spacer-x: 1.5rem !default;

// progress bar
$progress-height: 0.4rem !default;
$progress-border-radius: 5px;
$progress-height-lg: 0.8rem !default;
$progress-height-xl: 1.2rem !default;

// badge
$badge-padding-y: 0.5em !default;
$badge-padding-x: 0.8em !default;
// Tables
$table-head-color: $black !default;
$table-accent-bg: rgba($primary, 0.05);
$table-border-color: $gray-200;
$table-border-width: 2px;
// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.
$table-cell-padding: 0.75rem !default;
$table-cell-padding-sm: 0.45rem !default;

//shadow
$box-shadow-sm: 0 0.125rem 0.25rem rgba($gray-900, 0.02) !default;
$box-shadow: 0 0.5rem 1rem rgba($gray-900, 0.03) !default;
$box-shadow-lg: 0 1rem 3rem rgba($gray-900, 0.06) !default;

// Pagination

$pagination-padding-y: 0.875rem !default;
$pagination-padding-x: 1.25rem !default;
$pagination-padding-y-sm: 0.25rem !default;
$pagination-padding-x-sm: 0.5rem !default;
$pagination-padding-y-lg: 0.75rem !default;
$pagination-padding-x-lg: 1.5rem !default;
$pagination-line-height: 1.25 !default;

$pagination-color: $secondary !important;
$pagination-bg: $white !default;
$pagination-border-color: $gray-200 !default;
$pagination-hover-bg: $white !important;
$pagination-hover-color: $primary !important;
$pagination-active-color: $primary !important;
$pagination-active-bg: $white !default;

$modal-xl: 1140px !default;
$modal-lg: 800px !default;
$modal-md: 795px !default;
$modal-sm: 300px !default;

//
// Application Variables
//-----------------------------------------------------

// ------- Header --------
$header-height: 70px;
$footer-height: 70px;

// ------- Sidebar --------
$sidebar-width: 250px;
$sidebar-mini-width: 60px;
$sidebar-color: #ffffff;
$table-head-color: $black !default;
$table-accent-bg: rgba($primary, 0.05);
$table-border-color: $gray-200;
$table-border-width: 2px; //
// Customize bootstrap variables to match
// look and feel of the theme
//-----------------------------------------------------
// stylelint-disable
$white: #fff !default;
$gray-100: #f9fbfd !default;
$gray-200: #ececec !default;
$gray-300: #e3ebf6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #95aac9 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #12263f !default;
$black: #000 !default;

/*Theme color*/

$primary: #36b7b7;
$secondary: #878787;
$success: #00d97e;
$info: #39afd1;
$warning: #f6c343;
$danger: #e63757;
$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
);
$body-bg: $gray-100;
$body-color: $gray-900;
$base-shadow-color: #005595;
$nav-base-color: #1e2224;
$light-color: #b4b4b4;

// Customize typographic styles
$font-size-base: 1rem;
$font-family-sans-serif: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
$link-color: $primary;
$headings-line-height: 21px !default;
$small-font-size: 90%;

// Customize button styles
$btn-border-radius: 7px;
$font-weight-medium: 500 !default;
$btn-font-weight: 400 !default;
$btn-transition: box-shadow 0.3s cubic-bezier(0.35, 0, 0.25, 1), transform 0.2s cubic-bezier(0.35, 0, 0.25, 1), background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
$line-height-lg: 1.75 !default;
$input-btn-padding-y: 0.65rem !default;
$input-btn-padding-x: 0.75rem !default;
$input-btn-padding-y-lg: 0.4rem !default;
$input-btn-padding-x-lg: 1.6rem !default;
$input-btn-padding-y-sm: 0.5rem !default;
$input-btn-padding-x-sm: 0.75rem !default;
// Custmize form component styles
$input-border-radius: 0;
$input-border-radius-lg: 5px;
$input-border-radius-sm: 5px;
$input-focus-border-color: $primary;
$input-btn-border-width: 1px;
$input-btn-focus-box-shadow: none;
$input-btn-focus-width: 0;
$input-disabled-bg: rgba(#dfe6ec, 0.27) !default;
// Dropdowns
// Dropdown menu container and contents.
$dropdown-padding-y: 0.75rem;
$dropdown-item-padding-y: 0.75rem;
$dropdown-item-padding-x: 1.5rem;
$dropdown-header-color: $black;
$dropdown-min-width: 10rem !default;
$dropdown-padding-y: 0.5rem !default;
$dropdown-spacer: 0.125rem !default;
$dropdown-bg: $white !default;
$dropdown-border-color: rgba($black, 0.15) !default;

$dropdown-divider-bg: $gray-300 !default;
$dropdown-box-shadow: 0 0.5rem 1rem rgba($black, 0.175) !default;
$dropdown-link-color: $body-color !default;
$dropdown-link-hover-color: darken($body-color, 5%) !default;
$dropdown-link-hover-bg: $gray-100 !default;
$dropdown-link-active-color: darken($body-color, 5%) !default;
$dropdown-link-active-bg: darken($gray-200, 5%) !default;
// Forms
$input-color: $primary;
// Links
//
// Style anchor elements.
$link-hover-decoration: underline !default;
// List
$list-group-border-color: rgba($primary, 0.4);

// card
$card-border-radius: 1rem;
$card-color: $body-color;
$card-spacer-y: 1.5rem !default;
$card-spacer-x: 1.5rem !default;

// progress bar
$progress-height: 0.4rem !default;
$progress-border-radius: 5px;
$progress-height-lg: 0.8rem !default;
$progress-height-xl: 1.2rem !default;

// badge
$badge-padding-y: 0.5em !default;
$badge-padding-x: 0.8em !default;
// Tables
$table-head-color: $black !default;
$table-accent-bg: rgba($primary, 0.05);
$table-border-color: $gray-200;
$table-border-width: 2px;
// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.
$table-cell-padding: 0.75rem !default;
$table-cell-padding-sm: 0.45rem !default;

//shadow
$box-shadow-sm: 0 0.125rem 0.25rem rgba($gray-900, 0.02) !default;
$box-shadow: 0 0.5rem 1rem rgba($gray-900, 0.03) !default;
$box-shadow-lg: 0 1rem 3rem rgba($gray-900, 0.06) !default;

// Pagination

$pagination-padding-y: 0.875rem !default;
$pagination-padding-x: 1.25rem !default;
$pagination-padding-y-sm: 0.25rem !default;
$pagination-padding-x-sm: 0.5rem !default;
$pagination-padding-y-lg: 0.75rem !default;
$pagination-padding-x-lg: 1.5rem !default;
$pagination-line-height: 1.25 !default;

$pagination-color: $secondary !important;
$pagination-bg: $white !default;
$pagination-border-color: $gray-200 !default;
$pagination-hover-bg: $white !important;
$pagination-hover-color: $primary !important;
$pagination-active-color: $primary !important;
$pagination-active-bg: $white !default;

$modal-xl: 1140px !default;
$modal-lg: 800px !default;
$modal-md: 795px !default;
$modal-sm: 300px !default;

//
// Application Variables
//-----------------------------------------------------

// ------- Header --------
$header-height: 70px;
$footer-height: 70px;

// ------- Sidebar --------
$sidebar-width: 250px;
$sidebar-mini-width: 60px;
$sidebar-color: #ffffff; //
// Customize bootstrap variables to match
// look and feel of the theme
//-----------------------------------------------------
// stylelint-disable
$white: #fff !default;
$gray-100: #f9fbfd !default;
$gray-200: #ececec !default;
$gray-300: #e3ebf6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #95aac9 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #12263f !default;
$black: #000 !default;

/*Theme color*/

$primary: #36b7b7;
$secondary: #878787;
$success: #00d97e;
$info: #39afd1;
$warning: #f6c343;
$danger: #e63757;
$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
);
$body-bg: $gray-100;
$body-color: $gray-900;
$base-shadow-color: #005595;
$nav-base-color: #1e2224;
$light-color: #b4b4b4;

// Customize typographic styles
$font-size-base: 1rem;
$font-family-sans-serif: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
$link-color: $primary;
$headings-line-height: 21px !default;
$small-font-size: 90%;

// Customize button styles
$btn-border-radius: 7px;
$font-weight-medium: 500 !default;
$btn-font-weight: 400 !default;
$btn-transition: box-shadow 0.3s cubic-bezier(0.35, 0, 0.25, 1), transform 0.2s cubic-bezier(0.35, 0, 0.25, 1), background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
$line-height-lg: 1.75 !default;
$input-btn-padding-y: 0.65rem !default;
$input-btn-padding-x: 0.75rem !default;
$input-btn-padding-y-lg: 0.4rem !default;
$input-btn-padding-x-lg: 1.6rem !default;
$input-btn-padding-y-sm: 0.5rem !default;
$input-btn-padding-x-sm: 0.75rem !default;
// Custmize form component styles
$input-border-radius: 0;
$input-border-radius-lg: 5px;
$input-border-radius-sm: 5px;
$input-focus-border-color: $primary;
$input-btn-border-width: 1px;
$input-btn-focus-box-shadow: none;
$input-btn-focus-width: 0;
$input-disabled-bg: rgba(#dfe6ec, 0.27) !default;
// Dropdowns
// Dropdown menu container and contents.
$dropdown-padding-y: 0.75rem;
$dropdown-item-padding-y: 0.75rem;
$dropdown-item-padding-x: 1.5rem;
$dropdown-header-color: $black;
$dropdown-min-width: 10rem !default;
$dropdown-padding-y: 0.5rem !default;
$dropdown-spacer: 0.125rem !default;
$dropdown-bg: $white !default;
$dropdown-border-color: rgba($black, 0.15) !default;

$dropdown-divider-bg: $gray-300 !default;
$dropdown-box-shadow: 0 0.5rem 1rem rgba($black, 0.175) !default;
$dropdown-link-color: $body-color !default;
$dropdown-link-hover-color: darken($body-color, 5%) !default;
$dropdown-link-hover-bg: $gray-100 !default;
$dropdown-link-active-color: darken($body-color, 5%) !default;
$dropdown-link-active-bg: darken($gray-200, 5%) !default;
// Forms
$input-color: $primary;
// Links
//
// Style anchor elements.
$link-hover-decoration: underline !default;
// List
$list-group-border-color: rgba($primary, 0.4);

// card
$card-border-radius: 1rem;
$card-color: $body-color;
$card-spacer-y: 1.5rem !default;
$card-spacer-x: 1.5rem !default;

// progress bar
$progress-height: 0.4rem !default;
$progress-border-radius: 5px;
$progress-height-lg: 0.8rem !default;
$progress-height-xl: 1.2rem !default;

// badge
$badge-padding-y: 0.5em !default;
$badge-padding-x: 0.8em !default;
// Tables
$table-head-color: $black !default;
$table-accent-bg: rgba($primary, 0.05);
$table-border-color: $gray-200;
$table-border-width: 2px;
// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.
$table-cell-padding: 0.75rem !default;
$table-cell-padding-sm: 0.45rem !default;

//shadow
$box-shadow-sm: 0 0.125rem 0.25rem rgba($gray-900, 0.02) !default;
$box-shadow: 0 0.5rem 1rem rgba($gray-900, 0.03) !default;
$box-shadow-lg: 0 1rem 3rem rgba($gray-900, 0.06) !default;

// Pagination

$pagination-padding-y: 0.875rem !default;
$pagination-padding-x: 1.25rem !default;
$pagination-padding-y-sm: 0.25rem !default;
$pagination-padding-x-sm: 0.5rem !default;
$pagination-padding-y-lg: 0.75rem !default;
$pagination-padding-x-lg: 1.5rem !default;
$pagination-line-height: 1.25 !default;

$pagination-color: $secondary !important;
$pagination-bg: $white !default;
$pagination-border-color: $gray-200 !default;
$pagination-hover-bg: $white !important;
$pagination-hover-color: $primary !important;
$pagination-active-color: $primary !important;
$pagination-active-bg: $white !default;

$modal-xl: 1140px !default;
$modal-lg: 800px !default;
$modal-md: 795px !default;
$modal-sm: 300px !default;

//
// Application Variables
//-----------------------------------------------------

// ------- Header --------
$header-height: 70px;
$footer-height: 70px;

// ------- Sidebar --------
$sidebar-width: 250px;
$sidebar-mini-width: 60px;
$sidebar-color: #ffffff; //
// Customize bootstrap variables to match
// look and feel of the theme
//-----------------------------------------------------
// stylelint-disable
$white: #fff !default;
$gray-100: #f9fbfd !default;
$gray-200: #ececec !default;
$gray-300: #e3ebf6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #95aac9 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #12263f !default;
$black: #000 !default;

/*Theme color*/

$primary: #36b7b7;
$secondary: #878787;
$success: #00d97e;
$info: #39afd1;
$warning: #f6c343;
$danger: #e63757;
$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
);
$body-bg: $gray-100;
$body-color: $gray-900;
$base-shadow-color: #005595;
$nav-base-color: #1e2224;
$light-color: #b4b4b4;

// Customize typographic styles
$font-size-base: 1rem;
$font-family-sans-serif: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
$link-color: $primary;
$headings-line-height: 21px !default;
$small-font-size: 90%;

// Customize button styles
$btn-border-radius: 7px;
$font-weight-medium: 500 !default;
$btn-font-weight: 400 !default;
$btn-transition: box-shadow 0.3s cubic-bezier(0.35, 0, 0.25, 1), transform 0.2s cubic-bezier(0.35, 0, 0.25, 1), background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
$line-height-lg: 1.75 !default;
$input-btn-padding-y: 0.65rem !default;
$input-btn-padding-x: 0.75rem !default;
$input-btn-padding-y-lg: 0.4rem !default;
$input-btn-padding-x-lg: 1.6rem !default;
$input-btn-padding-y-sm: 0.5rem !default;
$input-btn-padding-x-sm: 0.75rem !default;
// Custmize form component styles
$input-border-radius: 0;
$input-border-radius-lg: 5px;
$input-border-radius-sm: 5px;
$input-focus-border-color: $primary;
$input-btn-border-width: 1px;
$input-btn-focus-box-shadow: none;
$input-btn-focus-width: 0;
$input-disabled-bg: rgba(#dfe6ec, 0.27) !default;
// Dropdowns
// Dropdown menu container and contents.
$dropdown-padding-y: 0.75rem;
$dropdown-item-padding-y: 0.75rem;
$dropdown-item-padding-x: 1.5rem;
$dropdown-header-color: $black;
$dropdown-min-width: 10rem !default;
$dropdown-padding-y: 0.5rem !default;
$dropdown-spacer: 0.125rem !default;
$dropdown-bg: $white !default;
$dropdown-border-color: rgba($black, 0.15) !default;

$dropdown-divider-bg: $gray-300 !default;
$dropdown-box-shadow: 0 0.5rem 1rem rgba($black, 0.175) !default;
$dropdown-link-color: $body-color !default;
$dropdown-link-hover-color: darken($body-color, 5%) !default;
$dropdown-link-hover-bg: $gray-100 !default;
$dropdown-link-active-color: darken($body-color, 5%) !default;
$dropdown-link-active-bg: darken($gray-200, 5%) !default;
// Forms
$input-color: $primary;
// Links
//
// Style anchor elements.
$link-hover-decoration: underline !default;
// List
$list-group-border-color: rgba($primary, 0.4);

// card
$card-border-radius: 1rem;
$card-color: $body-color;
$card-spacer-y: 1.5rem !default;
$card-spacer-x: 1.5rem !default;

// progress bar
$progress-height: 0.4rem !default;
$progress-border-radius: 5px;
$progress-height-lg: 0.8rem !default;
$progress-height-xl: 1.2rem !default;

// badge
$badge-padding-y: 0.5em !default;
$badge-padding-x: 0.8em !default;
// Tables
$table-head-color: $black !default;
$table-accent-bg: rgba($primary, 0.05);
$table-border-color: $gray-200;
$table-border-width: 2px;
// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.
$table-cell-padding: 0.75rem !default;
$table-cell-padding-sm: 0.45rem !default;

//shadow
$box-shadow-sm: 0 0.125rem 0.25rem rgba($gray-900, 0.02) !default;
$box-shadow: 0 0.5rem 1rem rgba($gray-900, 0.03) !default;
$box-shadow-lg: 0 1rem 3rem rgba($gray-900, 0.06) !default;

// Pagination

$pagination-padding-y: 0.875rem !default;
$pagination-padding-x: 1.25rem !default;
$pagination-padding-y-sm: 0.25rem !default;
$pagination-padding-x-sm: 0.5rem !default;
$pagination-padding-y-lg: 0.75rem !default;
$pagination-padding-x-lg: 1.5rem !default;
$pagination-line-height: 1.25 !default;

$pagination-color: $secondary !important;
$pagination-bg: $white !default;
$pagination-border-color: $gray-200 !default;
$pagination-hover-bg: $white !important;
$pagination-hover-color: $primary !important;
$pagination-active-color: $primary !important;
$pagination-active-bg: $white !default;

$modal-xl: 1140px !default;
$modal-lg: 800px !default;
$modal-md: 795px !default;
$modal-sm: 300px !default;

//
// Application Variables
//-----------------------------------------------------

// ------- Header --------
$header-height: 70px;
$footer-height: 70px;

// ------- Sidebar --------
$sidebar-width: 250px;
$sidebar-mini-width: 60px;
$sidebar-color: #ffffff;
$table-cell-padding: 0.75rem !default;
$table-cell-padding-sm: 0.45rem !default; //
// Customize bootstrap variables to match
// look and feel of the theme
//-----------------------------------------------------
// stylelint-disable
$white: #fff !default;
$gray-100: #f9fbfd !default;
$gray-200: #ececec !default;
$gray-300: #e3ebf6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #95aac9 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #12263f !default;
$black: #000 !default;

/*Theme color*/

$primary: #36b7b7;
$secondary: #878787;
$success: #00d97e;
$info: #39afd1;
$warning: #f6c343;
$danger: #e63757;
$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
);
$body-bg: $gray-100;
$body-color: $gray-900;
$base-shadow-color: #005595;
$nav-base-color: #1e2224;
$light-color: #b4b4b4;

// Customize typographic styles
$font-size-base: 1rem;
$font-family-sans-serif: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
$link-color: $primary;
$headings-line-height: 21px !default;
$small-font-size: 90%;

// Customize button styles
$btn-border-radius: 7px;
$font-weight-medium: 500 !default;
$btn-font-weight: 400 !default;
$btn-transition: box-shadow 0.3s cubic-bezier(0.35, 0, 0.25, 1), transform 0.2s cubic-bezier(0.35, 0, 0.25, 1), background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
$line-height-lg: 1.75 !default;
$input-btn-padding-y: 0.65rem !default;
$input-btn-padding-x: 0.75rem !default;
$input-btn-padding-y-lg: 0.4rem !default;
$input-btn-padding-x-lg: 1.6rem !default;
$input-btn-padding-y-sm: 0.5rem !default;
$input-btn-padding-x-sm: 0.75rem !default;
// Custmize form component styles
$input-border-radius: 0;
$input-border-radius-lg: 5px;
$input-border-radius-sm: 5px;
$input-focus-border-color: $primary;
$input-btn-border-width: 1px;
$input-btn-focus-box-shadow: none;
$input-btn-focus-width: 0;
$input-disabled-bg: rgba(#dfe6ec, 0.27) !default;
// Dropdowns
// Dropdown menu container and contents.
$dropdown-padding-y: 0.75rem;
$dropdown-item-padding-y: 0.75rem;
$dropdown-item-padding-x: 1.5rem;
$dropdown-header-color: $black;
$dropdown-min-width: 10rem !default;
$dropdown-padding-y: 0.5rem !default;
$dropdown-spacer: 0.125rem !default;
$dropdown-bg: $white !default;
$dropdown-border-color: rgba($black, 0.15) !default;

$dropdown-divider-bg: $gray-300 !default;
$dropdown-box-shadow: 0 0.5rem 1rem rgba($black, 0.175) !default;
$dropdown-link-color: $body-color !default;
$dropdown-link-hover-color: darken($body-color, 5%) !default;
$dropdown-link-hover-bg: $gray-100 !default;
$dropdown-link-active-color: darken($body-color, 5%) !default;
$dropdown-link-active-bg: darken($gray-200, 5%) !default;
// Forms
$input-color: $primary;
// Links
//
// Style anchor elements.
$link-hover-decoration: underline !default;
// List
$list-group-border-color: rgba($primary, 0.4);

// card
$card-border-radius: 1rem;
$card-color: $body-color;
$card-spacer-y: 1.5rem !default;
$card-spacer-x: 1.5rem !default;

// progress bar
$progress-height: 0.4rem !default;
$progress-border-radius: 5px;
$progress-height-lg: 0.8rem !default;
$progress-height-xl: 1.2rem !default;

// badge
$badge-padding-y: 0.5em !default;
$badge-padding-x: 0.8em !default;
// Tables
$table-head-color: $black !default;
$table-accent-bg: rgba($primary, 0.05);
$table-border-color: $gray-200;
$table-border-width: 2px;
// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.
$table-cell-padding: 0.75rem !default;
$table-cell-padding-sm: 0.45rem !default;

//shadow
$box-shadow-sm: 0 0.125rem 0.25rem rgba($gray-900, 0.02) !default;
$box-shadow: 0 0.5rem 1rem rgba($gray-900, 0.03) !default;
$box-shadow-lg: 0 1rem 3rem rgba($gray-900, 0.06) !default;

// Pagination

$pagination-padding-y: 0.875rem !default;
$pagination-padding-x: 1.25rem !default;
$pagination-padding-y-sm: 0.25rem !default;
$pagination-padding-x-sm: 0.5rem !default;
$pagination-padding-y-lg: 0.75rem !default;
$pagination-padding-x-lg: 1.5rem !default;
$pagination-line-height: 1.25 !default;

$pagination-color: $secondary !important;
$pagination-bg: $white !default;
$pagination-border-color: $gray-200 !default;
$pagination-hover-bg: $white !important;
$pagination-hover-color: $primary !important;
$pagination-active-color: $primary !important;
$pagination-active-bg: $white !default;

$modal-xl: 1140px !default;
$modal-lg: 800px !default;
$modal-md: 795px !default;
$modal-sm: 300px !default;

//
// Application Variables
//-----------------------------------------------------

// ------- Header --------
$header-height: 70px;
$footer-height: 70px;

// ------- Sidebar --------
$sidebar-width: 250px;
$sidebar-mini-width: 60px;
$sidebar-color: #ffffff;
$box-shadow-sm: 0 0.125rem 0.25rem rgba($gray-900, 0.02) !default;
$box-shadow: 0 0.5rem 1rem rgba($gray-900, 0.03) !default;
$box-shadow-lg: 0 1rem 3rem rgba($gray-900, 0.06) !default; //
// Customize bootstrap variables to match
// look and feel of the theme
//-----------------------------------------------------
// stylelint-disable
$white: #fff !default;
$gray-100: #f9fbfd !default;
$gray-200: #ececec !default;
$gray-300: #e3ebf6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #95aac9 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #12263f !default;
$black: #000 !default;

/*Theme color*/

$primary: #36b7b7;
$secondary: #878787;
$success: #00d97e;
$info: #39afd1;
$warning: #f6c343;
$danger: #e63757;
$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
);
$body-bg: $gray-100;
$body-color: $gray-900;
$base-shadow-color: #005595;
$nav-base-color: #1e2224;
$light-color: #b4b4b4;

// Customize typographic styles
$font-size-base: 1rem;
$font-family-sans-serif: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
$link-color: $primary;
$headings-line-height: 21px !default;
$small-font-size: 90%;

// Customize button styles
$btn-border-radius: 7px;
$font-weight-medium: 500 !default;
$btn-font-weight: 400 !default;
$btn-transition: box-shadow 0.3s cubic-bezier(0.35, 0, 0.25, 1), transform 0.2s cubic-bezier(0.35, 0, 0.25, 1), background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
$line-height-lg: 1.75 !default;
$input-btn-padding-y: 0.65rem !default;
$input-btn-padding-x: 0.75rem !default;
$input-btn-padding-y-lg: 0.4rem !default;
$input-btn-padding-x-lg: 1.6rem !default;
$input-btn-padding-y-sm: 0.5rem !default;
$input-btn-padding-x-sm: 0.75rem !default;
// Custmize form component styles
$input-border-radius: 0;
$input-border-radius-lg: 5px;
$input-border-radius-sm: 5px;
$input-focus-border-color: $primary;
$input-btn-border-width: 1px;
$input-btn-focus-box-shadow: none;
$input-btn-focus-width: 0;
$input-disabled-bg: rgba(#dfe6ec, 0.27) !default;
// Dropdowns
// Dropdown menu container and contents.
$dropdown-padding-y: 0.75rem;
$dropdown-item-padding-y: 0.75rem;
$dropdown-item-padding-x: 1.5rem;
$dropdown-header-color: $black;
$dropdown-min-width: 10rem !default;
$dropdown-padding-y: 0.5rem !default;
$dropdown-spacer: 0.125rem !default;
$dropdown-bg: $white !default;
$dropdown-border-color: rgba($black, 0.15) !default;

$dropdown-divider-bg: $gray-300 !default;
$dropdown-box-shadow: 0 0.5rem 1rem rgba($black, 0.175) !default;
$dropdown-link-color: $body-color !default;
$dropdown-link-hover-color: darken($body-color, 5%) !default;
$dropdown-link-hover-bg: $gray-100 !default;
$dropdown-link-active-color: darken($body-color, 5%) !default;
$dropdown-link-active-bg: darken($gray-200, 5%) !default;
// Forms
$input-color: $primary;
// Links
//
// Style anchor elements.
$link-hover-decoration: underline !default;
// List
$list-group-border-color: rgba($primary, 0.4);

// card
$card-border-radius: 1rem;
$card-color: $body-color;
$card-spacer-y: 1.5rem !default;
$card-spacer-x: 1.5rem !default;

// progress bar
$progress-height: 0.4rem !default;
$progress-border-radius: 5px;
$progress-height-lg: 0.8rem !default;
$progress-height-xl: 1.2rem !default;

// badge
$badge-padding-y: 0.5em !default;
$badge-padding-x: 0.8em !default;
// Tables
$table-head-color: $black !default;
$table-accent-bg: rgba($primary, 0.05);
$table-border-color: $gray-200;
$table-border-width: 2px;
// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.
$table-cell-padding: 0.75rem !default;
$table-cell-padding-sm: 0.45rem !default;

//shadow
$box-shadow-sm: 0 0.125rem 0.25rem rgba($gray-900, 0.02) !default;
$box-shadow: 0 0.5rem 1rem rgba($gray-900, 0.03) !default;
$box-shadow-lg: 0 1rem 3rem rgba($gray-900, 0.06) !default;

// Pagination

$pagination-padding-y: 0.875rem !default;
$pagination-padding-x: 1.25rem !default;
$pagination-padding-y-sm: 0.25rem !default;
$pagination-padding-x-sm: 0.5rem !default;
$pagination-padding-y-lg: 0.75rem !default;
$pagination-padding-x-lg: 1.5rem !default;
$pagination-line-height: 1.25 !default;

$pagination-color: $secondary !important;
$pagination-bg: $white !default;
$pagination-border-color: $gray-200 !default;
$pagination-hover-bg: $white !important;
$pagination-hover-color: $primary !important;
$pagination-active-color: $primary !important;
$pagination-active-bg: $white !default;

$modal-xl: 1140px !default;
$modal-lg: 800px !default;
$modal-md: 795px !default;
$modal-sm: 300px !default;

//
// Application Variables
//-----------------------------------------------------

// ------- Header --------
$header-height: 70px;
$footer-height: 70px;

// ------- Sidebar --------
$sidebar-width: 250px;
$sidebar-mini-width: 60px;
$sidebar-color: #ffffff;
$pagination-padding-y: 0.875rem !default;
$pagination-padding-x: 1.25rem !default;
$pagination-padding-y-sm: 0.25rem !default;
$pagination-padding-x-sm: 0.5rem !default;
$pagination-padding-y-lg: 0.75rem !default;
$pagination-padding-x-lg: 1.5rem !default;
$pagination-line-height: 1.25 !default;
$pagination-color: $secondary !important;
$pagination-bg: $white !default;
$pagination-border-color: $gray-200 !default;
$pagination-hover-bg: $white !important;
$pagination-hover-color: $primary !important;
$pagination-active-color: $primary !important;
$pagination-active-bg: $white !default;
$modal-xl: 1140px !default;
$modal-lg: 800px !default;
$modal-md: 795px !default;
$modal-sm: 300px !default; //
// Customize bootstrap variables to match
// look and feel of the theme
//-----------------------------------------------------
// stylelint-disable
$white: #fff !default;
$gray-100: #f9fbfd !default;
$gray-200: #ececec !default;
$gray-300: #e3ebf6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #95aac9 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #12263f !default;
$black: #000 !default;

/*Theme color*/

$primary: #36b7b7;
$secondary: #878787;
$success: #00d97e;
$info: #39afd1;
$warning: #f6c343;
$danger: #e63757;
$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
);
$body-bg: $gray-100;
$body-color: $gray-900;
$base-shadow-color: #005595;
$nav-base-color: #1e2224;
$light-color: #b4b4b4;

// Customize typographic styles
$font-size-base: 1rem;
$font-family-sans-serif: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
$link-color: $primary;
$headings-line-height: 21px !default;
$small-font-size: 90%;

// Customize button styles
$btn-border-radius: 7px;
$font-weight-medium: 500 !default;
$btn-font-weight: 400 !default;
$btn-transition: box-shadow 0.3s cubic-bezier(0.35, 0, 0.25, 1), transform 0.2s cubic-bezier(0.35, 0, 0.25, 1), background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
$line-height-lg: 1.75 !default;
$input-btn-padding-y: 0.65rem !default;
$input-btn-padding-x: 0.75rem !default;
$input-btn-padding-y-lg: 0.4rem !default;
$input-btn-padding-x-lg: 1.6rem !default;
$input-btn-padding-y-sm: 0.5rem !default;
$input-btn-padding-x-sm: 0.75rem !default;
// Custmize form component styles
$input-border-radius: 0;
$input-border-radius-lg: 5px;
$input-border-radius-sm: 5px;
$input-focus-border-color: $primary;
$input-btn-border-width: 1px;
$input-btn-focus-box-shadow: none;
$input-btn-focus-width: 0;
$input-disabled-bg: rgba(#dfe6ec, 0.27) !default;
// Dropdowns
// Dropdown menu container and contents.
$dropdown-padding-y: 0.75rem;
$dropdown-item-padding-y: 0.75rem;
$dropdown-item-padding-x: 1.5rem;
$dropdown-header-color: $black;
$dropdown-min-width: 10rem !default;
$dropdown-padding-y: 0.5rem !default;
$dropdown-spacer: 0.125rem !default;
$dropdown-bg: $white !default;
$dropdown-border-color: rgba($black, 0.15) !default;

$dropdown-divider-bg: $gray-300 !default;
$dropdown-box-shadow: 0 0.5rem 1rem rgba($black, 0.175) !default;
$dropdown-link-color: $body-color !default;
$dropdown-link-hover-color: darken($body-color, 5%) !default;
$dropdown-link-hover-bg: $gray-100 !default;
$dropdown-link-active-color: darken($body-color, 5%) !default;
$dropdown-link-active-bg: darken($gray-200, 5%) !default;
// Forms
$input-color: $primary;
// Links
//
// Style anchor elements.
$link-hover-decoration: underline !default;
// List
$list-group-border-color: rgba($primary, 0.4);

// card
$card-border-radius: 1rem;
$card-color: $body-color;
$card-spacer-y: 1.5rem !default;
$card-spacer-x: 1.5rem !default;

// progress bar
$progress-height: 0.4rem !default;
$progress-border-radius: 5px;
$progress-height-lg: 0.8rem !default;
$progress-height-xl: 1.2rem !default;

// badge
$badge-padding-y: 0.5em !default;
$badge-padding-x: 0.8em !default;
// Tables
$table-head-color: $black !default;
$table-accent-bg: rgba($primary, 0.05);
$table-border-color: $gray-200;
$table-border-width: 2px;
// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.
$table-cell-padding: 0.75rem !default;
$table-cell-padding-sm: 0.45rem !default;

//shadow
$box-shadow-sm: 0 0.125rem 0.25rem rgba($gray-900, 0.02) !default;
$box-shadow: 0 0.5rem 1rem rgba($gray-900, 0.03) !default;
$box-shadow-lg: 0 1rem 3rem rgba($gray-900, 0.06) !default;

// Pagination

$pagination-padding-y: 0.875rem !default;
$pagination-padding-x: 1.25rem !default;
$pagination-padding-y-sm: 0.25rem !default;
$pagination-padding-x-sm: 0.5rem !default;
$pagination-padding-y-lg: 0.75rem !default;
$pagination-padding-x-lg: 1.5rem !default;
$pagination-line-height: 1.25 !default;

$pagination-color: $secondary !important;
$pagination-bg: $white !default;
$pagination-border-color: $gray-200 !default;
$pagination-hover-bg: $white !important;
$pagination-hover-color: $primary !important;
$pagination-active-color: $primary !important;
$pagination-active-bg: $white !default;

$modal-xl: 1140px !default;
$modal-lg: 800px !default;
$modal-md: 795px !default;
$modal-sm: 300px !default;

//
// Application Variables
//-----------------------------------------------------

// ------- Header --------
$header-height: 70px;
$footer-height: 70px;

// ------- Sidebar --------
$sidebar-width: 250px;
$sidebar-mini-width: 60px;
$sidebar-color: #ffffff; //
// Customize bootstrap variables to match
// look and feel of the theme
//-----------------------------------------------------
// stylelint-disable
$white: #fff !default;
$gray-100: #f9fbfd !default;
$gray-200: #ececec !default;
$gray-300: #e3ebf6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #95aac9 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #12263f !default;
$black: #000 !default;

/*Theme color*/

$primary: #36b7b7;
$secondary: #878787;
$success: #00d97e;
$info: #39afd1;
$warning: #f6c343;
$danger: #e63757;
$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
);
$body-bg: $gray-100;
$body-color: $gray-900;
$base-shadow-color: #005595;
$nav-base-color: #1e2224;
$light-color: #b4b4b4;

// Customize typographic styles
$font-size-base: 1rem;
$font-family-sans-serif: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
$link-color: $primary;
$headings-line-height: 21px !default;
$small-font-size: 90%;

// Customize button styles
$btn-border-radius: 7px;
$font-weight-medium: 500 !default;
$btn-font-weight: 400 !default;
$btn-transition: box-shadow 0.3s cubic-bezier(0.35, 0, 0.25, 1), transform 0.2s cubic-bezier(0.35, 0, 0.25, 1), background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
$line-height-lg: 1.75 !default;
$input-btn-padding-y: 0.65rem !default;
$input-btn-padding-x: 0.75rem !default;
$input-btn-padding-y-lg: 0.4rem !default;
$input-btn-padding-x-lg: 1.6rem !default;
$input-btn-padding-y-sm: 0.5rem !default;
$input-btn-padding-x-sm: 0.75rem !default;
// Custmize form component styles
$input-border-radius: 0;
$input-border-radius-lg: 5px;
$input-border-radius-sm: 5px;
$input-focus-border-color: $primary;
$input-btn-border-width: 1px;
$input-btn-focus-box-shadow: none;
$input-btn-focus-width: 0;
$input-disabled-bg: rgba(#dfe6ec, 0.27) !default;
// Dropdowns
// Dropdown menu container and contents.
$dropdown-padding-y: 0.75rem;
$dropdown-item-padding-y: 0.75rem;
$dropdown-item-padding-x: 1.5rem;
$dropdown-header-color: $black;
$dropdown-min-width: 10rem !default;
$dropdown-padding-y: 0.5rem !default;
$dropdown-spacer: 0.125rem !default;
$dropdown-bg: $white !default;
$dropdown-border-color: rgba($black, 0.15) !default;

$dropdown-divider-bg: $gray-300 !default;
$dropdown-box-shadow: 0 0.5rem 1rem rgba($black, 0.175) !default;
$dropdown-link-color: $body-color !default;
$dropdown-link-hover-color: darken($body-color, 5%) !default;
$dropdown-link-hover-bg: $gray-100 !default;
$dropdown-link-active-color: darken($body-color, 5%) !default;
$dropdown-link-active-bg: darken($gray-200, 5%) !default;
// Forms
$input-color: $primary;
// Links
//
// Style anchor elements.
$link-hover-decoration: underline !default;
// List
$list-group-border-color: rgba($primary, 0.4);

// card
$card-border-radius: 1rem;
$card-color: $body-color;
$card-spacer-y: 1.5rem !default;
$card-spacer-x: 1.5rem !default;

// progress bar
$progress-height: 0.4rem !default;
$progress-border-radius: 5px;
$progress-height-lg: 0.8rem !default;
$progress-height-xl: 1.2rem !default;

// badge
$badge-padding-y: 0.5em !default;
$badge-padding-x: 0.8em !default;
// Tables
$table-head-color: $black !default;
$table-accent-bg: rgba($primary, 0.05);
$table-border-color: $gray-200;
$table-border-width: 2px;
// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.
$table-cell-padding: 0.75rem !default;
$table-cell-padding-sm: 0.45rem !default;

//shadow
$box-shadow-sm: 0 0.125rem 0.25rem rgba($gray-900, 0.02) !default;
$box-shadow: 0 0.5rem 1rem rgba($gray-900, 0.03) !default;
$box-shadow-lg: 0 1rem 3rem rgba($gray-900, 0.06) !default;

// Pagination

$pagination-padding-y: 0.875rem !default;
$pagination-padding-x: 1.25rem !default;
$pagination-padding-y-sm: 0.25rem !default;
$pagination-padding-x-sm: 0.5rem !default;
$pagination-padding-y-lg: 0.75rem !default;
$pagination-padding-x-lg: 1.5rem !default;
$pagination-line-height: 1.25 !default;

$pagination-color: $secondary !important;
$pagination-bg: $white !default;
$pagination-border-color: $gray-200 !default;
$pagination-hover-bg: $white !important;
$pagination-hover-color: $primary !important;
$pagination-active-color: $primary !important;
$pagination-active-bg: $white !default;

$modal-xl: 1140px !default;
$modal-lg: 800px !default;
$modal-md: 795px !default;
$modal-sm: 300px !default;

//
// Application Variables
//-----------------------------------------------------

// ------- Header --------
$header-height: 70px;
$footer-height: 70px;

// ------- Sidebar --------
$sidebar-width: 250px;
$sidebar-mini-width: 60px;
$sidebar-color: #ffffff; //
// Customize bootstrap variables to match
// look and feel of the theme
//-----------------------------------------------------
// stylelint-disable
$white: #fff !default;
$gray-100: #f9fbfd !default;
$gray-200: #ececec !default;
$gray-300: #e3ebf6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #95aac9 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #12263f !default;
$black: #000 !default;

/*Theme color*/

$primary: #36b7b7;
$secondary: #878787;
$success: #00d97e;
$info: #39afd1;
$warning: #f6c343;
$danger: #e63757;
$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
);
$body-bg: $gray-100;
$body-color: $gray-900;
$base-shadow-color: #005595;
$nav-base-color: #1e2224;
$light-color: #b4b4b4;

// Customize typographic styles
$font-size-base: 1rem;
$font-family-sans-serif: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
$link-color: $primary;
$headings-line-height: 21px !default;
$small-font-size: 90%;

// Customize button styles
$btn-border-radius: 7px;
$font-weight-medium: 500 !default;
$btn-font-weight: 400 !default;
$btn-transition: box-shadow 0.3s cubic-bezier(0.35, 0, 0.25, 1), transform 0.2s cubic-bezier(0.35, 0, 0.25, 1), background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
$line-height-lg: 1.75 !default;
$input-btn-padding-y: 0.65rem !default;
$input-btn-padding-x: 0.75rem !default;
$input-btn-padding-y-lg: 0.4rem !default;
$input-btn-padding-x-lg: 1.6rem !default;
$input-btn-padding-y-sm: 0.5rem !default;
$input-btn-padding-x-sm: 0.75rem !default;
// Custmize form component styles
$input-border-radius: 0;
$input-border-radius-lg: 5px;
$input-border-radius-sm: 5px;
$input-focus-border-color: $primary;
$input-btn-border-width: 1px;
$input-btn-focus-box-shadow: none;
$input-btn-focus-width: 0;
$input-disabled-bg: rgba(#dfe6ec, 0.27) !default;
// Dropdowns
// Dropdown menu container and contents.
$dropdown-padding-y: 0.75rem;
$dropdown-item-padding-y: 0.75rem;
$dropdown-item-padding-x: 1.5rem;
$dropdown-header-color: $black;
$dropdown-min-width: 10rem !default;
$dropdown-padding-y: 0.5rem !default;
$dropdown-spacer: 0.125rem !default;
$dropdown-bg: $white !default;
$dropdown-border-color: rgba($black, 0.15) !default;

$dropdown-divider-bg: $gray-300 !default;
$dropdown-box-shadow: 0 0.5rem 1rem rgba($black, 0.175) !default;
$dropdown-link-color: $body-color !default;
$dropdown-link-hover-color: darken($body-color, 5%) !default;
$dropdown-link-hover-bg: $gray-100 !default;
$dropdown-link-active-color: darken($body-color, 5%) !default;
$dropdown-link-active-bg: darken($gray-200, 5%) !default;
// Forms
$input-color: $primary;
// Links
//
// Style anchor elements.
$link-hover-decoration: underline !default;
// List
$list-group-border-color: rgba($primary, 0.4);

// card
$card-border-radius: 1rem;
$card-color: $body-color;
$card-spacer-y: 1.5rem !default;
$card-spacer-x: 1.5rem !default;

// progress bar
$progress-height: 0.4rem !default;
$progress-border-radius: 5px;
$progress-height-lg: 0.8rem !default;
$progress-height-xl: 1.2rem !default;

// badge
$badge-padding-y: 0.5em !default;
$badge-padding-x: 0.8em !default;
// Tables
$table-head-color: $black !default;
$table-accent-bg: rgba($primary, 0.05);
$table-border-color: $gray-200;
$table-border-width: 2px;
// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.
$table-cell-padding: 0.75rem !default;
$table-cell-padding-sm: 0.45rem !default;

//shadow
$box-shadow-sm: 0 0.125rem 0.25rem rgba($gray-900, 0.02) !default;
$box-shadow: 0 0.5rem 1rem rgba($gray-900, 0.03) !default;
$box-shadow-lg: 0 1rem 3rem rgba($gray-900, 0.06) !default;

// Pagination

$pagination-padding-y: 0.875rem !default;
$pagination-padding-x: 1.25rem !default;
$pagination-padding-y-sm: 0.25rem !default;
$pagination-padding-x-sm: 0.5rem !default;
$pagination-padding-y-lg: 0.75rem !default;
$pagination-padding-x-lg: 1.5rem !default;
$pagination-line-height: 1.25 !default;

$pagination-color: $secondary !important;
$pagination-bg: $white !default;
$pagination-border-color: $gray-200 !default;
$pagination-hover-bg: $white !important;
$pagination-hover-color: $primary !important;
$pagination-active-color: $primary !important;
$pagination-active-bg: $white !default;

$modal-xl: 1140px !default;
$modal-lg: 800px !default;
$modal-md: 795px !default;
$modal-sm: 300px !default;

//
// Application Variables
//-----------------------------------------------------

// ------- Header --------
$header-height: 70px;
$footer-height: 70px;

// ------- Sidebar --------
$sidebar-width: 250px;
$sidebar-mini-width: 60px;
$sidebar-color: #ffffff; //
// Customize bootstrap variables to match
// look and feel of the theme
//-----------------------------------------------------
// stylelint-disable
$white: #fff !default;
$gray-100: #f9fbfd !default;
$gray-200: #ececec !default;
$gray-300: #e3ebf6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #95aac9 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #12263f !default;
$black: #000 !default;

/*Theme color*/

$primary: #36b7b7;
$secondary: #878787;
$success: #00d97e;
$info: #39afd1;
$warning: #f6c343;
$danger: #e63757;
$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
);
$body-bg: $gray-100;
$body-color: $gray-900;
$base-shadow-color: #005595;
$nav-base-color: #1e2224;
$light-color: #b4b4b4;

// Customize typographic styles
$font-size-base: 1rem;
$font-family-sans-serif: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
$link-color: $primary;
$headings-line-height: 21px !default;
$small-font-size: 90%;

// Customize button styles
$btn-border-radius: 7px;
$font-weight-medium: 500 !default;
$btn-font-weight: 400 !default;
$btn-transition: box-shadow 0.3s cubic-bezier(0.35, 0, 0.25, 1), transform 0.2s cubic-bezier(0.35, 0, 0.25, 1), background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
$line-height-lg: 1.75 !default;
$input-btn-padding-y: 0.65rem !default;
$input-btn-padding-x: 0.75rem !default;
$input-btn-padding-y-lg: 0.4rem !default;
$input-btn-padding-x-lg: 1.6rem !default;
$input-btn-padding-y-sm: 0.5rem !default;
$input-btn-padding-x-sm: 0.75rem !default;
// Custmize form component styles
$input-border-radius: 0;
$input-border-radius-lg: 5px;
$input-border-radius-sm: 5px;
$input-focus-border-color: $primary;
$input-btn-border-width: 1px;
$input-btn-focus-box-shadow: none;
$input-btn-focus-width: 0;
$input-disabled-bg: rgba(#dfe6ec, 0.27) !default;
// Dropdowns
// Dropdown menu container and contents.
$dropdown-padding-y: 0.75rem;
$dropdown-item-padding-y: 0.75rem;
$dropdown-item-padding-x: 1.5rem;
$dropdown-header-color: $black;
$dropdown-min-width: 10rem !default;
$dropdown-padding-y: 0.5rem !default;
$dropdown-spacer: 0.125rem !default;
$dropdown-bg: $white !default;
$dropdown-border-color: rgba($black, 0.15) !default;

$dropdown-divider-bg: $gray-300 !default;
$dropdown-box-shadow: 0 0.5rem 1rem rgba($black, 0.175) !default;
$dropdown-link-color: $body-color !default;
$dropdown-link-hover-color: darken($body-color, 5%) !default;
$dropdown-link-hover-bg: $gray-100 !default;
$dropdown-link-active-color: darken($body-color, 5%) !default;
$dropdown-link-active-bg: darken($gray-200, 5%) !default;
// Forms
$input-color: $primary;
// Links
//
// Style anchor elements.
$link-hover-decoration: underline !default;
// List
$list-group-border-color: rgba($primary, 0.4);

// card
$card-border-radius: 1rem;
$card-color: $body-color;
$card-spacer-y: 1.5rem !default;
$card-spacer-x: 1.5rem !default;

// progress bar
$progress-height: 0.4rem !default;
$progress-border-radius: 5px;
$progress-height-lg: 0.8rem !default;
$progress-height-xl: 1.2rem !default;

// badge
$badge-padding-y: 0.5em !default;
$badge-padding-x: 0.8em !default;
// Tables
$table-head-color: $black !default;
$table-accent-bg: rgba($primary, 0.05);
$table-border-color: $gray-200;
$table-border-width: 2px;
// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.
$table-cell-padding: 0.75rem !default;
$table-cell-padding-sm: 0.45rem !default;

//shadow
$box-shadow-sm: 0 0.125rem 0.25rem rgba($gray-900, 0.02) !default;
$box-shadow: 0 0.5rem 1rem rgba($gray-900, 0.03) !default;
$box-shadow-lg: 0 1rem 3rem rgba($gray-900, 0.06) !default;

// Pagination

$pagination-padding-y: 0.875rem !default;
$pagination-padding-x: 1.25rem !default;
$pagination-padding-y-sm: 0.25rem !default;
$pagination-padding-x-sm: 0.5rem !default;
$pagination-padding-y-lg: 0.75rem !default;
$pagination-padding-x-lg: 1.5rem !default;
$pagination-line-height: 1.25 !default;

$pagination-color: $secondary !important;
$pagination-bg: $white !default;
$pagination-border-color: $gray-200 !default;
$pagination-hover-bg: $white !important;
$pagination-hover-color: $primary !important;
$pagination-active-color: $primary !important;
$pagination-active-bg: $white !default;

$modal-xl: 1140px !default;
$modal-lg: 800px !default;
$modal-md: 795px !default;
$modal-sm: 300px !default;

//
// Application Variables
//-----------------------------------------------------

// ------- Header --------
$header-height: 70px;
$footer-height: 70px;

// ------- Sidebar --------
$sidebar-width: 250px;
$sidebar-mini-width: 60px;
$sidebar-color: #ffffff;
$header-height: 70px;
$footer-height: 70px; //
// Customize bootstrap variables to match
// look and feel of the theme
//-----------------------------------------------------
// stylelint-disable
$white: #fff !default;
$gray-100: #f9fbfd !default;
$gray-200: #ececec !default;
$gray-300: #e3ebf6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #95aac9 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #12263f !default;
$black: #000 !default;

/*Theme color*/

$primary: #36b7b7;
$secondary: #878787;
$success: #00d97e;
$info: #39afd1;
$warning: #f6c343;
$danger: #e63757;
$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
);
$body-bg: $gray-100;
$body-color: $gray-900;
$base-shadow-color: #005595;
$nav-base-color: #1e2224;
$light-color: #b4b4b4;

// Customize typographic styles
$font-size-base: 1rem;
$font-family-sans-serif: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
$link-color: $primary;
$headings-line-height: 21px !default;
$small-font-size: 90%;

// Customize button styles
$btn-border-radius: 7px;
$font-weight-medium: 500 !default;
$btn-font-weight: 400 !default;
$btn-transition: box-shadow 0.3s cubic-bezier(0.35, 0, 0.25, 1), transform 0.2s cubic-bezier(0.35, 0, 0.25, 1), background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
$line-height-lg: 1.75 !default;
$input-btn-padding-y: 0.65rem !default;
$input-btn-padding-x: 0.75rem !default;
$input-btn-padding-y-lg: 0.4rem !default;
$input-btn-padding-x-lg: 1.6rem !default;
$input-btn-padding-y-sm: 0.5rem !default;
$input-btn-padding-x-sm: 0.75rem !default;
// Custmize form component styles
$input-border-radius: 0;
$input-border-radius-lg: 5px;
$input-border-radius-sm: 5px;
$input-focus-border-color: $primary;
$input-btn-border-width: 1px;
$input-btn-focus-box-shadow: none;
$input-btn-focus-width: 0;
$input-disabled-bg: rgba(#dfe6ec, 0.27) !default;
// Dropdowns
// Dropdown menu container and contents.
$dropdown-padding-y: 0.75rem;
$dropdown-item-padding-y: 0.75rem;
$dropdown-item-padding-x: 1.5rem;
$dropdown-header-color: $black;
$dropdown-min-width: 10rem !default;
$dropdown-padding-y: 0.5rem !default;
$dropdown-spacer: 0.125rem !default;
$dropdown-bg: $white !default;
$dropdown-border-color: rgba($black, 0.15) !default;

$dropdown-divider-bg: $gray-300 !default;
$dropdown-box-shadow: 0 0.5rem 1rem rgba($black, 0.175) !default;
$dropdown-link-color: $body-color !default;
$dropdown-link-hover-color: darken($body-color, 5%) !default;
$dropdown-link-hover-bg: $gray-100 !default;
$dropdown-link-active-color: darken($body-color, 5%) !default;
$dropdown-link-active-bg: darken($gray-200, 5%) !default;
// Forms
$input-color: $primary;
// Links
//
// Style anchor elements.
$link-hover-decoration: underline !default;
// List
$list-group-border-color: rgba($primary, 0.4);

// card
$card-border-radius: 1rem;
$card-color: $body-color;
$card-spacer-y: 1.5rem !default;
$card-spacer-x: 1.5rem !default;

// progress bar
$progress-height: 0.4rem !default;
$progress-border-radius: 5px;
$progress-height-lg: 0.8rem !default;
$progress-height-xl: 1.2rem !default;

// badge
$badge-padding-y: 0.5em !default;
$badge-padding-x: 0.8em !default;
// Tables
$table-head-color: $black !default;
$table-accent-bg: rgba($primary, 0.05);
$table-border-color: $gray-200;
$table-border-width: 2px;
// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.
$table-cell-padding: 0.75rem !default;
$table-cell-padding-sm: 0.45rem !default;

//shadow
$box-shadow-sm: 0 0.125rem 0.25rem rgba($gray-900, 0.02) !default;
$box-shadow: 0 0.5rem 1rem rgba($gray-900, 0.03) !default;
$box-shadow-lg: 0 1rem 3rem rgba($gray-900, 0.06) !default;

// Pagination

$pagination-padding-y: 0.875rem !default;
$pagination-padding-x: 1.25rem !default;
$pagination-padding-y-sm: 0.25rem !default;
$pagination-padding-x-sm: 0.5rem !default;
$pagination-padding-y-lg: 0.75rem !default;
$pagination-padding-x-lg: 1.5rem !default;
$pagination-line-height: 1.25 !default;

$pagination-color: $secondary !important;
$pagination-bg: $white !default;
$pagination-border-color: $gray-200 !default;
$pagination-hover-bg: $white !important;
$pagination-hover-color: $primary !important;
$pagination-active-color: $primary !important;
$pagination-active-bg: $white !default;

$modal-xl: 1140px !default;
$modal-lg: 800px !default;
$modal-md: 795px !default;
$modal-sm: 300px !default;

//
// Application Variables
//-----------------------------------------------------

// ------- Header --------
$header-height: 70px;
$footer-height: 70px;

// ------- Sidebar --------
$sidebar-width: 250px;
$sidebar-mini-width: 60px;
$sidebar-color: #ffffff;
$sidebar-width: 250px;
$sidebar-mini-width: 60px;
$sidebar-color: #ffffff;
