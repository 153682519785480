// Componant: Tabs

.nav-filter {
    .nav-item {
        margin-right: 1rem;
    }
    .nav-link {
        @extend .btn-clear;
        @extend .pt-0;
        @extend .pb-2;
        position: relative;
        line-height: 25px;
        padding-bottom: 4px;
        letter-spacing: 0.5px;
        border: 0;
        &:before {
            content: "";
            width: 100%;
            height: 2px;
            display: block;
            position: absolute;
            left: 0;
            bottom: 0;
            @include default_transition(0.3s, ease-in-out);
            @include border-radius(2px);
        }
        &.active,
        &:hover {
            color: $primary !important;
            &:before {
                background-color: $primary;
            }
        }
    }
    &.filter-border {
        @extend .border-bottom;
    }
}

.scroll-h {
    white-space: nowrap;
    overflow-x: auto;
    @extend .d-block;

    li {
        display: inline-block;
        float: none;
    }
}

.tab-menu {
    &.nav-tabs {
        @extend .border-0;
        margin-bottom: 1rem;
        .nav-item {
            margin-right: 40px;
        }
        .nav-link {
            cursor: pointer;
            @extend .border-0;
            @extend .p-0;
            &.active {
                color: $primary;
                svg {
                    path {
                        fill: $primary;
                    }
                    circle {
                        fill: $primary;
                    }
                }
            }
        }
    }
}

.avatar-name {
    @extend .d-flex;
    @extend .align-items-center;
    img {
        width: 60px;
        height: 60px;
        object-fit: cover;
        margin-right: 1rem;
        @include border-radius(50%);
    }
    h5 {
        font-size: rem(18);
        @extend .font-weight-bold;
        @extend .mb-0;
    }
    &-edit {
        position: relative;
        .avataredit {
            position: absolute;
            left: 40px;
            bottom: -9px;
            cursor: pointer;
            @include border-radius(50%);
            -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
            -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
            box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
        }
    }
}

.avatar-center {
    .avatar-name {
        @extend .justify-content-center;
        img {
            margin-right: 0;
        }
        &-edit {
            .avataredit {
                left: calc(50% + 9px);
            }
        }
    }
}
