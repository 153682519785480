// utility Classes

// font size
@for $i from 11 through 36 {
  .font-size-#{$i} {
    font-size: rem($i);
  }
}

// font weight
.font-light {
  font-weight: 300 !important;
}
.font-normal {
  font-weight: 400 !important;
}
.font-medium {
  font-weight: 500 !important;
}
.font-semibold {
  font-weight: 600 !important;
}
.font-bold {
  font-weight: 700 !important;
}
.no-label {
  margin-top: 33px;
}
.no-res-label {
  margin-top: 33px;
  @include media-breakpoint-down(sm) {
    margin-top: 0;
  }
}

.c-pointer {
  cursor: pointer;
}

.font-weight-regular {
  font-weight: $font-weight-normal;
}
ul {
  margin-bottom: 0;
}

.pl-20 {
  padding-left: 20px;
}

.mr-15 {
  margin-right: 15px;
}
.empty-message {
  text-align: center;
  margin: 3rem 0;
  color: #737373 !important;
}
