// Componant: Table
.table {
  margin-bottom: 0;
  font-size: rem(14);
  font-weight: $font-weight-medium;
  color: $black;
  thead th {
    background-color: #f9fbfd;
    // color: $white;
    padding: 1rem 1rem;
    font-family: $font-Poppins;
    text-transform: uppercase;
    font-weight: 500 !important;
    border: 0 !important;
  }
  tr {
    &:first-child {
      td {
        border-top: 0;
      }
    }
  }
  th,
  td {
    vertical-align: middle;
    font-size: rem(13);
  }
  td {
    padding: 0.75rem 1rem;
    font-weight: initial;
    .w-50 {
      width: 75px !important;
    }
    &:first-child {
      padding-left: 1.5rem;
    }
    &:last-child {
      padding-right: 1.5rem;
    }
  }
  th {
    &:first-child {
      padding-left: 1.5rem;
    }
    &:last-child {
      padding-right: 1.5rem;
    }
  }
  .badge {
    line-height: 1.3;
  }
  a:hover {
    text-decoration: none;
  }
  .table-striped {
    margin-bottom: 0;
  }
}

.table-no-border {
  th,
  td {
    border: 0 !important;
  }
}

.pagination {
  .page-link {
    cursor: pointer;
    border-color: $border-color !important;
    color: #a3acaf;
    font-weight: 600;
  }
}
@media (max-width: 1441px) {
  table.table-no-border {
    min-width: 1200px;
  }
}
.table-responsive-md {
  .table {
    min-width: 960px !important;
  }
}
@media (max-width: 767px) {
  .table-responsive-sm {
    .table {
      min-width: 560px;
    }
  }
}

.t-p-img {
  flex: 0 0 60px;
  width: 60px;
  height: 60px;
  position: relative;
  display: block;
  @extend .border;
  @extend .rounded;
  padding: 2px;
  img {
    position: absolute;
    z-index: 10;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
  }
  &-sm {
    flex: 0 0 54px;
    width: 54px;
    height: 54px;
  }
  &-lg {
    flex: 0 0 90px;
    width: 90px;
    height: 90px;
  }
}

.input-group-table {
  .btn {
    @extend .font-weight-light;
    padding: 0.5rem 0.75rem;
    font-size: rem(13);
  }
  .form-control {
    font-size: rem(13);
    @extend .font-weight-light;
    padding: 0.5rem 0.75rem;
    height: unset;
  }
}

.row-total {
  td {
    font-weight: 600 !important;
  }
}

.table {
  th {
    &.sort {
      cursor: pointer;
      .sort-icon {
        @extend .ml-1;
        @extend .d-inline-block;
        background-image: url('/images/svg/sort.svg');
        background-size: 14px;
        width: 14px;
        height: 14px;
        vertical-align: middle;
        opacity: 0.15;
        &.sorting_asc {
          opacity: 1;
        }
        &.sorting_desc {
          opacity: 1;
          @include rotate(180deg);
        }
      }
    }
  }
}

.table-td-px-0 {
  td {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
.table-responsive-sm {
  .table {
    min-width: 720px;
  }
}
@media (max-width: 768px) {
  .w-75.table-responsive-sm {
    width: 100% !important;
  }
  .table-rs-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    .table {
      min-width: 420px;
    }
  }
}

.table th,
.table td {
  border-top: 1px solid #ececec;
}

.table-responsive {
  .table {
    min-width: 1120px;
  }
  &::-webkit-scrollbar {
    height: 5px;
  }
}
.table-responsive-sm {
  .table {
    min-width: 720px !important;
  }
}
.table-responsive-lg {
  .table {
    min-width: 1220px;
  }
}
.table-responsive-xl {
  .table {
    min-width: 1640px;
  }
}
.sidebar-mini {
  .table-responsive-xl {
    .table {
      min-width: 1520px;
    }
  }
}

.table-fg-mb-0 {
  .form-group {
    margin-bottom: 0;
  }
}
