/*----- Componant: Top Navigation Bar ----- */

.header {
  // @extend .d-none;
  background-color: $white;
  height: $header-height;
  color: $white;
  @extend .shadow-sm;
  @extend .fixed-top;
  .nav {
    height: $header-height;
    background: #007679;
  }
  .navbar-logo {
    width: $sidebar-width;
    @extend .d-flex;
    @extend .align-items-center;
    @extend .shadow-sm;
    .navbar-logo {
      @extend .d-block;
    }
    .navbar-brand {
      @extend .mr-0;
    }
    img {
      // height: 40.846px;
      max-width: 175px;
      display: block;
      margin-left: 0.25rem;
    }
  }
}
.nav-avatar {
  width: 42px;
  height: 42px;
  object-fit: cover;
  @include border-radius(50%);
}
.nav-footer {
  // @extend .border-top;
  padding: 1rem;
  .dropdown-menu {
    @extend .shadow-lg;
  }
  .dropdown-item {
    font-size: rem(14);
    span {
      @extend .d-inline-block;
    }
  }
}

@media (max-width: 1080px) {
  .header {
    height: 56px;
    display: block !important;
    .nav {
      height: 56px;
      padding-left: 15px;
      padding-right: 15px;
      justify-content: space-between;
      align-items: center;
      .btn {
        padding-left: 0;
        padding-right: 0.25rem;
      }
    }
    .navbar-logo {
      width: unset !important;
      img {
        height: 24px !important;
      }
    }
  }
  .page-content {
    // padding-top: 56px;
  }
  .nav-footer {
    padding: 0;
    border: 0 !important;
  }
  .left-sidebar {
    top: 56px !important;
    .nav-header {
      display: none !important;
    }
    .nav-footer {
      display: none !important;
    }
  }
}
