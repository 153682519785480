// Componant: Card
.card {
  margin-bottom: 30px;
  border: none;
  color: $card-color;
  @extend .shadow;
  .card-body {
    @include media-breakpoint-down(md) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
  .card-title {
    font-size: rem(18);
  }
  .card-titles {
    font-size: rem(18);
  }

  .card-header {
    background-color: transparent;
    border: none;
    padding-left: 1rem;
    padding-right: 1rem;
    h3 {
      margin-bottom: 0;
    }
  }
  .card-footer {
    background-color: transparent;
    border: none;
  }
}

.card-ticket {
  ul {
    li {
      margin-bottom: 25px;
      border-bottom: 1px;
      &.media {
        & > span {
          width: 84px;
        }
        .media-body {
          margin-left: 36px;
          border-bottom: 1px solid rgba($black, 0.2);
        }
      }
      &:last-child {
        margin-bottom: 0;
        &.media {
          .media-body {
            border-bottom: 0;
            p.text-secondary {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}

.nav-count {
  counter-reset: navcount;
  .nav-link {
    @extend .position-relative;
    &:before {
      counter-increment: navcount;
      content: counter(navcount);
      width: 18px;
      height: 18px;
      @extend .position-absolute;
      left: 0;
      background-color: $gray-600;
      @include border-radius(50%);
      @extend .flexbox-align-c;
      color: $white;
      font-size: rem(10);
      font-weight: $font-weight-medium;
    }
    padding-left: 27px;
    &.active {
      &:before {
        background-color: $primary;
      }
    }
    &:last-child {
    }
  }
}

.cus-nav {
  @extend .justify-content-around;
  .nav-link {
    font-size: rem(12);
    line-height: 19px;
    @extend .text-muted;
    &.active {
      color: $primary !important;
    }
  }
  @include media-breakpoint-down(md) {
    padding-left: 1rem;
    padding-right: 1rem;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    flex-wrap: nowrap;
    justify-content: flex-start !important;
  }
}
