// utility Ovarlay
.ovarlay{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    background-color: rgba(0,0,0,.5)!important;
    @include transition(opacity .3s, cubic-bezier(.4,0,.2,1));
    z-index: 1020;
    pointer-events: none;
}

.has-ovarlay{
    .ovarlay{
        pointer-events: auto;
        opacity: 1;  
    }
}